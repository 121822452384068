import { createSlice } from "@reduxjs/toolkit";

const initalProgressState = {
  imageUploading: false,
  membersLoading: false,
  memberLoading: false,
  profileUploading: false,
  noteUpload: false,
};

const progressSlice = createSlice({
  name: "progress",
  initialState: initalProgressState,
  reducers: {
    toggleProgress(state, action) {
      const initialState = state;
      initialState[action.payload.progress] = action.payload.status;
      state = initialState;
    }
  }
});

export const progressActions = progressSlice.actions;

export default progressSlice;