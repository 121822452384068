import React, { useState } from 'react';
import axios from '../helpers/authCatch';
import playerboost_logo from '../assets/images/playerboost_logo.png';
import axiosConfig from '../helpers/axiosConfig';
import Loader from 'react-loader-spinner';
import { useHistory } from 'react-router-dom';
import playerboost_bg from "../assets/images/PB_BG3.jpg";

const bgImgStyle = {
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundImage: `url(${playerboost_bg})`
}

export const SignUpPage = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [country, setCountry] = useState('');
  const [phone, setPhone] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [gamertag, setGamertag] = useState('');
  const [discord, setDiscord] = useState('');
  const [paypal, setPaypal] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [keyword, setKeyword] = useState('');
  const [loaderSpinner, setLoaderSpinner] = useState(false);

  let history = useHistory();

  const summarizeInputs = async () => {
    if (firstName === '') {
      return alertTrigger('Please enter a first name');
    }
    if (lastName === '') {
      return alertTrigger('Please enter a last name');
    }
    if (email === '') {
      return alertTrigger('Please enter an email');
    }
    if (displayName === '') {
      return alertTrigger('Please enter a display name');
    }
    // if (!/^[a-zA-Z0-9_.-]$/.test(displayName)) {
    //   console.log('nope')
    //   return alertTrigger('Please use only letters and numbers for your display name.')
    // }
    if (country === '') {
      return alertTrigger('Please enter a country');
    }
    if (gamertag === '') {
      return alertTrigger('Please enter a gamertag');
    }
    if (discord === '') {
      return alertTrigger('Please enter a discord name')
    }
    if (paypal === '') {
      return alertTrigger('Please enter your paypal');
    }
    if (phone === '') {
      return alertTrigger('Please enter a phone number');
    }
    if (keyword === '') {
      return alertTrigger('Please enter signup token');
    }
    if (password !== confirmPassword) {
      setPassword('');
      setConfirmPassword('');
      return alertTrigger('Passwords do not match');
    }
    if (password.length < 8) {
      return alertTrigger('Password is not long enough');
    }
    const body = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNumber: phone,
      country: country,
      password: password,
      confirmPassword: confirmPassword,
      username: displayName,
      gamertag: gamertag,
      discord: discord,
      paypal: paypal,
      keyword: keyword,
    };
    setLoaderSpinner(true);
    setAlertMessage('Building your account...');
    try {
      await axios.post(axiosConfig('signup'), body);
      setAlertMessage('Success!');

      setTimeout(() => {
        history.push('/login');
      }, 1000);
    } catch (error) {
      console.log(error)
      const errCode = error.response.data.type || '';
      if (
        ['USERNAME_TAKEN', 'CHAT_USER_EXISTS', 'CHAT_USER_ERR'].includes(
          errCode
        )
      ) {
        setAlertMessage('Whoops, this username is already taken.');
        setDisplayName('');
      } else if (errCode === 'EMAIL_IN_USE') {
        setAlertMessage('Whoops, this email is already in use.');
        setEmail('');
      } else if (errCode === 'INVALID_EMAIL') {
        setAlertMessage('Whoops, this email is invalid.');
        setEmail('');
      } else if (errCode === 'WEAK_PASSWORD') {
        setAlertMessage(
          'That password is too weak - please choose a stronger password.'
        );
        setPassword('');
        setConfirmPassword('');
      } else {
        setAlertMessage('Something went wrong - please try again...');
        setFirstName('');
        setLastName('');
        setEmail('');
        setCountry('');
        setPhone('');
        setDisplayName('');
        setGamertag('');
        setDiscord('');
        setPassword('');
        setPaypal('');
        setConfirmPassword('');
        setKeyword('');
      }
      setTimeout(() => {
        setLoaderSpinner(false);
      }, 2000);
    }
  };

  const alertTrigger = (message) => {
    setAlertMessage(message);

    setTimeout(() => {
      setAlertMessage('');
    }, 2500);
  };

  return (
    <>
      <div className="flex sm:h-full justify-center items-center bg-cbackground-primary" style={ bgImgStyle }>
        <div className="mt-10 sm:mt-0 w-full md:w-8/12 lg:h-auto lg:w-auto">
          <div className="my-4">
            <img
              className="mx-auto h-12 w-auto"
              src={playerboost_logo}
              alt="Workflow"
            />
          </div>
          <div className="md:grid md:grid-cols-2 md:gap-6">
            <div className="mb-32 mt-5 md:mt-0 md:col-span-2 md:mb-0">
              <form action="#" method="POST">
                <div className="md:shadow overflow-hidden sm:rounded-md">
                  <div className="px-4 py-5 md:bg-cbackground-light sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 lg:col-span-3">
                        <label
                          htmlFor="first-name"
                          className="block text-sm font-medium text-cprimary-primary"
                        >
                          First name
                        </label>
                        <input
                          onChange={(event) => setFirstName(event.target.value)}
                          type="text"
                          name="first-name"
                          id="first-name"
                          value={firstName}
                          autoComplete="given-name"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          required
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="last-name"
                          className="block text-sm font-medium text-cprimary-primary"
                        >
                          Last name
                        </label>
                        <input
                          onChange={(event) => setLastName(event.target.value)}
                          type="text"
                          name="last-name"
                          id="last-name"
                          value={lastName}
                          autoComplete="family-name"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          required
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="email-address"
                          className="block text-sm font-medium text-cprimary-primary"
                        >
                          Email address
                        </label>
                        <input
                          onChange={(event) => setEmail(event.target.value)}
                          type="text"
                          name="email-address"
                          id="email-address"
                          value={email}
                          autoComplete="email"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          required
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label className="block text-sm font-medium text-cprimary-primary">
                          Display Name
                        </label>
                        <input
                          onChange={(event) =>
                            setDisplayName(event.target.value)
                          }
                          type="text"
                          name="display-name"
                          id="display-name"
                          value={displayName}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          required
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="email-address"
                          className="block text-sm font-medium text-cprimary-primary"
                        >
                          Country
                        </label>
                        <input
                          onChange={(event) => setCountry(event.target.value)}
                          type="text"
                          name="country"
                          id="country"
                          value={country}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          required
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                        <label
                          htmlFor="city"
                          className="block text-sm font-medium text-cprimary-primary"
                        >
                          Gamertag
                        </label>
                        <input
                          onChange={(event) => setGamertag(event.target.value)}
                          type="text"
                          name="gamertag"
                          id="gamertag"
                          value={gamertag}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          required
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                        <label
                          htmlFor="city"
                          className="block text-sm font-medium text-cprimary-primary"
                        >
                          Discord
                        </label>
                        <input
                          onChange={(event) => setDiscord(event.target.value)}
                          type="text"
                          name="discord"
                          id="discord"
                          value={discord}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          required
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                        <label
                          htmlFor="city"
                          className="block text-sm font-medium text-cprimary-primary"
                        >
                          Paypal
                        </label>
                        <input
                          onChange={(event) => setPaypal(event.target.value)}
                          type="text"
                          name="paypal"
                          id="paypal"
                          value={paypal}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          required
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                        <label
                          htmlFor="phone-number"
                          className="block text-sm font-medium text-cprimary-primary"
                        >
                          Phone Number
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <div className="absolute inset-y-0 left-0 flex items-center"></div>
                          <input
                            onChange={(event) => setPhone(event.target.value)}
                            type="text"
                            name="phone-number"
                            id="phone-number"
                            autoComplete="tel"
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            placeholder="+1 (555) 555-5555"
                            value={phone}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                        <label
                          htmlFor="city"
                          className="block text-sm font-medium text-cprimary-primary"
                        >
                          Password
                        </label>
                        <input
                          onChange={(event) => setPassword(event.target.value)}
                          type="password"
                          name="paypal"
                          id="paypal"
                          value={password}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          required
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                        <label
                          htmlFor="city"
                          className="block text-sm font-medium text-cprimary-primary"
                        >
                          Confirm Password
                        </label>
                        <input
                          onChange={(event) =>
                            setConfirmPassword(event.target.value)
                          }
                          type="password"
                          name="paypal"
                          id="paypal"
                          value={confirmPassword}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          required
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                        <label
                          htmlFor="city"
                          className="block text-sm font-medium text-cprimary-primary"
                        >
                          Signup Token
                        </label>
                        <input
                          onChange={(event) => setKeyword(event.target.value)}
                          type="password"
                          name="keyword"
                          id="keyword"
                          value={keyword}
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="py-3 bg-cbackground-light flex-auto items-center flex flex-col text-cprimary-primary fixed right-0 left-0 bottom-0 md:static ">
                    {loaderSpinner && (
                      <div>
                        {loaderSpinner ? (
                          <Loader
                            type="ThreeDots"
                            color="#4289f4"
                            height={30}
                            width={30}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    )}
                    {alertMessage !== '' && (
                      <div className="px-4 py-3 flex-auto items-center flex text-cprimary-primary transition ease-in-out delay-15">
                        {alertMessage}
                      </div>
                    )}
                    {loaderSpinner ? (
                      ''
                    ) : (
                      <div className="w-full px-4 py-3 flex flex-row justify-between items-center sm:px-6">
                        <p
                          className="text-white cursor-pointer text-sm"
                          onClick={() => history.push('/login')}
                        >
                          Already have an account? Sign in.
                        </p>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            summarizeInputs();
                          }}
                          type="submit"
                          className="inline-flex rounded-sm items-center px-4 py-2 shadow-sm text-sm font-medium text-white bg-cprimary-primary hover:bg-cprimary-light"
                        >
                          SIGN UP
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUpPage;
