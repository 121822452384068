import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Card from '../components/helpers/Card';
import DetailCard from '../components/Profile/DetailCard';
import UserCard from '../components/Profile/UserCard';
import AdminBlock from '../components/Profile/Admin/AdminBlock';
import { CheckCircleIcon } from '@heroicons/react/solid';
import axios from 'axios';
import axiosConfig from '../helpers/axiosConfig';
import { uiActions } from '../store/ui-slice';
import { useFetch } from '../customHooks/useFetch';
import ShopifyLoader from '../components/helpers/ShopifyLoader';

const UserDetailPage = () => {
  const dispatch = useDispatch();
  const { user } = useParams();
  const [userDetails, setUserDetails] = useState();
  const [values, setValues] = useState({
    platforms: [],
    games: [],
    access: '',
    revShare: '',
  });
  const [changing, setChanging] = useState(false);
  const userRole = useSelector((state) => state.auth.role);
  const userAuth = useSelector((state) => state.auth.token);
  const [isLoading, setIsLoading] = useState(false);

  const detailInfo = {
    path: `user/${user}`,
    errorMessage: "Issue fetching member data, try again!",
  };

  const memberDetails = useFetch(detailInfo);

  useEffect(() => {
    setIsLoading(memberDetails.loading);
    setValues({
      ...values,
      platforms: memberDetails.data.platforms,
      revShare: parseInt(memberDetails.data.revenue_share * 100),
      access: memberDetails.data.access,
      games: memberDetails.data.games
    });
    setUserDetails(memberDetails.data);
  }, [memberDetails.loading]);

  function adminValues(type, selection) {
    setChanging(true);
    setValues({ ...values, [type]: selection });
  }

  const sendRequest = async () => {
    setChanging(false);

    try {
      if (/^\d{1,2}$/.test(values.revShare)) {
        const body = {
          username: user,
          platforms: values.platforms,
          rev_share: values.revShare / 100,
          claim: values.access,
          //games: values.games
          games: []
        };
        const response = await axios.put(
          axiosConfig("update-super-info"),
          body,
          {
            headers: { Authorization: userAuth },
          }
        );
        if (response.status === 200) {
          dispatch(
            uiActions.showFlashAlert({
              message: "User details updated.",
            })
          );

          setTimeout(() => {
            dispatch(uiActions.closeFlashAlert());
          }, 2000);
        }
      } else {
        dispatch(
          uiActions.showFlashAlert({
            message:
              "Please input a number between 0 and 100 for the revenue share.",
          })
        );

        setTimeout(() => {
          dispatch(uiActions.closeFlashAlert());
        }, 2000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading) {
    return <ShopifyLoader />
  }

  return (
    <>
      {userDetails ? (
        <div>
          <div className="shadow sm:rounded-lg">
            <div className="flex m-2 p-4">
              <div className="mr-24 flex">
                <div className="text-white self-center text-xl mr-8">
                  {userDetails.gamertag}
                </div>
              </div>
            </div>
          </div>
          <div className=" w-full flex-col flex lg:flex-row-reverse">
            <div className="md:2/12 lg:w-4/12 bg-cbackground-primary mt-3 rounded-lg">
              <Card
                details={{
                  styling: 'w-full flex flex-col bg-transparent	shadow-none',
                }}
              >
                <UserCard
                  name={userDetails.displayName ?? userDetails.gamertag}
                  access={userDetails.access}
                  email={userDetails.email}
                  platforms={userDetails.platforms || []}
                />
              </Card>
            </div>
            <div className="md:10/12 md:mx-2 lg:w-8/12">
              <Card
                details={{
                  heading: 'Personal Information',
                  styling: 'w-full',
                }}
              >
                <DetailCard
                  firstName={userDetails.firstName}
                  lastName={userDetails.lastName}
                  gamerTag={userDetails.gamertag}
                  email={userDetails.email}
                  country={userDetails.country}
                  paypal={userDetails.paypal}
                  discord={userDetails.discord}
                />
              </Card>

              {'super' === userRole && (
                <Card
                  details={{
                    heading: 'Admin Info',
                    styling: 'w-full',
                  }}
                  rightElement={
                    changing ? (
                      <CheckCircleIcon
                        className="h-6 w-6 text-cprimary-light cursor-pointer border-2 border-white rounded-full"
                        onClick={() => sendRequest()}
                      />
                    ) : (
                      ''
                    )
                  }
                >
                  <AdminBlock
                    platform={userDetails.platforms || []}
                    games={values.games || []}
                    getAdminValues={adminValues}
                    access={userDetails.access}
                    revShare={userDetails.revenue_share}
                  />
                </Card>
              )}
            </div>
          </div>
        </div>
      ) : (
        <ShopifyLoader />
      )}
    </>
  );
};

export default UserDetailPage;
