import InfoTitle from '../../components/InfoTitle';
import InfoContent from '../../components/helpers/InfoContent';

const VPNSection = ({order}) => {
return (
  <section
  aria-labelledby="timeline-title"
  className="shadow overflow-hidden sm:rounded-lg bg-cbackground-primary lg:col-start-3 lg:col-span-1 space-y-6"
>
  <div className="">
    <h2 className="px-6 py-4 text-white border-b border-cborder font-light text-lg">
      VPN Protection
    </h2>

    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-1 px-4 py-5 sm:px-6">
      <div className="sm:col-span-1">
        <InfoTitle content="country" />
        <InfoContent
          content={
            order
              ? order.order_info.address.country
              : 'no info provided'
          }
        />
      </div>
      <div className="sm:col-span-1">
        <InfoTitle content="city" />
        <InfoContent
          content={
            order
              ? order.order_info.address.city
              : 'no info provided'
          }
        />
      </div>
      <div className="sm:col-span-1">
        <InfoTitle content="Province" />
        <InfoContent
          content={
            order
              ? order.order_info.address.province
              : 'no info provided'
          }
        />
      </div>
      <div className="sm:col-span-1">
        <InfoTitle content="ZIP" />
        <InfoContent
          content={
            order
              ? order.order_info.address.zip
              : 'no info provided'
          }
        />
      </div>
    </dl>
  </div>
</section>
)
};

export default VPNSection;