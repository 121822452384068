import AccessLevel from './AccessLevel';
import GameFilter from './GameFilter';
import RevShareInput from './RevShareInput';
import SystemSelect from './SystemSelect';

const AdminBlock = ({ platform, access, revShare, games, getAdminValues }) => {
  return (
    <>
      <div className="mt-2 flex flex-col xl:flex-row xl:items-center w-full">
        <div className="px-2 py-2 rounded-sm  ">
          <SystemSelect platform={platform} getAdminValues={getAdminValues} />
        </div>
        <div className="px-2 py-2 rounded-sm my-4 sm:my-0">
          <AccessLevel access={access} getAdminValues={getAdminValues} />
        </div>
        <div className="px-2 sm:py-2 rounded-sm sm:my-0 pt-0">
          <RevShareInput revShare={revShare} getAdminValues={getAdminValues} />
        </div>
      </div>
      <div>
        <GameFilter games={games} getAdminValues={getAdminValues} />
      </div>
    </>
  );
};

export default AdminBlock;
