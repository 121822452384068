import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import axios from '../../helpers/authCatch';
import axiosConfig from '../../helpers/axiosConfig';

const GamerTagCheck = ({ gamerTag }) => {
  const userAuth = useSelector((state) => state.auth.token);
  const Uid = useSelector((state) => state.auth.uid);
  const nameMap = useSelector((state) => state.ui.profileImgs.userIdNameMap);
  const [users, setUsers] = useState([]);

  const config = {
    headers: { Authorization: userAuth },
  };

  const getGTData = async () => {
    const response = await axios.get(
      axiosConfig(`gamertags/${gamerTag}`),
      config
    );
    return response;
  };

  useEffect(async () => {
    const resp = await getGTData();
    const allUsers = resp.data.users;
    const reduced = allUsers.filter((item, index) => {
      return allUsers.indexOf(item) === index && Uid !== item;
    });
    setUsers(reduced);
  }, []);

  return (
    <>
      {users.length > 0 ? (
        <div
          className={
            'm-4 p-4 rounded-md border text-center border-cprimary-primary'
          }
        >
          <ExclamationCircleIcon
            className="h-10 w-10 mr-auto ml-auto text-cprimary-primary"
            aria-hidden="true"
          />
          <div className="flex items-center">
            <div className={'text-white mb-2'}>
              {
                'Careful - this gamer tag is possibly in use by the following boosters:'
              }
            </div>
          </div>
          <div className={'flex justify-center'}>
            <div>
              {users
                ? users.map((user) => (
                    <span
                      key={user}
                      className="inline-flex items-center mr-2 px-3 py-0.5 rounded-full text-sm font-medium from-cprimary-primary to-red-400 bg-gradient-to-b text-gray-800"
                    >
                      {nameMap[user]}
                    </span>
                  ))
                : ''}
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default GamerTagCheck;
