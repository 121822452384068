import { orderActions } from './orders-slice';
import { progressActions } from './progress-slice';
import axios from '../helpers/authCatch';
import axiosConfig from '../helpers/axiosConfig';
import { uiActions } from './ui-slice';
import { conversationsActions } from './conversations-slice';

export const fetchOrderData = (query, username, userAuth) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const config = {
        headers: { Authorization: userAuth },
      };

      const path = `get-orders/${query}/${username}`;
      const response = await axios.get(axiosConfig(path), config);

      if (response.data === 'error') {
        throw new Error('Error fetching orders originating in /orders');
      }

      const data = await response.data;
      return data;
    };

    try {
      const orderData = await fetchData();
      dispatch(orderActions.setAllOrders({ allOrders: orderData }));
    } catch (error) {
      console.log(error);
    }
  };
};

export const addOrderToContractor = (
  order,
  contractor,
  orderStatus,
  orderNumber,
  userAuth
) => {
  return async (dispatch) => {
    const sendData = async () => {
      const body = {
        order_id: order,
        contractor_id: contractor,
        order_status: orderStatus,
      };

      const response = await axios.put(axiosConfig('orders/assign'), body, {
        headers: { Authorization: userAuth },
      });

      const data = await response.data;
      return data;
    };

    try {
      await sendData();
      dispatch(
        uiActions.showFlashAlert({
          message:
            orderStatus === 'unclaimed'
              ? `Order ${orderNumber} has been unassigned.`
              : `Order ${orderNumber} has been assigned.`,
        })
      );

      dispatch(
        orderActions.addOrderToContractor({
          order: order,
          contractor: contractor,
          order_status: orderStatus,
        })
      );

      setTimeout(() => {
        dispatch(uiActions.closeFlashAlert());
      }, 2000);
    } catch (error) {
      dispatch(
        uiActions.showFlashAlert({
          message:
            'There was an error assigning the order.'
        })
      );

      setTimeout(() => {
        dispatch(uiActions.closeFlashAlert());
      }, 3000);
    }
  };
};

export const changeOrderStatus = (order, status, userAuth) => {
  return async (dispatch) => {
    const sendData = async () => {
      const body = {
        order_id: order,
        status: status,
      };

      const response = await axios.put(axiosConfig('orders/status'), body, {
        headers: { Authorization: userAuth },
      });

      if (response.data === 'error') {
        throw new Error('Error fetching orders originating in /orders');
      }

      const data = await response.data;
      return data;
    };

    try {
      await sendData();
      dispatch(
        orderActions.updateOrderStatus({ order: order, status: status })
      );
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const addOrderImage = (image, orderId, order, userAuth) => {
  console.log('new persistor')
  return async (dispatch) => {
    const sendData = async () => {
      const formData = new FormData();
      formData.append('doc_id', orderId);
      formData.append('img_type', 'order');
      formData.append('img_number', Date.now());
      formData.append('image', image);

      const response = await axios.post(
        axiosConfig('orders/order-image'),
        formData,
        {
          headers: { Authorization: userAuth },
        }
      );

      if (response.data === 'error') {
        throw new Error('Error error uploading order image');
      }

      const data = await response.data;
      return data;
    };

    try {
      dispatch(
        progressActions.toggleProgress({
          progress: 'imageUploading',
          status: true,
        })
      );

      const resp = await sendData();

      dispatch(
        uiActions.showFlashAlert({
          message: 'Image successfully uploaded!',
        })
      );
      dispatch(
        orderActions.updateOrderImages({
          image: resp.img_url,
          orderId: order,
        })
      );
      dispatch(uiActions.addOrderDetailImage({ image: resp.img_url }));
      dispatch(
        progressActions.toggleProgress({
          progress: 'imageUploading',
          status: false,
        })
      );
      setTimeout(() => {
        dispatch(uiActions.closeFlashAlert());
      }, 2000);
    } catch (error) {
      dispatch(
        progressActions.toggleProgress({
          progress: 'imageUploading',
          status: false,
        })
      );
      dispatch(
        uiActions.showFlashAlert({
          message:
            'There was an error uploading your image. Please make sure it is a .png or .jpeg',
        })
      );
      setTimeout(() => {
        dispatch(uiActions.closeFlashAlert());
      }, 2000);

      console.log(error);
    }
  };
};

export const removeOrderImage = (file, orderId, userAuth) => {
  return async (dispatch) => {
    const sendData = async () => {
      const body = {
        order_id: orderId,
        img_to_delete: file,
      };

      const response = await axios.put(
        axiosConfig('orders/delete/order-image'),
        body,
        {
          headers: { Authorization: userAuth },
        }
      );

      if (response.data === 'error') {
        throw new Error(
          'Error fetching orders originating in /orders/order-image'
        );
      }

      const data = await response.data;
      return data;
    };

    try {
      await sendData();

      dispatch(
        orderActions.deleteOrderImage({
          image: file,
          orderId: orderId,
        })
      );
      dispatch(uiActions.removeOrderDetailImage({ image: file }));
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const archiveOrder = (
  orderDoc,
  orderId,
  twilioDoc,
  conversationId,
  type,
  userAuth
) => {
  return async (dispatch) => {
    const sendData = async () => {
      const body = {
        order_doc_id: orderDoc,
        twilio_doc_id: twilioDoc,
        conversation_id: conversationId,
        type: type,
      };
      const response = await axios.put(axiosConfig('orders/archive'), body, {
        headers: { Authorization: userAuth },
      });

      if (response.data === 'error') {
        throw new Error('Error fetching orders originating in /orders/approve');
      }

      console.log(response);
      const data = await response.data;
      return data;
    };
    try {
      await sendData();
      // dispatch(
      //   orderActions.updateOrderStatus({
      //     status: type,
      //     order: orderId,
      //   })
      // );
      dispatch(
        conversationsActions.removeConversation({
          conversation: conversationId,
        })
      );
      dispatch(
        uiActions.updateActiveConvoParticipants({
          state: [],
        })
      );
      dispatch(
        orderActions.addOrderToContractor({
          order: orderId,
          contractor: null,
          order_status: 'archived',
        })
      );
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const getPayoutData = (userId, userAuth) => {
  return async (dispatch) => {
    const getData = async () => {
      const body = { user_id: userId };

      const response = await axios.post(
        axiosConfig('orders/payoutsummary'),
        body,
        {
          headers: { Authorization: userAuth },
        }
      );

      if (response.data === 'error') {
        throw new Error('Error fetching orders originating in /orders/approve');
      }

      const data = await response.data;
      return data;
    };
    try {
      const resp = await getData();
      dispatch(
        orderActions.updatePaymentStatus({
          paymentObj: resp,
        })
      );
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const deleteOrder = (orderId, redirect, userAuth) => {
  return async (dispatch) => {
    const deleteOrder = async () => {

      const response = await axios.delete(
        axiosConfig(`orders/edit/${orderId}`),
        {
          headers: { Authorization: userAuth },
        }
      );

      if (response.data === 'error') {
        throw new Error('Error deleting order');
      }

      const data = await response.data;
      return data;
    };
    try {
      await deleteOrder();
      
      dispatch(
        uiActions.showFlashAlert({
          message:
            `Order ${orderId} deleted`,
        })
      );

      setTimeout(() => {
        dispatch(uiActions.closeFlashAlert());
        console.log(redirect)
        redirect()
      }, 2000);

    } catch (error) {
      dispatch(
        uiActions.showFlashAlert({
          message:
            `Error deleting order: ${orderId}`,
        })
      );

      setTimeout(() => {
        dispatch(uiActions.closeFlashAlert());
      }, 2000);

      console.log(error.message);
    }
  };
};