import React, { useState, useRef } from "react";
import { addOrderImage } from "../store/orders-actions";
import { useDispatch, useSelector } from "react-redux";

const ImageUpload = ({ orderId, order, setClose }) => {
  const [name, setName] = useState("");
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.auth.token);

  const handleChange = (e) => {
    setName(e.target.value);
  };
  const formSubmit = (e) => {
    e.preventDefault();

    const file = inputRef.current.files[0];

    if (!file) return;

    dispatch(addOrderImage(file, orderId, order, userAuth));
    setName("");
  };

  return (
    <form
      className="md:flex items-center mb-0 w-full"
      id="load-image-form"
      onSubmit={(e) => {
        formSubmit(e), setClose();
      }}
    >
      <input
        type="file"
        value={name}
        onChange={handleChange}
        ref={inputRef}
        className="m-2"
      />
      <button
        className="mr-2 md:mr-none rounded-md inline-flex items-center px-4 py-2 mr-4 border border-transparent text-sm font-medium shadow-sm text-white bg-csecondary-primary"
        type="submit"
      >
        Upload
      </button>
    </form>
  );
};

export default ImageUpload;

//Note that the “files” argument depends on the name of the input specified in the formData.
