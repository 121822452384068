import React, { useEffect } from 'react';
import StatRow from '../components/StatRow';
import PayoutDetails from '../components/Payouts/PayoutDetails';
import PayoutList from '../components/Payouts/PayoutList';
import { useSelector, useDispatch } from 'react-redux';
import { getPayoutData } from '../store/orders-actions';
import ShopifyLoader from '../components/helpers/ShopifyLoader';

const PayoutPage = () => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.auth.displayName);
  const userAuth = useSelector((state) => state.auth.token);
  const payoutData = useSelector((state) => state.orders.paymentObj);

  useEffect(async () => {
    dispatch(getPayoutData(userId, userAuth));
  }, []);

  useEffect(() => {}, [payoutData])

  return (
    <>
      {payoutData ? (
        <>
          <StatRow />
          <div className="flex flex-col-reverse py-2 lg:py-4 lg:flex-row mx-3">
            <PayoutDetails data={payoutData} />
            <PayoutList data={payoutData} />
          </div>
        </>
      ) : (
        <ShopifyLoader />
      )}
    </>
  );
};

export default PayoutPage;
