import { uiActions } from "./ui-slice";
import axios from "../helpers/authCatch";
import axiosConfig from "../helpers/axiosConfig";

export const getContractors = (userAuth) => {
  return async (dispatch) => {
    const sendData = async () => {
      const response = await axios.get(axiosConfig("contractors"), {
        headers: { Authorization: userAuth },
      });

      if (response.data === "error") {
        throw new Error("Error fetching orders originating in /orders");
      }

      const data = await response.data;
      return data;
    };

    try {
      const data = await sendData();
      dispatch(
        uiActions.setActiveContractors({
          activeContractors: data,
        })
      );
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const getUserDetail = (userAuth) => {
  return async (dispatch) => {
    const getData = async () => {
      const response = await axios.get(axiosConfig("user"), {
        headers: { Authorization: userAuth },
      });

      if (response.data === "error") {
        throw new Error("Error fetching user data in /user");
      }

      const data = await response.data;
      return data;
    };
    try {
      const data = await getData();
      dispatch(
        uiActions.editUserStats({
          userRating: data.avg_rating,
          userRevenue: data.total_revenue.toFixed(2),
          userUnpaid: data.unpaid_revenue.toFixed(2),
          userCompletedOrders: data.completed_orders,
          userPaymentRequested: data.user_payment_requested,
          requestedOrders: data.requested_orders || [],
          cancellationRequests: data.cancellation_requests || [],
          completedRequests: data.completed_order_requests || [],
          profileImgs: data.profileImgs,
          twilioUid: data.twilioUid,
          firstName: data.firstName,
          lastName: data.lastName,
          country: data.country,
          phone: data.phone,
          discord: data.discord,
          platforms: data.platforms,
          access: data.access,
          paypal: data.paypal,
          gamertag: data.gamertag,
          email: data.email,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
};

export const getOrderDetail = (orderId, userAuth) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const config = {
        headers: { Authorization: userAuth },
      };

      const path = `orders/order_detail/${orderId}`;
      const response = await axios.get(axiosConfig(path), config);

      if (response.data === "error") {
        throw new Error(
          "Error fetching orders originating in /orders/order_details"
        );
      }

      const data = await response.data;
      return data;
    };

    try {
      const orderData = await fetchData();
      dispatch(uiActions.setActiveOrderDetail({ orderDetail: orderData }));
    } catch (error) {
      return error;
    }
  };
};

export const getOrderConvoParticipants = (conversationId, userAuth) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const config = {
        headers: { Authorization: userAuth },
      };

      const body = {
        conversation_sid: conversationId,
      };

      const path = "chat/fetchConversationParticipants";
      const response = await axios.post(axiosConfig(path), body, config);

      if (response.data === "error") {
        throw new Error(
          "Error fetching orders originating in /chat/fetchConversationParticipants"
        );
      }

      const data = await response.data;
      return data;
    };

    try {
      const conversationData = await fetchData();
      let toAdd = [];
      conversationData.forEach((conversation) => {
        toAdd.push({
          sid: conversation.sid,
          name: conversation.identity
            ? conversation.identity
            : conversation.messagingBinding.address,
        });
      });
      dispatch(
        uiActions.updateActiveConvoParticipants({
          state: toAdd,
        })
      );
    } catch (error) {
      console.log(error)
    }
  };
};

export const addNoteToOrder = (orderId, note, author, userAuth) => {
  return async (dispatch) => {
    const sendData = async () => {
      dispatch(uiActions.changeProgress({ type: "noteUpload", state: true }));
      const config = {
        headers: { Authorization: userAuth },
      };

      const body = {
        note,
        author,
        order_doc_id: orderId,
      };

      const path = "orders/addNote";
      const response = await axios.post(axiosConfig(path), body, config);

      if (response.data === "error") {
        throw new Error("Error fetching orders originating in /orders/addNote");
      }

      dispatch(uiActions.changeProgress({ type: "noteUpload", state: false }));
      return response;
    };

    try {
      await sendData();
      dispatch(
        uiActions.addNoteToOrder({
          by: author,
          content: note,
          date: new Date(),
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
};

export const updateProfileImg = (image, username, userId, userAuth) => {
  return async (dispatch) => {
    const sendData = async () => {
      const formData = new FormData();
      formData.append("doc_id", username);
      formData.append("img_type", "profile");
      formData.append("img_number", Date.now());
      formData.append("image", image);

      const response = await axios.post(
        axiosConfig("users/profile-image"),
        formData,
        {
          headers: { Authorization: userAuth },
        }
      );

      if (response.data === "error") {
        throw new Error("Error uploading new profile picture");
      }

      const data = await response.data;
      return data;
    };

    try {
      dispatch(
        uiActions.changeProgress({ type: "imageUploading", state: true })
      );

      const data = await sendData();

      console.log(data);
      dispatch(
        uiActions.updateProfileImg({
          userName: username,
          userId: userId,
          image: data.img_url,
        })
      );
      dispatch(
        uiActions.changeProgress({ type: "imageUploading", state: false })
      );
    } catch (error) {
      console.log(error);
    }
  };
};
