import React from 'react';
import { FaXbox, FaPlaystation, FaArrowCircleLeft } from 'react-icons/fa';
import { HiOutlineDesktopComputer } from 'react-icons/hi';
import Badges from '../components/Badges';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Button from './Button';
import { Link } from 'react-router-dom';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const OrderCard = ({ order, onDetails, filterType, index, ordersLength }) => {
  const userRole = useSelector((state) => state.auth.role);
  const Uid = useSelector((state) => state.auth.uid);
  const profileImgs = useSelector((state) => state.ui.profileImgs);
  let history = useHistory();

  const dymanicWidth = ordersLength > 1 ? 'w-48w' : 'w-98w';

  return (
    <div
      key={order.order_id}
      className={`mx-auto my-2 w-98w py-2 shadow bg-cbackground-primary  flex flex-wrap justify-start align-center rounded-md  lg:${dymanicWidth} xl:flex-row  xl:my-2 xl:flex-nowrap xl:w-98w xl:mx-auto`}
    >
      <div className="px-4 py-4 whitespace-nowrap self-center w-full xl:w-1/3">
        <div className="flex items-center justify-start">
          <div className="flex-shrink-0 h-10 w-10 hidden md:inline-flex">
            <div className="h-10 w-10 rounded-full flex justify-center items-center">
              {filterType === 'AssignedOrders' ? (
                <img
                  className="h-100 w-100 rounded-full"
                  src={`${profileImgs.userId[order.assigned_contractor]}`}
                  alt=""
                />
              ) : (
                <div className="text-2xl text-ctext-primary">{`${index}:`}</div>
              )}
            </div>
          </div>
          {order.properties['PLATFORM'] === 'XBOX' && (
            <div className="flex-shrink-0 h-12 w-12 ml-6 p-2 bg-platform-xbox rounded-md xl:h-14 xl:w-14">
              <FaXbox size={'100%'} color={'#ffffff'} />
            </div>
          )}
          {order.properties['PLATFORM'] === 'PLAYSTATION' && (
            <div className="flex-shrink-0 h-12 w-12 ml-6 p-2 bg-platform-playstation rounded-md">
              <FaPlaystation size={'100%'} color={'#ffffff'} />
            </div>
          )}
          {order.properties['PLATFORM'] === 'PC' && (
            <div className="flex-shrink-0 h-12 w-12 ml-6 p-2 bg-platform-pc rounded-md">
              <HiOutlineDesktopComputer size={'100%'} color={'#ffffff'} />
            </div>
          )}
          <div className="ml-4">
            <div className="flex pb-2">
              <Link
                to={
                  order.assigned_contractor === Uid ||
                  ['admin', 'super'].includes(userRole)
                    ? `order_detail/${order.order_id}`
                    : '#'
                }
              >
                <div
                  className={classNames(
                    order.assigned_contractor === Uid ||
                      ['admin', 'super'].includes(userRole)
                      ? 'hover:text-blue-300 cursor-pointer'
                      : 'cursor-none',
                    'text-sm mr-4 text-ctext-accent font-semibold self-center'
                  )}
                >
                  {`#${order.order_info.order_number}${
                    order.order_info.order_index > 0
                      ? '-' + order.order_info.order_index
                      : ''
                  }`}
                </div>
              </Link>
              <div className={'mr-2'}>
                {order.properties['ADD ON'] === 'STREAM' && (
                  <Badges type={'stream'} />
                )}
                {['VIP', 'VIP ( FAST PASS )'].includes(order.properties['QUEUE TIME']) && (
                  <Badges type={'vip'} />
                )}
                {order.order_status !== 'unclaimed' ? (
                  <Badges type={order.order_status} />
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="text-sm text-ctext-primary">
              {order.order_info.title}
            </div>
            {order.assigned_contractor && order.assigned_contractor !== Uid ? (
              <div className="text-sm text-ctext-primary italic">
                {profileImgs.userIdNameMap[
                  order.assigned_contractor
                ].toUpperCase()}
              </div>
            ) : (
              ''
            )}
            <div className="text-xs italic text-ctext-primary">
              {new Date(order.order_info.created_at).toLocaleString()}
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 py-4 whitespace-nowrap self-center text-center w-1/3 xl:w-1/6">
        <div className="text-sm text-white font-semibold mb-1">
          {'Play Option'}
        </div>
        <div className="text-sm text-ctext-primary">
          {order.properties['PLAY OPTION'] ===
            'RECOVERY ( WE PLAY FOR YOU )' && (
            <span className="text-sm text-ctext-primary font-semibold">
              RECOVERY
            </span>
          )}
          {order.properties['PLAY OPTION'] === 'RECOVERY (WE PLAY FOR YOU)' && (
            <span className="text-sm text-ctext-primary font-semibold">
              RECOVERY
            </span>
          )}
          {[
            'CARRY (PLAY WITH OUR TEAM)',
            'CARRY ( PLAY WITH OUR TEAM )',
          ].includes(order.properties['PLAY OPTION']) && (
            <span className="text-sm text-ctext-primary font-semibold">
              CARRY
            </span>
          )}
          {!order.properties['PLAY OPTION'] && (
            <span className="text-sm text-ctext-primary font-semibold">
              RECOVERY
            </span>
          )}
        </div>
      </div>
      <div className="px-4 py-4 whitespace-nowrap self-center text-center w-1/3 xl:w-1/6">
        <div className="text-sm text-white font-semibold mb-1">{'Game'}</div>
        {order.order_info.game ? (
          <div className="text-sm text-ctext-primary font-semibold">
            {order.order_info.game.toUpperCase()}
          </div>
        ) : (
          ''
        )}
      </div>
      <div className="px-4 py-4 mr-0 xl:mr-4 whitespace-nowrap text self-center text-center w-1/3 xl:w-1/6">
        <div className="text-sm text-white font-semibold mb-1">{'Payout'}</div>
        <div className="text-sm text-ctext-primary font-semibold">
          {`$${parseFloat(order.order_info.price).toFixed(2)}`}
        </div>
      </div>
      {/* <div className="py-4 whitespace-nowrap text-center text-sm text-white self-center flex align-center justify-end w-full  xl:w-1/6 items-center">
        {['super', 'admin'].includes(userRole) ||
        order.order_status === 'claimed' ? (
          <Button
            text="VIEW DETAILS"
            action={() => history.push(`/order_detail/${order.order_id}`)}
            style="outline"
          />
        ) : (
          <div className="h-6 w-6 mx-5" />
        )}
        {["super", "admin"].includes(userRole) ||
        order.order_status !== "unclaimed" ? (
          <Link to={`/order_detail/${order.order_id}`}>
            <button
              type="button"
              className="mx-4 text-ctext-primary hover:text-ctext-active self-center"
              onClick={() => {
            onDetails(order);
          }}
            >
              <FaArrowCircleLeft className="h-6 w-6" aria-hidden="true" />
            </button>
          </Link>
        ) : (
          ""
        )}
      </div> */}
      <div className="py-4 whitespace-nowrap text-center text-sm text-white self-center flex align-center justify-end  items-center  w-full xl:w-1/6">
        {['super', 'admin'].includes(userRole) ||
        order.order_status === 'claimed' ? (
          <Button
            text="VIEW DETAILS"
            action={() => history.push(`/order_detail/${order.order_id}`)}
            style="outline"
          />
        ) : (
          <div className="h-6 w-6 mx-5" />
        )}
        <button
          onClick={() => {
            onDetails(order);
          }}
          type="button"
          className="mx-6 text-ctext-primary hover:text-ctext-active"
        >
          <FaArrowCircleLeft className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
};

export default OrderCard;
