import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { uiActions } from "../store/ui-slice";
import axios from "axios";
import axiosConfig from "../helpers/axiosConfig";

export const useFetch = (info) => {
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.auth.token);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  async function makeRequest() {
    setLoading(true);
    const members = await axios.get(axiosConfig(info.path), {
      headers: { Authorization: userAuth },
    });
    const data = await members.data;
    if (!data) return; // wait till data loads
    setData(data);
    setLoading(false);
  }

  useEffect(() => {
    function renderData() {
      try {
        makeRequest();
      } catch (error) {
        dispatch(
          uiActions.showFlashAlert({
            message: info.errorMessage,
          })
        );
        setTimeout(() => {
          dispatch(uiActions.closeFlashAlert());
        }, 3000);
      }
    }
    renderData();
  }, []);

  return {
    data,
    loading,
  };
};
