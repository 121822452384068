import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Menu, Transition } from '@headlessui/react';
import {
  AnnotationIcon,
  BellIcon,
  GiftIcon,
  MenuAlt2Icon,
  XIcon,
  ChartBarIcon,
  CurrencyDollarIcon,
  ClipboardCheckIcon,
  UserGroupIcon,
  UserIcon,
  LogoutIcon,
} from '@heroicons/react/solid';
import playerboost_logo from '../assets/images/playerboost_logo.png';
import SideNav from '../components/SideNav';
import { SearchIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import NumberBadge from './NumberBadge';
import { useHistory, useLocation } from 'react-router-dom';
import ConversationTrigger from './conversations/ConversationTrigger';
import { getJWTToken } from '../store/conversations-actions';
import { conversationsActions } from '../store/conversations-slice';
import { Client as ConversationsClient } from '@twilio/conversations';

//const userNavigation = [
  // { name: 'Your Profile', href: '#' },
  // { name: 'Settings', href: '#' },
  // { name: 'Sign out', href: '#' },
//];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const WithNav = (props) => {
  const userName = useSelector((state) => state.auth.displayName);
  const conversations = useSelector(
    (state) => state.conversations.conversations
  );
  const role = useSelector((state) => state.auth.role);
  const notifications = useSelector(
    (state) => state.notifications.allNotifications
  );
  const profileImgs = useSelector((state) => state.ui.profileImgs);
  const [searchQuery, setSearchQuery] = useState();
  const Uid = useSelector((state) => state.auth.uid);
  const displayName = useSelector((state) => state.auth.displayName);
  const dispatch = useDispatch();
  const [selectedConversationSid, setSelectedConversationSid] = useState();
  const [status, setStatus] = useState();
  const [selectedConversation, setSelectedConversation] = useState();
  const TwilioJWT = useSelector((state) => state.conversations.JWT);
  // toggle nav
  const [showNav, setShowNav] = useState(false);
  let history = useHistory();
  const location = useLocation();
  const userAuth = useSelector((state) => state.auth.token);
  const orders = useSelector((state) => state.orders.allOrders);
  const [tipCount, setTipCount] = useState(0);

  const navigation = [
    {
      name: 'Dashboard',
      href: '/dashboard',
      icon: ChartBarIcon,
      current: true,
    },
    { name: 'Orders', href: '/orders', icon: AnnotationIcon, current: false },
    {
      name: 'Payout',
      href: '/payout',
      icon: CurrencyDollarIcon,
      current: false,
    },
    {
      name: 'My Profile',
      params: {
        pathname: `/profile/${displayName}`,
      },
      href: `/profile/${displayName}`,
      icon: UserIcon,
      current: false,
    },
  ];

  if (['super', 'admin'].includes(role)) {
    navigation.push({
      name: 'Notifications',
      href: '/notifications',
      icon: ClipboardCheckIcon,
      current: false,
    });
  }

  if (['super'].includes(role)) {
    navigation.push({
      name: 'Members',
      href: '/members',
      icon: UserGroupIcon,
      current: false,
    });
  }

  // This code was started but never tested or presented to the client to use. The basic idea is to look up an order Id, you would type: id:12478798069981, or user:ALI
  const queryRedirect = () => {
    const splits = searchQuery.split(':');

    if (['num', 'id', 'user'].includes(splits[0])) {
      if (splits[0] === 'id') {
        history.push(`/order_detail/${splits[1]}`);
        setTimeout(() => {
          setSearchQuery('');
        }, 1500);
      }

      if (splits[0] === 'num') {
        history.push(`/order_number/${splits[1]}`);
        setTimeout(() => {
          setSearchQuery('');
        }, 1500);
      }

      if (splits[0] === 'user') {
        history.push(`/user_detail/${splits[1]}`);
        setTimeout(() => {
          setSearchQuery('');
        }, 1500);
      }
    } else {
      setSearchQuery('No Match, try again');
      setTimeout(() => {
        setSearchQuery('');
      }, 1500);
    }
  };

  const getTipCount = () => {
    return orders.filter((order) => {
      return (
        order.assigned_contractor === Uid &&
        ['Tip', 'TIP YOUR BOOSTER'].includes(order.order_info.title)
      );
    }).length;
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      queryRedirect();
    }
  };

  useEffect(() => {
    initConversations(TwilioJWT);
    const tips = getTipCount();
    setTipCount(tips);
  }, [TwilioJWT]);

  useEffect(() => {
    setSelectedConversation(
      conversations.find((it) => it.sid === selectedConversationSid) || null
    );
  }, [selectedConversationSid]);

  // This instantiates the Twilio client
  const initConversations = async (JWT) => {
    const thisClient = new ConversationsClient(JWT);

    thisClient.on('connectionStateChanged', (state) => {
      if (state === 'connecting') {
        setStatus('default');
      }
      if (state === 'connected') {
        setStatus('success');
      }
      if (state === 'disconnecting') {
        setStatus('default');
      }
      if (state === 'disconnected') {
        setStatus('warning');
      }
      if (state === 'denied') {
        setStatus('error');
        dispatch(getJWTToken(userName, userAuth));
      }
    });
    thisClient.on('conversationJoined', (conversation) => {
      dispatch(
        conversationsActions.addConversation({ conversation: conversation })
      );
    });
    thisClient.on('conversationLeft', (conversation) => {
      dispatch(
        conversationsActions.removeConversation({ conversation: conversation })
      );
    });
    thisClient.on('messageAdded', (message) => {
      console.log(message);
    });
  };

  return (
    <>
      <div className="anchorDiv">
        <Transition.Root show={showNav} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 flex z-40 md:hidden"
            onClose={() => setShowNav(false)}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-cbackground-dark">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setShowNav(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-shrink-0 flex items-center px-4">
                  <img
                    className="h-8 w-auto"
                    src={playerboost_logo}
                    alt="playerboost logo"
                  />
                </div>
                <div className="mt-5 flex-1 h-0 overflow-y-auto">
                  <nav className="px-2 space-y-1">
                    {navigation.map((item) => (
                      <Link
                        to={item.href}
                        key={item.href}
                        onClick={() => setShowNav(false)}
                      >
                        <div
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.href === location.pathname
                              ? 'bg-cprimary-primary text-white'
                              : 'text-indigo-100 hover:bg-indigo-600',
                            'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                          )}
                        >
                          <item.icon
                            className="mr-4 flex-shrink-0 h-6 w-6 text-ctext-primary"
                            aria-hidden="true"
                          />
                          {item.name}
                        </div>
                      </Link>
                    ))}
                    <a
                      key={'Logout'}
                      onClick={() => window.persistor.purge()}
                      href={'/#'}
                      className={
                        'mt-0 text-indigo-100 hover:bg-indigo-600 group flex items-center px-2 py-2 text-base font-medium rounded-md'
                      }
                    >
                      <LogoutIcon
                        className={
                          'mr-4 flex-shrink-0 h-6 w-6 text-ctext-primary'
                        }
                      />
                      {'Logout'}
                    </a>
                  </nav>
                </div>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>
        <SideNav navigation={navigation} />
        <div className="md:pl-64 flex flex-col flex-1 bg-cbackground-dark min-h-screen pb-72">
          <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-cbackground-dark shadow">
            <button
              type="button"
              className="px-4  text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
              onClick={() => setShowNav(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex-1 pr-4 flex justify-between bg-cbackground-light">
              <div className="flex-1 flex">
                <label
                  htmlFor="search-field"
                  className="sr-only text-ctext-primary"
                >
                  Search
                </label>
                <div className="relative w-full text-ctext-primary focus-within:text-gray-600">
                  <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                    <SearchIcon className="h-5 w-5 ml-2" aria-hidden="true" />
                  </div>
                  <input
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onSubmit={queryRedirect}
                    onKeyPress={handleKeyPress}
                    id="search-field"
                    className="block w-full h-full pl-8 pr-3 py-2 bg-gray-600 border-transparent text-ctext-primary placeholder-ctext-primary focus:outline-none focus:placeholder-ctext-primary focus:ring-0 focus:border-transparent sm:text-sm font-thin md:w-3/4"
                    placeholder="Search"
                    type="search"
                    name="search"
                  />
                </div>
                {/* </form> */}
              </div>
              <div className="ml-4 flex items-center md:ml-6">
                <div className="relative">
                  {tipCount > 0 ? <NumberBadge number={tipCount} /> : ''}
                  <button
                    type="button"
                    className="mx-4 text-ctext-primary hover:text-ctext-active focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <span className="sr-only">View notifications</span>
                    <GiftIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="relative">
                  {notifications.length > 0 ? (
                    <NumberBadge number={notifications.length} />
                  ) : (
                    ''
                  )}
                  <button
                    type="button"
                    className="mx-4 text-ctext-primary hover:text-ctext-active focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="text-right ml-2 hidden md:block">
                  <p className="text-sm text-white">Hello, {userName}</p>
                  <p className="text-xs text-cprimary-primary uppercase">
                    {role || 'role not set'}
                  </p>
                </div>

                {/* Profile dropdown */}
                <Menu as="div" className="ml-3 relative">
                  <div>
                    <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full cursor-none">
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="h-8 w-8 rounded-full"
                        src={`${
                          profileImgs.userId[Uid]
                            ? profileImgs.userId[Uid]
                            : 'https://i.pinimg.com/originals/c6/92/85/c69285419db3f7f436aca78bd4bccbcf.png'
                        }`}
                        alt="user avatar"
                      />
                    </Menu.Button>
                  </div>
                </Menu>
              </div>
            </div>
          </div>
          {props.children}
        </div>
        
        {(status === 'success' && conversations.length > 0) ||
        /* Gets all avaiable conversations for Admins, and sets the selectedConversation to pass back into the conversation Trigger */
        ['super', 'admin'].includes(role) ? (
          <ConversationTrigger
            conversations={conversations}
            onConversationClick={(item) => {
              if (!item) {
                setSelectedConversationSid(null);
              } else {
                setSelectedConversationSid(item);
              }
            }}
            selectedConversation={selectedConversation}
          />
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default WithNav;
