// Loaded From WithNav.js
// Contatins the lower left conversation widget (Pencil / X Icon)

import React, { Fragment, useEffect, useState } from 'react';
import { Transition, Popover } from '@headlessui/react';
import Toggle from '../../components/helpers/Toggle';
import Badge from '../../components/Badges';
import {
  PencilIcon,
  XIcon,
  ChatIcon,
  ArrowLeftIcon,
} from '@heroicons/react/outline';
import Conversation from './Conversation';
import { useSelector, useDispatch } from 'react-redux';
import { addChatUserToTwilio } from '../../store/conversations-actions';
import { Link } from 'react-router-dom';
import { PaperAirplaneIcon } from '@heroicons/react/solid';

const ConversationTrigger = ({
  // Conversation list from Redux (state.conversations.conversations)
  conversations,
  // Sets selectedConversationSid in WithNav.js which looks up the conversation and sets selectedConversation
  onConversationClick,
  // Conversation that was selected from onConversationClick
  selectedConversation,
}) => {
  const dispatch = useDispatch();
  const userName = useSelector((state) => state.auth.displayName);
  const userAuth = useSelector((state) => state.auth.token);
  const Uid = useSelector((state) => state.auth.uid);
  const role = useSelector((state) => state.auth.role);
  const orders = useSelector((state) => state.orders.allOrders || []);
  const [assignedOrders, setAssignedOrders] = useState();
  const [activeConvos, setActiveConvos] = useState([]);
  const profileImgs = useSelector((state) => state.ui.profileImgs);
  const userConversations = useSelector(
    (state) => state.conversations.conversations
  );

  // Runs when the conversation list is changed from WithNav.js
  // userConversations is pulled from the active conversation list in redux
  useEffect(() => {
    let convoList = [];
    userConversations.forEach((convo) => {
      convoList.push(convo.sid);
    });

    setActiveConvos(convoList);
  }, [conversations]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  // This is the conversation widget in the bottom left corner of the app
  return (
    <Popover className="fixed bottom-6 right-6 flex z-40">
      {({ open }) => (
        <>
          <Popover.Button
            className={classNames(
              open ? 'bg-background-primary' : 'bg-background-secondary',
              'group rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 md:z-50'
            )}
          >
            <div
              type="button"
              className={`inline-flex items-center p-3 border border-transparent rounded-full shadow-sm text-white focus:outline-none ${
                open
                  ? 'bg-cprimary-primary hover:bg-cprimary-light'
                  : 'bg-csecondary-primary hover:bg-csecondary-light'
              }`}
            >
              {open ? (
                <XIcon className="h-8 w-8" aria-hidden="true" />
              ) : (
                <PencilIcon className="h-8 w-8" aria-hidden="true" />
              )}
            </div>
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-x-10"
            enterTo="opacity-100 translate-x-10"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            {
              // if there is a selectedConversation show the message list, Else show the conversation list. The selected conversation is set in local state at WithNav.js
              selectedConversation ? (
              <Popover.Panel className="fixed left-0 right-0 bottom-0 top-0 z-10 md:absolute md:bottom-8 md:right-8 md:top-auto md:left-auto">
                <div className="md:rounded-lg md:shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className="relative flex flex-col h-full bg-white md:h-mdChat md:w-mdChat "> 
                    {/* This div is the header for the conversation window that contains the back arrow and the link to the order page */}                 
                    <div className="flex p-4 bg-cprimary-primary items-center">
                      <ArrowLeftIcon
                        className="h-6 w-6 text-white mr-4 flex-none"
                        aria-hidden="true"
                        // This clears the selected conversation and renders the conversation list
                        onClick={() => onConversationClick()}
                      />
                      <div>
                        <Link
                          to={`/order_detail/${
                            orders.find(
                              (order) =>
                                order.conversation.sid ===
                                selectedConversation.sid
                            ).order_id
                          }`}
                        >
                          <div className={'flex items-center'}>
                            <div className="text-white font-light flex-initial">
                              {selectedConversation.friendlyName}
                            </div>
                            <PaperAirplaneIcon
                              className="h-5 w-5 mx-2 mb-1 text-white flex-none rotate-45"
                              aria-hidden="true"
                            />
                          </div>
                        </Link>
                      </div>
                    </div>
                    <Conversation
                      conversationProxy={selectedConversation}
                      myIdentity={userName}
                    />
                  </div>
                </div>
              </Popover.Panel>
            ) : (
              // If selectedConversation is false, show the conversation list
              <Popover.Panel className="fixed left-0 right-0 bottom-0 top-0 z-10 md:absolute md:bottom-8 md:right-8 md:top-auto md:left-auto">
                <div className="md:rounded-lg md:shadow-lg  ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className="relative flex flex-col h-full bg-white md:h-mdChat md:w-mdChat">
                    {/* This div is the header for the conversation window */}
                    <div className="flex p-4 bg-cprimary-primary items-center justify-between">
                      <div className="flex">
                        <ChatIcon
                          className="h-6 w-6 text-white mr-2"
                          aria-hidden="true"
                        />
                        <div>
                          <div className="text-white font-bold">
                            {assignedOrders ? 'Active Orders' : 'My Orders'}
                          </div>
                        </div>
                      </div>
                      {
                        // Admins and Supers can toggle, which sets assignedOrders to False, revaleaing the list of all orders
                        // When assignedOrders is True this shows all of the users assigned conversations all active conversations (which is the only option for non admins)
                        ['super', 'admin'].includes(role) ? (
                        <div className={'ml-4'}>
                          <Toggle
                            toggle={(state) => {
                              setAssignedOrders(state);
                            }}
                            enabled={assignedOrders}
                          />
                        </div>
                      ) : (
                        ''
                      )}
                      <Popover.Button
                        className={classNames(
                          open
                            ? 'bg-background-primary '
                            : 'bg-background-secondary',
                          'group rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 ml-auto md:hidden'
                        )}
                      >
                        <div
                          type="button"
                          className={`inline-flex items-center p-3 border border-transparent rounded-full shadow-sm text-white focus:outline-none`}
                        >
                          <XIcon className="h-8 w-8" aria-hidden="true" />
                        </div>
                      </Popover.Button>
                    </div>
                    <div className="w-100 items-center overflow-y-scroll">
                      {
                        /* If assignedOrders is True show only the logged in users assignedOrders  */
                        assignedOrders ? (
                        <>
                          {orders.map(
                            (order) =>
                              // Filters to build order list from all Orders
                              order.conversation.sid &&
                              order.assigned_contractor !== Uid &&
                              order.order_status !== 'unclaimed' &&
                              order.order_status !== 'approved' && (
                                <button
                                  key={Math.random() * 1000}
                                  className={
                                    'px-3 py-4 hover:bg-cbackground-lightgray transition ease-in-out duration-150 flex justify-between items-center w-full border-b'
                                  }
                                  onClick={() => {
                                    /* 
                                    if the activeConvos list incldues the selected conversation run onConversationClick (to set the selectedConversation)
                                    else run addChatUserToTwilio, which calls the Twilio API to add the user 
                                    */
                                    if (
                                      activeConvos.includes(
                                        order.conversation.sid
                                      )
                                    ) {
                                      // set the current conversation
                                      onConversationClick(
                                        order.conversation.sid
                                      );
                                    } else {
                                      // Adds the user to the twilio conversation
                                      dispatch(
                                        addChatUserToTwilio(
                                          order.conversation.sid,
                                          userName,
                                          `${order.order_id}_${order.order_info.order_number}`,
                                          userAuth
                                        )
                                      );
                                    }
                                  }}
                                >
                                {/* This is is the visual elements for the convesation card, mapped to create the conversation list */}
                                  <div className="text-left">
                                    <p className="text-sm text-black mb-2">
                                      {`${order.order_info.title} - ${order.order_info.order_number}`}
                                    </p>
                                    <div className="flex">
                                      <div className={'mr-2'}>
                                        <Badge type={order.order_status} />
                                      </div>
                                      <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium from-blue-500 to-blue-300 bg-gradient-to-b text-gray-800">
                                        {
                                          profileImgs.userIdNameMap[
                                            order.assigned_contractor
                                          ]
                                        }
                                      </span>
                                    </div>
                                  </div>
                                  <div className="h-12 w-12 rounded-full flex justify-center items-center">
                                    <img
                                      className="h-100 w-100 rounded-full"
                                      src={`${
                                        profileImgs.userId[
                                          order.assigned_contractor
                                        ]
                                      }`}
                                      alt=""
                                    />
                                  </div>
                                </button>
                              )
                          )}
                        </>
                      ) : (
                        <>
                          {
                            /* 
                            assignedOrders is False show list of all orders
                            */
                            ['admin', 'super'].includes(role) ? (
                            <>
                              {orders.map(
                                (order) =>
                                  // Show all orders that arent Tips
                                  order.assigned_contractor === Uid &&
                                  !['Tip', 'TIP YOUR BOOSTER'].includes(
                                    order.order_info.title
                                  ) && (
                                    <button
                                      key={Math.random() * 1000}
                                      className={
                                        'px-3 py-4 hover:bg-cbackground-lightgray transition ease-in-out duration-150 flex justify-between items-center w-full border-b'
                                      }
                                      onClick={() => {
                                        if (
                                          activeConvos.includes(
                                            order.conversation.sid
                                          )
                                        ) {
                                          // set the current conversation
                                          onConversationClick(
                                            order.conversation.sid
                                          );
                                        } else {
                                          // Adds the user to the twilio conversation
                                          dispatch(
                                            addChatUserToTwilio(
                                              order.conversation.sid,
                                              userName,
                                              `${order.order_id}_${order.order_info.order_number}`,
                                              userAuth
                                            )
                                          );
                                        }
                                      }}
                                    >
                                      <div className="text-left">
                                        <p className="text-sm text-black mb-2">
                                          {`${order.order_info.title} - ${order.order_info.order_number}`}
                                        </p>
                                        <div className="flex">
                                          <div className={'mr-2'}>
                                            <Badge type={order.order_status} />
                                          </div>
                                          <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium from-blue-500 to-blue-300 bg-gradient-to-b text-gray-800">
                                            {
                                              profileImgs.userIdNameMap[
                                                order.assigned_contractor
                                              ]
                                            }
                                          </span>
                                        </div>
                                      </div>
                                      <div className="h-12 w-12 rounded-full flex justify-center items-center">
                                        <img
                                          className="h-100 w-100 rounded-full"
                                          src={`${
                                            profileImgs.userId[
                                              order.assigned_contractor
                                            ]
                                          }`}
                                          alt=""
                                        />
                                      </div>
                                    </button>
                                  )
                              )}
                            </>
                          ) : (
                            <>
                              {
                                /*
                                Show all orders that are assigned to the logged in user
                                */
                                orders.map((order) => {
                                if (
                                  order.conversation.participants.includes(
                                    userName
                                  )
                                ) {
                                  return (
                                    <div>
                                      <button
                                        key={Math.random() * 1000}
                                        className={
                                          'px-3 py-4 hover:bg-cbackground-lightgray transition ease-in-out duration-150 flex justify-between items-center w-full border-b'
                                        }
                                        onClick={() => {
                                          if (
                                            activeConvos.includes(
                                              order.conversation.sid
                                            )
                                          ) {
                                            // set the current conversation
                                            onConversationClick(
                                              order.conversation.sid
                                            );
                                          } else {
                                            dispatch(
                                              // Adds the user to the twilio conversation
                                              addChatUserToTwilio(
                                                order.conversation.sid,
                                                userName,
                                                `${order.order_id}_${order.order_info.order_number}`,
                                                userAuth
                                              )
                                            );
                                          }
                                        }}
                                      >
                                        <div className="text-left">
                                          <p className="text-sm text-black mb-2">
                                            {`${order.order_info.title} - ${order.order_info.order_number}`}
                                          </p>
                                          <div className="flex">
                                            <div className={'mr-2'}>
                                              <Badge
                                                type={order.order_status}
                                              />
                                            </div>
                                            <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium from-blue-500 to-blue-300 bg-gradient-to-b text-gray-800">
                                              {
                                                profileImgs.userIdNameMap[
                                                  order.assigned_contractor
                                                ]
                                              }
                                            </span>
                                          </div>
                                        </div>
                                        <div className="h-12 w-12 rounded-full flex justify-center items-center">
                                          <img
                                            className="h-100 w-100 rounded-full"
                                            src={`${
                                              profileImgs.userId[
                                                order.assigned_contractor
                                              ]
                                            }`}
                                            alt=""
                                          />
                                        </div>
                                      </button>
                                    </div>
                                  );
                                }
                              })}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            )}
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default ConversationTrigger;
