import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import ordersSlice from './orders-slice';
import uiSlice from './ui-slice';
import authSlice from './auth-slice';
import notificationsSlice from './notifications-slice';
import conversationsSlice from './conversations-slice';
import progressSlice from './progress-slice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: ["progress"]
}

const rootReducer = combineReducers({
  orders: ordersSlice.reducer,
  auth: authSlice.reducer,
  ui: uiSlice.reducer,
  notifications: notificationsSlice.reducer,
  conversations: conversationsSlice.reducer,
  progress: progressSlice.reducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['conversations/addConversation', FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    },
  }),
  reducer: persistedReducer,
});

export default store;
