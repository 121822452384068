import React from 'react';
import { useSelector } from 'react-redux';
import Card from '../components/helpers/Card';
import DetailCard from '../components/Profile/DetailCard';
import UserCard from '../components/Profile/UserCard';
import StatRow from '../components/StatRow';

const ProfilePage = () => {
  const userInfo = useSelector((state) => state.ui);

  const passedDisplayName = useSelector(
    (state) => state.auth.displayName || ''
  );
  const access = useSelector((state) => state.auth.role || '');
  const email = userInfo.email || '';
  const platforms = userInfo.platforms || [];
  const firstName = userInfo.firstName || '';
  const lastName = userInfo.lastName || '';
  const gamertag = userInfo.gamertag || '';
  const country = userInfo.country || '';
  const paypal = userInfo.paypal || '';
  const discord = userInfo.discord || '';

  return (
    <div>
      <StatRow />
      <div className=" w-full flex-col flex lg:flex-row-reverse">
        <div className="md:2/12 lg:w-4/12 bg-cbackground-primary mt-3 rounded-lg">
          <Card
            details={{
              styling: 'w-full flex flex-col bg-transparent	shadow-none',
            }}
          >
            <UserCard
              displayName={passedDisplayName}
              access={access}
              email={email}
              platforms={platforms}
            />
          </Card>
        </div>
        <div className="md:10/12 md:mx-2 lg:w-8/12">
          <Card
            details={{
              heading: 'Personal Information',
              styling: 'w-full',
            }}
          >
            <DetailCard
              firstName={firstName}
              lastName={lastName}
              gamerTag={gamertag}
              email={email}
              country={country}
              paypal={paypal}
              discord={discord}
            />
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
