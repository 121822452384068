import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Badge from '../../components/Badges';
import ActionDropdown from '../../components/OrderDetailPage/ActionDropdown';
import AssignOrderAction from '../../components/OrderDetailPage/AssignOrderAction';
import InfoContent from '../../components/helpers/InfoContent';
import { DatabaseIcon, CurrencyDollarIcon } from '@heroicons/react/solid';

const DetailHeader = ({ approvalPrompt }) => {
  const { order_id } = useParams();
  const basicOrder = useSelector(
    (state) =>
      state.orders.allOrders.filter(
        (order) => order.order_id === parseInt(order_id)
      )[0]
  );
  const userRole = useSelector((state) => state.auth.role);
  const profileImgs = useSelector((state) => state.ui.profileImgs);
  const order = useSelector((state) => state.ui.activeOrderDetail);

  return (
    <div className="max-w-3xl p-4 mx-auto lg:mx-0 sm:px-6 md:flex md:items-center lg:max-w-7xl lg:px-8">
      <div className="w-full">
        <div className="flex items-center">
          <h1 className="text-3xl font-bold text-ctext-accent">{`#${
            basicOrder.order_info.order_number
              ? `${basicOrder.order_info.order_number}${
                  basicOrder.order_info.order_index > 0
                    ? '-' + basicOrder.order_info.order_index
                    : ''
                }`
              : ''
          }`}</h1>
          <div className="ml-4 mr-2">
            <Badge type={basicOrder.order_status} />
          </div>
          {order.conversation.assigned_number === 'NO NUMBER PROVIDED' && (
            <span className="mr-2 inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium from-indigo-800 to-blue-500 bg-gradient-to-b text-gray-800">
              NO TWILIO NUMBER
            </span>
          )}
          {basicOrder.conversation.sid === '' && userRole === 'user' ? (
            <span className="mr-2 inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium from-indigo-800 to-blue-500 bg-gradient-to-b text-gray-800">
              NO CONVERSATION
            </span>
          ) : (
            ''
          )}
          {basicOrder.conversation.client_phone === 'NO CLIENT NUMBER PROVIDED' ? (
            <span className="mr-2 inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium from-indigo-800 to-blue-500 bg-gradient-to-b text-gray-800">
              NO CLIENT NUMBER
            </span>
          ) : (
            ''
          )}
          <ActionDropdown order={order} />
        </div>
        <div className="flex mt-2">
          <div className="flex mr-4">
            <CurrencyDollarIcon
              className="h-6 w-6 mr-2 text-ctext-primary"
              aria-hidden="true"
            />
            <InfoContent content={`$${order.payment_info.payout}`} />
          </div>
          {order.payment_info.tip > 0 ? (
            <div className="flex">
              <DatabaseIcon
                className="h-6 w-6 mr-2 text-ctext-primary"
                aria-hidden="true"
              />
              <InfoContent content={`$${order.payment_info.tip}`} />
            </div>
          ) : (
            ''
          )}
          {['approved', 'paid'].includes(order.order_status) &&
            ['super', 'admin'].includes(userRole) && (
              <InfoContent
                content={
                  profileImgs.userIdNameMap[basicOrder.assigned_contractor]
                }
              />
            )}
        </div>
        {['super', 'admin'].includes(userRole) &&
        !approvalPrompt &&
        ['unclaimed', 'claimed'].includes(basicOrder.order_status) ? (
          <AssignOrderAction />
        ) : (
          ''
        )}
      </div>
      <div className="flex-shrink-0 ml-auto">
        <div className="absolute top-4 right-4">
          {order.assigned_contractor !== null ? (
            <>
              <img
                className="h-16 w-16 rounded-full "
                src={`${profileImgs.userId[basicOrder.assigned_contractor]}`}
                alt=""
              />
              <span
                className="absolute inset-0 shadow-inner rounded-full"
                aria-hidden="true"
              />
            </>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default DetailHeader;
