export const InfoContent = ({ content, style }) => {
  if (style === 'italic') {
    return <dd className="text-sm text-ctext-primary italic uppercase">{content}</dd>;
  } else if (style === 'no-caps') {
    return <dd className="text-sm text-ctext-primary font-semibold">{content}</dd>;
  } else {
    return <dd className="text-sm text-ctext-primary font-semibold uppercase">{content}</dd>;
  }
};


export default InfoContent;