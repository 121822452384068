import { deleteNotification } from '../../store/notifications-actions';
import playerboost_only_logo from '../../assets/images/playerboost_only_logo.png';
import { BsFillXCircleFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';

const NotificationsPanelContent = ({ content }) => {
  let contentType;
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.auth.token);
  const role = useSelector((state) => state.auth.role);

  content.length <= 0
    ? (contentType = (
        <div className="text-center text-sm text-white border-top border-color-white ">
          No new notifications...
        </div>
      ))
    : (contentType = content.map((notification) => (
          <tr
            key={Math.random() * 1000}
            //className="border-b border-1 border-cbackground-light"
          >
            <td className="whitespace-nowrap my-2">
              <div className="flex flex-col items-start justify-center">
                <div
                  className="flex-shrink-0 h-10 pr-2"
                  style={{ minWidth: '2.5rem' }}
                >
                  <img
                    className="h-10 rounded-full"
                    src={playerboost_only_logo}
                    alt="playerboost logo"
                  />
                </div>
              </div>
            </td>
            <td>
              <div className="text-sm text-ctext-primary">
                {notification.payload.message}
              </div>
              <div className="text-xs text-ctext-primary">
                {new Date(notification.created_at).toLocaleString()}
              </div>
            </td>
            {['admin', 'super'].includes(role) ? (
              ''
            ) : (
              <td>
                <button className="text-ctext-primary hover:text-ctext-active self-center">
                  <BsFillXCircleFill
                    className="h-4 w-4"
                    aria-hidden="true"
                    onClick={() =>
                      dispatch(
                        deleteNotification(
                          notification.payload.doc_id,
                          userAuth
                        )
                      )
                    }
                  />
                </button>
              </td>
            )}
          </tr>
      )));

  return <>{contentType}</>;
};

export default NotificationsPanelContent;
