import { useSelector } from 'react-redux';
import InfoContent from '../helpers/InfoContent';

const NotesFeed = () => {
  const profileImgs = useSelector((state) => state.ui.profileImgs.userName);
  const notes = useSelector(
    (state) => state.ui.activeOrderDetail.order_info.note
  );

  return (
    <>
      {notes ? (
        <div className="flow-root">
          <ul role="list" className="-mb-8">
            {notes.map((event, eventIdx) => (
              <li key={Math.random() * 1000}>
                <div className="relative pb-8">
                  {eventIdx !== notes.length - 1 ? (
                    <span
                      className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                      aria-hidden="true"
                    />
                  ) : null}
                  <div className="relative flex space-x-3">
                    <div>
                      <img
                        className="h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-cbackground-primary"
                        src={`${
                          profileImgs[event.by]
                            ? profileImgs[event.by]
                            : 'https://i.pinimg.com/originals/c6/92/85/c69285419db3f7f436aca78bd4bccbcf.png'
                        }`}
                        alt="user avatar"
                      />
                    </div>
                    <div className="min-w-0 flex-1 flex justify-between space-x-4">
                      <div>
                        <p className="text-sm text-gray-500">
                          <InfoContent
                            content={event.content}
                            style="no-caps"
                          />
                        </p>
                      </div>
                      <div className="text-right text-sm whitespace-nowrap text-gray-500">
                        <p>
                          {new Date(event.date).toLocaleString()}
                        </p>
                        <p className="text-sm text-ctext-primary italic">
                          {event.by}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default NotesFeed;
