import { useEffect, useState } from 'react';
import UserItem from '../components/UserItem';
import { useFetch } from '../customHooks/useFetch';
import ShopifyLoader from '../components/helpers/ShopifyLoader';

const MembersPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [members, setMembers] = useState([]);
  const path = 'allusers';
  const info = {
    path,
    errorMessage: 'Issue fetching members data, try again!',
  };

  const response = useFetch(info);

  useEffect(() => {
    setMembers(response.data);
    setIsLoading(response.loading);
  }, [response.loading]); // re-run useEffect when loading changes, sample if response.loading is true, means data isnt loaded so we wait till its done to change loading status and re run useEffect

  return isLoading ? (
    <ShopifyLoader />
  ) : (
    <div className="mx-2 py-4 px-4 text-center sm:px-6  lg:py-10">
      <div className="space-y-8 sm:space-y-12">
        <div className="shadow sm:rounded-lg">
          <div className="flex m-2 p-4">
            <div className={'text-left'}>
              <p className="text-white text-xl">{'Members'}</p>
              <p className="text-ctext-primary text-sm font-thin">
                {'Edit and invite new members.'}
              </p>
            </div>
          </div>
        </div>
        <div role="list" className={'flex flex-wrap justify-center'}>
          {members.map((member) => (
            <UserItem member={member} key={Math.random() * 1000} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MembersPage;
