import { useState } from "react";
import { FaXbox, FaPlaystation } from "react-icons/fa";
import { HiOutlineDesktopComputer } from "react-icons/hi";
import { CheckIcon } from "@heroicons/react/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const SystemSelect = ({ platform, getAdminValues }) => {
  const [active, setActive] = useState(platform);

  function platformsHandler(platform) {
    let x = [...active, platform];
    const included = active.includes(platform);
    if (included) {
      x = x.filter((word) => word !== platform);
    }
    setActive(x);
    getAdminValues("platforms", x);
  }

  return (
    <>
      <span className="relative z-0 inline-flex shadow-sm rounded-md w-full">
        <button
          onClick={() => platformsHandler("xbox")}
          type="button"
          className={
            "bg-cbackground-dark relative inline-flex items-center px-4 py-2 rounded-l-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 w-1/3 xl:w-auto justify-center"
          }
        >
          <FaXbox size={"20px"} color={"#01621f"} />
          <span className="ml-2">
            <CheckIcon
              className={classNames(
                active.includes("xbox")
                  ? "text-cprimary-primary"
                  : "text-cbackground-dark",
                "h-5 w-5"
              )}
            />
          </span>
        </button>
        <button
          onClick={() => platformsHandler("playstation")}
          type="button"
          className={
            "bg-cbackground-dark relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 w-1/3 xl:w-auto justify-center"
          }
        >
          <FaPlaystation size={"20px"} color={"#003791"} />
          <span className="ml-2">
            <CheckIcon
              className={classNames(
                active.includes("playstation")
                  ? "text-cprimary-primary"
                  : "text-cbackground-dark",
                "h-5 w-5"
              )}
            />
          </span>
        </button>
        <button
          onClick={() => platformsHandler("pc")}
          type="button"
          className={
            "bg-cbackground-dark relative inline-flex items-center px-4 py-2 rounded-r-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 w-1/3 xl:w-auto justify-center"
          }
        >
          <HiOutlineDesktopComputer size={"20px"} color={"#8c92ac"} />
          <span className="ml-2">
            <CheckIcon
              className={classNames(
                active.includes("pc")
                  ? "text-cprimary-primary"
                  : "text-cbackground-dark",
                "h-5 w-5"
              )}
            />
          </span>
        </button>
      </span>
    </>
  );
};

export default SystemSelect;
