import { useSelector } from "react-redux";

const StatRow = () => {
  const userUnpaid = useSelector((state) => state.ui.userUnpaid);
  const userRevenue = useSelector((state) => state.ui.userRevenue);
  const userCompletedOrders = useSelector(
    (state) => state.ui.userCompletedOrders
  );

  const stats = [
    {
      name: "Total Revenue",
      stat: `$${userRevenue || "-"}`,
      gradient: "bg-gradient-to-r from-indigo-500 to-purple-700",
    },
    {
      name: "Unpaid Earnings",
      stat: `$${userUnpaid || "-"}`,
      gradient: "bg-gradient-to-r from-yellow-500 to-red-500",
    },
    {
      name: "Total Orders",
      stat: `${userCompletedOrders || "-"}`,
      gradient: "bg-gradient-to-r from-green-300 to-green-600",
    },
  ];

  return (
    <div className="mx-3">
      <dl className="mt-5 grid grid-cols-1 gap-5  lg:grid-cols-3">
        {stats.map((item) => (
          <div
            key={item.name}
            className={`h-40 px-4 py-8 shadow rounded-lg overflow-hidden sm:p-6 ${item.gradient} `}
          >
            <dt className="text-sm font-extralight text-md text-ctext-active truncate">
              {item.name}
            </dt>
            <dd className="mt-1 text-3xl font-light text-ctext-active ">
              {item.stat}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
};

export default StatRow;
