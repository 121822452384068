import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getOrderDetail } from "../store/ui-actions";
import { uiActions } from "../store/ui-slice";
import { createOrderCompleteRequest } from "../store/notifications-actions";
import OrderDetails from "../components/OrderDetailPage/OrderDetails";
import ActionButton from "../components/OrderDetailPage/ActionButton";
import DetailHeader from "../components/OrderDetailPage/DetailHeader";
import UserCompletePrompt from "../components/OrderDetailPage/UserCompletePrompt";
import ApprovalPrompt from "../components/OrderDetailPage/ApprovalPrompt";
import OrderConversation from "../components/OrderDetailPage/OrderConversation";
import VPNSection from "../components/OrderDetailPage/VPNSection";
import LoginSection from "../components/OrderDetailPage/LoginSection";
import NotesSection from "../components/OrderDetailPage/NotesSection";
import ShopifyLoader from '../components/helpers/ShopifyLoader';

const OrderDetailPage = () => {
  const dispatch = useDispatch();
  const { order_id } = useParams();
  const userAuth = useSelector((state) => state.auth.token);
  const order = useSelector((state) => state.ui.activeOrderDetail);
  const [showComplete, setShowComplete] = useState();
  const Uid = useSelector((state) => state.auth.uid);
  const displayName = useSelector((state) => state.auth.displayName);
  const [showApprovalPrompt, setShowApprovalPrompt] = useState(false);
  const completedRequests = useSelector((state) => state.ui.completedRequests);
  const role = useSelector((state) => state.auth.role);
  let history = useHistory();

  useEffect(() => {
    dispatch(uiActions.setActiveOrderDetail({ orderDetail: "" }));
  }, []);

  useEffect(async () => {
    dispatch(getOrderDetail(order_id, userAuth));
  }, [order_id]);

  const completeClick = () => {
    const arrToEdit = [...completedRequests];
    console.log("test");
    if (!showComplete) {
      console.log("if");
      setShowComplete(true);
    } else {
      dispatch(
        createOrderCompleteRequest(
          order.order_id,
          displayName,
          Uid,
          arrToEdit,
          userAuth
        )
      );
      setTimeout(() => {
        dispatch(
          uiActions.showOrderDetailToaster({
            setShow: false,
          })
        );
      }, 1000);

      setTimeout(() => {
        dispatch(uiActions.closeFlashAlert());
        history.push("/dashboard");
      }, 2000);
    }
  };

  return (
    <>
      {order ? (
        <div className="min-h-full">
          <main className="py-10">
            <div className="shadow mx-2 sm:rounded-lg bg-cbackground-primary relative md:mx-6">
              <DetailHeader approvalPrompt={showApprovalPrompt} />
              {showComplete ? (
                ""
              ) : (
                <ActionButton
                  status={order.order_status}
                  showApprovalPrompt={showApprovalPrompt}
                  showApproval={() => setShowApprovalPrompt(true)}
                  completeClick={() => completeClick()}
                />
              )}
              {showComplete && ["claimed"].includes(order.order_status) ? (
                <UserCompletePrompt
                  completeClick={() => completeClick()}
                  setShowComplete={() => setShowComplete()}
                />
              ) : (
                ""
              )}
              {showApprovalPrompt && role === 'super' ? (
                <ApprovalPrompt
                  closePrompt={() => {
                    setShowApprovalPrompt(false);
                  }}
                />
              ) : (
                ""
              )}
            </div>

            <div className="mt-8 max-w-3xl grid grid-cols-1 gap-6 mx-2 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3 md:mx-6">
              <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                <OrderDetails />
                {order.order_info.note ? <NotesSection order={order} /> : ''}
              </div>
              <div>
                <div className="flex flex-col space-y-6">
                  {order ? <VPNSection order={order} /> : ''}
                  {order.conversation && ['super', 'admin'].includes(role) ? (
                    <OrderConversation />
                  ) : (
                    ''
                  )}
                  {role === 'super' || role === 'admin' && order.order_status !== 'unclaimed' || Uid === order.assigned_contractor ? (
                    <LoginSection order={order} />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </main>
        </div>
      ) : (
        <ShopifyLoader />
      )}
    </>
  );
};

export default OrderDetailPage;
