import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { XCircleIcon } from '@heroicons/react/solid';
import {
  changeNotificationStatus,
  approveOrderOverRequest,
  denyOrderOverRequest,
  approveUnassignRequest,
  denyUnassignRequest,
  approvePaymentRequest,
  deleteNotification
} from '../../store/notifications-actions';
import { uiActions } from '../../store/ui-slice';
import InfoContent from '../helpers/InfoContent';
import InfoTitle from '../InfoTitle';

const NotificationCard = ({ notification }) => {
  const userAuth = useSelector((state) => state.auth.token);
  const userDoc = notification.payload.user_doc;
  const orderId = notification.payload.order_id;
  const contractorId = notification.payload.contractor_id;
  const notificationId = notification.payload.doc_id;
  const dispatch = useDispatch();
  const profileImgs = useSelector((state) => state.ui.profileImgs);

  if (notification.type === 'order_request') {
    return (
      <div
        key={Math.random() * 1000}
        className={
          'my-2 py-2 bg-cbackground-dark flex justify-between items-center rounded-md shadow-lg'
        }
      >
        <td className="px-6 py-4 whitespace-nowrap self-center">
          <div className="flex items-center">
            <div className="flex-shrink-0 h-10 w-10">
              <img
                className="h-10 w-10 rounded-full"
                src={`${
                  profileImgs.userId[notification.payload.contractor_id]
                }`}
                alt=""
              />
            </div>
            <div className="ml-4">
              <InfoTitle content={'ORDER OVER LIMIT REQUEST'} />
              <div className="text-sm text-ctext-primary">
                {new Date(notification.created_at).toLocaleString()}
              </div>
              <InfoContent
                content={notification.payload.message}
                style="italic"
              />
            </div>
          </div>
        </td>
        <td className="ml-auto">
          <button
            onClick={() => {
              dispatch(
                approveOrderOverRequest(
                  userDoc,
                  orderId,
                  contractorId,
                  notificationId,
                  userAuth
                )
              );
            }}
            type="button"
            className="inline-flex items-center px-4 py-2 mr-4 border border-gray-300 shadow-sm text-sm font-medium text-white bg-transparent hover:bg-gray-50 hover:text-cprimary-primary"
          >
            APPROVE
          </button>
          <button
            onClick={() => {
              dispatch(
                denyOrderOverRequest(
                  userDoc,
                  orderId,
                  contractorId,
                  notificationId,
                  userAuth
                )
              );
            }}
            type="button"
            className="inline-flex items-center px-4 py-2 mr-4 border border-gray-300 shadow-sm text-sm font-medium text-white bg-transparent hover:bg-gray-50 hover:text-cprimary-primary"
          >
            DENY
          </button>
        </td>
        <td className="py-4 whitespace-nowrap text-center text-sm text-white self-center flex align-center justify-end">
          <button
            type="button"
            className="mx-4 text-ctext-primary hover:text-ctext-active self-center"
            onClick={() => {
              dispatch(deleteNotification(notificationId, userAuth))
            }}
          >
            <XCircleIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </td>
      </div>
    );
  } else if (notification.type === 'payment_request') {
    return (
      <div
        key={Math.random() * 1000}
        className={
          'my-2 py-2 bg-cbackground-dark flex justify-between items-center rounded-md shadow-lg'
        }
      >
        <td className="px-6 py-4 whitespace-nowrap self-center">
          <div className="flex items-center">
            <div className="flex-shrink-0 h-10 w-10">
              <img
                className="h-10 w-10 rounded-full"
                src={`${
                  profileImgs.userId[notification.payload.contractor_id]
                }`}
                alt=""
              />
            </div>
            <div className="ml-4">
              <InfoTitle content={'PAYMENT REQUEST'} />
              <div className="text-sm text-ctext-primary">
              {new Date(notification.created_at).toLocaleString()}
              </div>
              <InfoContent
                content={notification.payload.message}
                style="italic"
              />
            </div>
          </div>
        </td>
        <td className="ml-auto">
          <button
            onClick={() => {
              dispatch(
                approvePaymentRequest(
                  notification.payload.contractor_id,
                  notification.payload.doc_id,
                  userAuth
                )
              );
            }}
            type="button"
            className="inline-flex items-center px-4 py-2 mr-4 border border-gray-300 shadow-sm text-sm font-medium text-white bg-transparent hover:bg-gray-50 hover:text-cprimary-primary"
          >
            APPROVE
          </button>
          <button
            onClick={() => {
              dispatch(
                uiActions.showPayoutToaster({
                  setShow: true,
                })
              );
              dispatch(
                uiActions.setActiveNotification({
                  notificationId: notification,
                })
              );
            }}
            type="button"
            className="inline-flex items-center px-4 py-2 mr-4 border border-gray-300 shadow-sm text-sm font-medium text-white bg-transparent hover:bg-gray-50 hover:text-cprimary-primary"
          >
            SUMMARY
          </button>
        </td>
        <td className="py-4 whitespace-nowrap text-center text-sm text-white self-center flex align-center justify-end">
          <button
            type="button"
            className="mx-4 text-ctext-primary hover:text-ctext-active self-center"
            onClick={() => {
              dispatch(deleteNotification(notificationId, userAuth))
            }}
          >
            <XCircleIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </td>
      </div>
    );
  } else if (notification.type === 'unassign_request') {
    return (
      <div
        key={Math.random() * 1000}
        className={
          'my-2 py-2 bg-cbackground-dark flex justify-between items-center rounded-md shadow-lg'
        }
      >
        <td className="px-6 py-4 whitespace-nowrap self-center">
          <div className="flex items-center">
            <div className="flex-shrink-0 h-10 w-10">
              <img
                className="h-10 w-10 rounded-full"
                src={`${
                  profileImgs.userId[notification.payload.contractor_id]
                }`}
                alt=""
              />
            </div>
            <div className="ml-4">
              <InfoTitle content={'UNASSIGN ORDER REQUEST'} />
              <div className="text-sm text-ctext-primary">
              {new Date(notification.created_at).toLocaleString()}
              </div>
              <InfoContent
                content={notification.payload.message}
                style="italic"
              />
            </div>
          </div>
        </td>
        <td className="ml-auto">
          <button
            onClick={async () => {
              try {
                dispatch(
                  approveUnassignRequest(
                    orderId,
                    contractorId,
                    notificationId,
                    userDoc,
                    userAuth
                  )
                );
              } catch (error) {
                console.log(error);
                return;
              }
            }}
            type="button"
            className="inline-flex items-center px-4 py-2 mr-4 border border-gray-300 shadow-sm text-sm font-medium text-white bg-transparent hover:bg-gray-50 hover:text-cprimary-primary"
          >
            APPROVE
          </button>
          <button
            onClick={async () => {
              try {
                dispatch(
                  denyUnassignRequest(
                    orderId,
                    contractorId,
                    notificationId,
                    userDoc,
                    userAuth
                  )
                );
              } catch (error) {
                console.log(error);
                return;
              }
            }}
            type="button"
            className="inline-flex items-center px-4 py-2 mr-4 border border-gray-300 shadow-sm text-sm font-medium text-white bg-transparent hover:bg-gray-50 hover:text-cprimary-primary"
          >
            DENY
          </button>
        </td>
        <td className="py-4 whitespace-nowrap text-center text-sm text-white self-center flex align-center justify-end">
          <button
            type="button"
            className="mx-4 text-ctext-primary hover:text-ctext-active self-center"
            onClick={() => {
              dispatch(deleteNotification(notificationId, userAuth))
            }}
          >
            <XCircleIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </td>
      </div>
    );
  } else {
    return (
      <div
        key={Math.random() * 1000}
        className={
          'my-2 py-2 bg-cbackground-dark flex justify-between items-center rounded-md shadow-lg'
        }
      >
        <td className="px-6 py-4 whitespace-nowrap self-center flex-grow">
          <div className="flex items-center">
            <div className="flex-shrink-0 h-10 w-10">
              <img
                className="h-10 w-10 rounded-full"
                src={`${
                  profileImgs.userId[notification.payload.contractor_id]
                }`}
                alt=""
              />
            </div>
            <div className="ml-4">
              <div className="flex pb-2">
                <div className="text-sm mr-4 font-medium text-white font-semibold self-center">
                  {`${notification.payload.order_id}`}
                </div>
              </div>
              <div className="text-sm text-ctext-primary">
                {notification.type === 'order_request'
                  ? 'ORDER REQUEST'
                  : 'SOMETHING ELSE'}
              </div>
            </div>
          </div>
        </td>
        <td className="justify-end">
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 mr-4 border border-gray-300 shadow-sm text-sm font-medium text-white bg-transparent hover:bg-gray-50 hover:text-cprimary-primary"
          >
            APPROVE
          </button>
          <button
            onClick={() => {
              dispatch(
                changeNotificationStatus(
                  notification.doc_id,
                  'order_request',
                  {
                    action: 'deny',
                    contractor_id: notification.payload.user_id,
                    order_id: notification.payload.order_id,
                  },
                  userAuth
                )
              );
            }}
            type="button"
            className="inline-flex items-center px-4 py-2 mr-4 border border-gray-300 shadow-sm text-sm font-medium text-white bg-transparent hover:bg-gray-50 hover:text-cprimary-primary"
          >
            DENY
          </button>
        </td>
        <td className="py-4 whitespace-nowrap text-center text-sm text-white self-center flex align-center justify-end">
          <button
            type="button"
            className="mx-4 text-ctext-primary hover:text-ctext-active self-center"
            onClick={() => {
              dispatch(deleteNotification(notificationId, userAuth))
            }}
          >
            <XCircleIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </td>
      </div>
    );
  }
};

export default NotificationCard;
