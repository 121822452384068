import InfoTitle from '../components/InfoTitle';
import InfoContent from '../components/helpers/InfoContent';
import { useState, Fragment } from 'react';
import { RefreshIcon } from '@heroicons/react/outline';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { useDispatch, useSelector } from 'react-redux';
import { uiActions } from '../store/ui-slice'
import axios from '../helpers/authCatch';
import axiosConfig from '../helpers/axiosConfig';

const menuItems = [
  { name: 'EMAIL', id: 'emailProperty' },
  { name: 'PLATFORM', id: 'platformProperty'},
  { name: 'LOGIN PASSWORD', id: 'loginPasswordProperty' },
  { name: 'GAMER TAG', id: 'gamertagProperty' },
  { name: 'STEAM ID', id: 'steamIDProperty' },
  { name: 'PC CHOICE', id: 'PCChoiceProperty' },
  { name: 'BATTLE NET', id: 'battleNetProperty' },
  { name: 'OTHER', id: 'otherProperty' },
];

const orderInfoItems = [
  { name: 'Game', id: 'game', placeholder: 'Destiny 2', type: 'text' },
  {
    name: 'Title',
    id: 'title',
    placeholder: 'Vow Of The Disciple',
    type: 'text',
  },
  {
    name: 'Variant Title',
    id: 'variant',
    placeholder: 'Destiny 2',
    type: 'text',
  },
  { name: 'Price', id: 'price', placeholder: '0', type: 'number' },
  { name: 'Tip', id: 'tip', placeholder: '0', type: 'number' },
  { name: 'Note', id: 'note', placeholder: 'Sample Note', type: 'text' },
  { name: 'Quantity', id: 'quantity', placeholder: '1', type: 'number' }
];

const customerInfoItems = [
  { name: 'First Name', id: 'first', placeholder: 'First Name', type: 'text' },
  { name: 'Last Name', id: 'last', placeholder: 'Last Name', type: 'text' },
  { name: 'Phone', id: 'phone', placeholder: '+15554443333', type: 'text' },
  { name: 'Email', id: 'email', placeholder: 'test@playerboost.com', type: 'email' },
  { name: 'Province / State', id: 'province', placeholder: 'State', type: 'text' },
  { name: 'Country', id: 'country', placeholder: 'Country', type: 'text' },
  { name: 'Zip', id: 'zip', placeholder: 'Zip', type: 'text' },
  { name: 'City', id: 'city', placeholder: 'City', type: 'text' },
  { name: 'Address 1', id: 'address1', placeholder: 'Address 1', type: 'text' },
  { name: 'Address 2', id: 'address2', placeholder: 'Address 2', type: 'text' }
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const CreateOrderPage = () => {
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.auth.token);
  const [values, setValues] = useState({
    game: '',
    title: '',
    variant: '',
    price: '',
    tip: '',
    note: '',
    first: '',
    last: '',
    phone: '',
    province: '',
    country: '',
    zip: '',
    city: '',
    address1: '',
    address2: '',
    quantity: 1,
    email: '',
    platform: '',
    gamerTag: '',
    loginEmail: '',
    playOption: '',
  });
  const [orderSending, setOrderSending] = useState(false);
  const [propNodes, setPropNodes] = useState([{
    name: 'PLATFORM',
    id: 'platformProperty',
    value: '',
  }]);

  const handleChange = (e) => {
    const value = e.target.value;
    setValues({
      ...values,
      [e.target.id]: value,
    });
  };

  const addProp = (type) => {
    setPropNodes([...propNodes, type]);
  };

  const updateProp = (e) => {
    const revised = propNodes.map((prop) => {
      if (prop.id === e.target.id) {
        prop.value = e.target.value;
      }
      return prop;
    });
    setPropNodes(revised);
  };

  const sendOrder = async () => {
    const getProps = () => {
      const propArray = [];

      propNodes.forEach((prop) => {
        propArray.push({ name: prop.name, value: prop.value });
      });
      return propArray;
    };
    const orderObject = {};
    setOrderSending(true);
    orderObject.line_items = [
      {
        id: new Date().getTime(),
        vendor: values.game,
        price: values.price,
        quantity: values.quantity,
        title: values.title,
        variant_title: values.variant,
        properties: getProps()
      },
    ];
    orderObject.id = 'C-' + new Date().getTime();
    orderObject.order_number = 'C-' + parseInt(Math.random() * 10000)
    orderObject.total_tip_received = values.tip;
    orderObject.note = values.note;
    orderObject.customer = {
      email: values.email,
      first_name: values.first,
      last_name: values.last,
    };
    orderObject.customer.default_address = {
      province: values.province,
      zip: values.zip,
      city: values.city,
      address1: values.address1,
      address2: values.address2,
      country_name: values.country,
    };
    orderObject.billing_address = {
      phone: values.phone
    }
    console.log(JSON.stringify(orderObject));

    const response = await axios.post(
      axiosConfig("create-custom-order"),
      orderObject,
      {
        headers: { Authorization: userAuth },
      }
    );
    console.log(response)
    if (response.status === 200) {
      dispatch(
        uiActions.showFlashAlert({
          message: "Custom order submitted.",
        })
      );

      setTimeout(() => {
        dispatch(uiActions.closeFlashAlert());
      }, 2000);
    }
  };

  return (
    <div className="mt-8 max-w-3xl mx-2 lg:max-w-7xl md:mx-6">
      <div className="min-h-full shadow flex-grow sm:rounded-lg bg-cbackground-primary">
        <div className="flex mx-4 items-center justify-between">
          <div className="flex items-center px-6 py-4 text-white font-light text-lg">
            <h2 className="">Create New Order</h2>
          </div>
          <div
            onClick={() => sendOrder()}
            className={'ml-8 rounded-full p-2 bg-cbackground-primary shadow-lg'}
          >
            <RefreshIcon
              className={`flex-shrink-0 h-6 w-6 text-ctext-primary ${
                orderSending && 'text-cprimary-primary animate-spin'
              }`}
            />
          </div>
        </div>
        <main className="border-t border-cborder px-4 py-5 sm:px-6">
          <div className={'mx-2 md:mx-6 mb-2'}>
            <InfoTitle content={'Order Info'} />
          </div>
          <div className="shadow mx-2 sm:rounded-lg relative md:mx-6 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            {orderInfoItems.map((item) => {
              return (
                <div key={item.id}>
                  <InfoContent content={item.name} />
                  <div className="mt-1">
                    <input
                      type={item.type}
                      value={values[item.id]}
                      id={item.id}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder={item.placeholder}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <div className={'mx-2 md:mx-6 mb-2 mt-6'}>
            <InfoTitle content={'Customer Info'} />
          </div>
          <div className="shadow mx-2 sm:rounded-lg relative md:mx-6 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            {customerInfoItems.map(item => {
              return (
                <div key={item.id}>
                  <InfoContent content={item.name} />
                  <div className="mt-1">
                    <input
                      type={item.type}
                      value={values[item.id]}
                      id={item.id}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder={item.placeholder}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              )
            })}
          </div>
          <div className={'flex mb-2 mt-6 items-center'}>
            <div className={'mx-2 md:mx-6'}>
              <InfoTitle content={'Order Properties'} />
            </div>
            <div>
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                    Options
                    <ChevronDownIcon
                      className="-mr-1 ml-2 h-5 w-5"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      {menuItems.map((i) => {
                        return (
                          <Menu.Item key={i.id}>
                            {({ active }) => (
                              <div
                                onClick={() => {
                                  addProp({
                                    name: i.name,
                                    id: i.id,
                                    value: '',
                                  });
                                }}
                                className={classNames(
                                  active
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-700',
                                  'block w-full text-left px-4 py-2 text-sm'
                                )}
                              >
                                {i.name}
                              </div>
                            )}
                          </Menu.Item>
                        );
                      })}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
          <div className="shadow mx-2 sm:rounded-lg md:mx-6 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            {propNodes.map((i) => {
              return (
                <div key={i.id}>
                  <InfoContent content={i.name} />
                  <div className="mt-1">
                    <input
                      type="text"
                      value={i.value}
                      id={i.id}
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder={i.name}
                      onChange={updateProp}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </main>
      </div>
    </div>
  );
};

export default CreateOrderPage;
