import axios from 'axios';
import { createBrowserHistory } from 'history';

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      createBrowserHistory().push('/');
      window.location.reload();
      return Promise.reject(error);
    }
 }
);

export default axios;