// Conversation.js uses the twilio api to load and add message to the active conversation

import { useEffect, useState } from "react";
import ConversationMessages from "./ConversationMessages";
import { PaperAirplaneIcon } from "@heroicons/react/outline";

const Conversation = ({ conversationProxy }) => {
  const [newMessage, setNewMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [loadingState, setLoadingState] = useState("initalizing");

  // Scrolls the conversation windown down on message add
  const scrollToBottom = () => {
    const msgArea = document.getElementById(
      `messageArea-${conversationProxy.sid}`
    );
    if (msgArea !== null) {
      let scrollHeight = msgArea.scrollHeight;
      msgArea.scrollTop = scrollHeight;
    }
  };

  const loadMessagesFor = (thisConversation) => {
    if (conversationProxy === thisConversation) {
      thisConversation
        .getMessages()
        .then((messagePaginator) => {
          if (conversationProxy === thisConversation) {
            setMessages(messagePaginator.items), setLoadingState("ready");
            thisConversation.updateLastReadMessageIndex(72);
          }
          scrollToBottom();
        })
        .catch((err) => {
          console.error("Couldnt fetch messages: " + err);
          setLoadingState("failed");
        });
    }
  };

  // loads messages on render
  useEffect(() => {
    loadMessagesFor(conversationProxy);
  }, []);

  // updates component when twilio client adds a new message
  useEffect(() => {
    conversationProxy.on("messageAdded", () => {
      messageAdded(conversationProxy);
    });
  }, [conversationProxy]);

  //
  const messageAdded = (targetConversation) => {
    if (targetConversation === conversationProxy) {
      loadMessagesFor(conversationProxy);
      scrollToBottom();
    }
  };

  const onMessageChanged = (e) => {
    setNewMessage(e.target.value);
  };

  // adds message to the conversation
  const sendMessage = () => {
    setNewMessage("");
    conversationProxy.sendMessage(newMessage).then(() => {
      loadMessagesFor(conversationProxy);
    });
  };

  return (
    <>
      <div
        id={`messageArea-${conversationProxy.sid}`}
        className="md:message-area h-full overflow-y-scroll"
      >
        <ConversationMessages messages={messages} />
      </div>
      <form
        className="m-0"
        onSubmit={(e) => {
          e.preventDefault();
          sendMessage();
        }}
      >
        <div className="relative border-gray-300 w-full">
          <textarea
            rows={4}
            placeholder="Enter your message..."
            type="text"
            name="message"
            disabled={loadingState !== "ready"}
            onChange={(e) => onMessageChanged(e)}
            value={newMessage}
            onSubmit={(e) => {
              e.preventDefault();
              sendMessage();
            }}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md pr-12"
          />

          {newMessage.length > 0 ? (
            <button
              type="submit"
              className="absolute inset-y-0 right-0 pr-3 flex items-center rotate-45 mt-2 z-40"
            >
              <PaperAirplaneIcon
                className="h-6 w-6 text-gray-400"
                aria-hidden="true"
              />
            </button>
          ) : (
            ""
          )}
        </div>
      </form>
    </>
  );
};

export default Conversation;
