import { useState } from "react";
import { FaPercent } from "react-icons/fa";

const RevShareInput = ({ revShare, getAdminValues }) => {
  const [share, setShare] = useState(parseInt(revShare * 100));

  const handleChange = (e) => {
    if (e.target.value > 100) {
      setShare(100);
    } else if (e.target.value <= 0) {
      setShare(0);
    } else {
      setShare(e.target.value);
    }
    getAdminValues("revShare", +e.target.value);
  };

  return (
    <div>
      <div className="relative rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <FaPercent className="h-3 w-3 text-gray-400" aria-hidden="true" />
        </div>
        <input
          name="revShare"
          id="adminRevShare"
          type="number"
          min="1"
          max="100"
          value={share}
          onChange={handleChange}
          className="block w-full pl-10 sm:text-sm rounded-md text-cprimary-primary bg-cbackground-dark border-none"
          placeholder="55%"
        />
      </div>
    </div>
  );
};

export default RevShareInput;
