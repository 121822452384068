import React, { useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import playerboost_logo from "../assets/images/playerboost_logo.png";
import { LogoutIcon, ChevronRightIcon } from "@heroicons/react/solid";
import NavigationItem from "./NavigationItem";
import { useSelector, useDispatch } from "react-redux";
import { createPaymentRequest } from "../store/notifications-actions";

const SideNav = ({ navigation }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const inital = history.location.pathname;
  const [currentNav, setCurrentNav] = useState(inital);
  const Uid = useSelector((state) => state.auth.uid);
  const userAuth = useSelector((state) => state.auth.token);
  const displayName = useSelector((state) => state.auth.displayName);
  const userCompletedOrders = useSelector(
    (state) => state.ui.userCompletedOrders
  );
  const userUnpaid = useSelector((state) => state.ui.userUnpaid);
  const userRating = useSelector((state) => state.ui.userRating);
  const activeOrders = useSelector(
    (state) =>
      state.orders.allOrders.filter((order) => {
        return (
          order.order_status === "claimed" && order.assigned_contractor === Uid
        );
      }).length
  );
  const paymentRequested = useSelector(
    (state) => state.ui.userPaymentRequested
  );

  const requestPayment = () => {
    dispatch(createPaymentRequest(Uid, displayName, userAuth));
  };

  function truePath(item) {
    if (
      currentNav === item.href ||
      currentNav === item.name ||
      currentNav === item.params?.pathname
    ) {
      return true;
    }
    return false;
  }

  return (
    <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
      {/* Sidebar component, swap this element with another sidebar if you like */}
      <div className="flex flex-col flex-grow pt-5 bg-cbackground-primary overflow-y-auto rounded-r-lg">
        <div className="flex items-center flex-shrink-0 px-4 justify-center mt-4 mb-8 border-b-1 border-cborder">
          <img
            className="h-8 w-auto"
            src={playerboost_logo}
            alt="playerboost_logo"
          />
        </div>
        <div className="px-6 mx-4 mb-4 bg-cbackground-light rounded-lg border border-cborder relative">
          <div className="h-20 flex flex-col align-center justify-center">
            <p className="text-ctext-primary text-xs text-center">
              Unpaid Earnings
            </p>
            <p className={"text-center text-white text-3xl"}>
              ${userUnpaid || "-"}
            </p>
            {paymentRequested ? (
              <button
                className="absolute absolute left-1/2 top-full transform -translate-x-1/2 -translate-y-1/2 bg-csecondary-dark rounded text-xs p-1 w-4/6"
                onClick={() => {
                  "payment request not available";
                }}
              >
                Payment Requested
              </button>
            ) : (
              <button
                className="absolute absolute left-1/2 top-full transform -translate-x-1/2 -translate-y-1/2 bg-csecondary-light rounded text-xs p-1 w-4/6"
                onClick={requestPayment}
              >
                Request Payment
              </button>
            )}
          </div>
        </div>
        <div className="flex flex-row mt-6 mb-4 justify-around">
          <div>
            <p className="text-ctext-accent text-center font-bold">
              {userCompletedOrders || "-"}
            </p>
            <p className="text-ctext-primary font-thin text-xs">Orders</p>
          </div>
          <div>
            <p className="text-ctext-accent text-center font-bold">
              {activeOrders || "-"}
            </p>
            <p className="text-ctext-primary font-thin text-xs">Active</p>
          </div>
          <div>
            <p className="text-ctext-accent text-center font-bold">
              {userRating || "-"}
            </p>
            <p className="text-ctext-primary font-thin text-xs">Rating</p>
          </div>
        </div>
        <div className="mt-5 flex-1 flex flex-col">
          <nav className="flex-1 pb-4 space-y-1">
            {navigation.map((item) => {
              return (
                <NavLink
                  to={item.params ? item.params : item.href}
                  key={item.href}
                  onClick={() => setCurrentNav(item.name)}
                >
                  <NavigationItem
                    item={item}
                    current={truePath(item)}
                    setCurrent={setCurrentNav}
                  >
                    <item.icon
                      className="mr-4 flex-shrink-0 h-6 w-6 text-ctext-primary"
                      aria-hidden="true"
                    />
                  </NavigationItem>
                </NavLink>
              );
            })}
            <a
              key={"Logout"}
              onClick={() => window.persistor.purge()}
              href={"/#"}
              className={
                "text-indigo-100 hover:bg-cbackground-light group flex items-center px-4 py-4 text-sm font-medium"
              }
            >
              <LogoutIcon
                className={"mr-4 flex-shrink-0 h-6 w-6 text-ctext-primary"}
              />
              {"Logout"}
              <ChevronRightIcon
                className="mr-4 flex-shrink-0 h-6 w-6 text-ctext-primary ml-auto"
                aria-hidden="true"
              />
            </a>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default SideNav;
