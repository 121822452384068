import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const UserItem = ({ member }) => {
  const profileImgs = useSelector((state) => state.ui.profileImgs);

  return (
    <Link to={`/user_detail/${member.userName}`} className={'w-full m-4 md:w-1/3 lg:w-1/4'}>
      <div
        className={
          'rounded-t-md from-pink-600 to-purple-600 bg-gradient-to-b pb-12'
        }
      >
        <div className="flex items-center px-4 py-4 sm:px-6 justify-center">
          <div className="min-w-0 flex-1 pt-4 flex items-center flex-col justify-center">
            <img
              className="h-16 w-16 rounded-full"
              src={`${
                profileImgs.userName[member.userName]
                  ? profileImgs.userName[member.userName]
                  : 'https://i.pinimg.com/originals/c6/92/85/c69285419db3f7f436aca78bd4bccbcf.png'
              }`}
              alt=""
            />
            <p className="text-sm mt-4 font-medium text-white truncate">
              {member.userName}
            </p>
            <div className={'text-white text-sm h-4'}>{member.access}</div>
          </div>
        </div>
      </div>
      <div className={'flex items-center text-white py-2 bg-cbackground-primary h-12 rounded-b-md'}>
        <div className={'w-1/3 border-r border-cborder'}>
          <div className={'text-sm font-thin'}>{'Jobs'}</div>
          <div className={'text-xs font-thin'}>{member.completedOrders}</div>
        </div>
        <div className={'w-1/3 border-r border-cborder'}>
          <div className={'text-sm font-thin'}>{'Revenue'}</div>
          <div className={'text-xs font-thin'}>{'$' + member.totalRevenue.toFixed(2)}</div>
        </div>
        <div className={'w-1/3'}>
          <div className={'text-sm font-thin'}>{'Rating'}</div>
          <div className={'text-xs font-thin'}>{member.rating}</div>
        </div>
      </div>
    </Link>
  );
};

export default UserItem;
