import { useSelector } from "react-redux";
import Button from "../Button";

const ActionButton = ({
  status,
  showApprovalPrompt,
  showApproval,
  completeClick,
}) => {
  const userRole = useSelector((state) => state.auth.role);
  const requestedOrders = useSelector((state) => state.ui.requestedOrders);
  const order = useSelector((state) => state.ui.activeOrderDetail);
  const completedRequests = useSelector((state) => state.ui.completedRequests);

  if (
    ["super"].includes(userRole) &&
    ["complete", "claimed"].includes(status) &&
    !showApprovalPrompt
  ) {
    return (
      <div className="max-w-3xl p-4 pt-0 flex sm:px-6 md:flex md:items-center md:space-x-5 lg:max-w-7xl lg:px-8">
        <div className="flex">
          <Button action={showApproval} style="primary" text="APPROVE" />
        </div>
      </div>
    );
  } else if (["user", "admin"].includes(userRole) && status === "claimed") {
    return (
      <div className="max-w-3xl p-4 pt-0 flex sm:px-6 md:flex md:items-center md:space-x-5 lg:max-w-7xl lg:px-8">
        <div className="flex">
          <Button
            action={() => completeClick()}
            style="primary"
            text="COMPLETE"
          />
        </div>
      </div>
    );
  } else if (
    status === "unclaimed" &&
    requestedOrders.includes(order.order_id)
  ) {
    return (
      <div className="max-w-3xl p-4 pt-0 flex sm:px-6 md:flex md:items-center md:space-x-5 lg:max-w-7xl lg:px-8">
        <div className="flex">
          <Button text={"REQUESTED"} disabled={true} />
        </div>
      </div>
    );
  } else if (completedRequests.includes(order.order_id)) {
    return (
      <div className="max-w-3xl p-4 pt-0 flex sm:px-6 md:flex md:items-center md:space-x-5 lg:max-w-7xl lg:px-8">
        <Button
          text="MARKED COMPLETE"
          style="primary"
          disabled="true"
          action={() => console.log("completed")}
        />
      </div>
    );
  } else return null;
};

export default ActionButton;
