import React from "react";
import { ChevronRightIcon } from "@heroicons/react/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const NavigationItem = (props) => {
  const { name } = props.item;

  return (
    <div
      key={name}
      className={classNames(
        props.current
          ? "bg-cprimary-primary text-white"
          : "text-indigo-100 hover:bg-cbackground-light",
        "group flex items-center px-4 py-4 text-sm font-bold"
      )}
    >
      {props.children}
      {name}
      <ChevronRightIcon
        className="mr-4 flex-shrink-0 h-6 w-6 text-ctext-primary ml-auto"
        aria-hidden="true"
      />
    </div>
  );
};

export default NavigationItem;
