import axios from '../../helpers/authCatch';
import axiosConfig from '../../helpers/axiosConfig';
//import Button from '../Button';
//import { useSelector } from 'react-redux';
import { LinkIcon, XCircleIcon } from '@heroicons/react/solid';
import { useState } from 'react';
import { useHistory } from 'react-router';
import Button from '../Button';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const ConversationEdit = ({
  //convName,
  orderName,
  //conversationId,
  orderPhone,
  conversationSid,
  proxyNumber,
  currentClientNumber,
  userAuth,
}) => {
  const history = useHistory();
  // const displayName = useSelector((state) => state.auth.displayName);
  // const mobileNumber = useSelector(
  //   (state) => state.ui.activeOrderDetail.conversation.phone
  // );
  const [updatedNumber, setUpdatedNumber] = useState('');
  const [showing, setShowing] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  // const deleteConvo = async () => {
  //   try {
  //     var data = JSON.stringify({
  //       conversation_id: conversationId,
  //       order_doc_id: orderName,
  //     });

  //     var config = {
  //       method: 'delete',
  //       url: axiosConfig('chat/deleteConversation'),
  //       headers: {
  //         Authorization: userAuth,
  //         'Content-Type': 'application/json',
  //       },
  //       data: data,
  //     };

  //     const response = await axios(config);

  //     return response;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const createConvo = async () => {
  //   try {
  //     const config = {
  //       headers: { Authorization: userAuth },
  //     };
  //     const response = await axios.post(
  //       axiosConfig('chat/initConversation'),
  //       {
  //         conv_name: convName,
  //         order_name: orderName,
  //         identity: displayName,
  //         mobile_number: mobileNumber,
  //       },
  //       config
  //     );

  //     return response;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const resetConvo = async () => {
  //   if (conversationId !== '') {
  //     await deleteConvo();
  //   }
  //   createConvo();
  // };

  const swapOrderPhone = async () => {
    setFetching(true);
    try {
      const config = {
        headers: { Authorization: userAuth },
      };
      await axios
        .put(
          axiosConfig('chat/swapOrderPhone'),
          {
            order_doc_id: orderName,
            new_phone: updatedNumber,
            conversation_id: conversationSid,
            proxy_number: proxyNumber,
            sms_user: currentClientNumber[0] ? currentClientNumber[0].sid : '',
          },
          config
        )
        .then(() => {
          setFetching(false);
          history.push('/orders');
        })
        .catch(() => {
          setErrMsg(
            'There was an error. Please refresh the page (and/or refresh all orders and try again.'
          );
          setTimeout(() => {
            setErrMsg('');
          }, 5000);
        });

      // response.then(() => history.push('/orders'));
      // return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  };

  return (
    <div className={'mb-2'}>
      <>
        {showing ? (
          <div>
            <div className="mt-1 mb-4 flex items-center">
              <XCircleIcon
                className="h-6 w-6 mr-2 text-ctext-primary"
                onClick={() => setShowing(false)}
              />
              <input
                type="text"
                onChange={(event) => setUpdatedNumber(event.target.value)}
                name="name"
                id="name"
                className="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                placeholder={
                  orderPhone
                    ? orderPhone.substring(0, 2) +
                      'X'.repeat(orderPhone.length - 4) +
                      orderPhone.slice(-2)
                    : 'NO PHONE PROVIDED'
                }
              />
              <LinkIcon
                className={classNames(
                  fetching ? 'animate-spin' : '',
                  'flex-shrink-0 h-6 ml-2 w-6 text-cprimary-primary hover:text-cprimary-light'
                )}
                aria-hidden="true"
                onClick={() => {
                  swapOrderPhone();
                }}
              />
            </div>
          </div>
        ) : (
          <Button
            text={'CHANGE CLIENT #'}
            style={'outline'}
            action={() => {
              setShowing(true);
            }}
          />
        )}
      </>
      {errMsg && <div>{errMsg}</div>}
    </div>
  );
};

export default ConversationEdit;
