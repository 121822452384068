import { createSlice } from '@reduxjs/toolkit';

const initialNotificationState = { allNotifications: [] };

const notificationSlice = createSlice({
  name: 'notifications',
  initialState: initialNotificationState,
  reducers: {
    setAllNotifications(state, action) {
      state.allNotifications = action.payload.allNotifications;
    },
    deleteNotification(state, action) {
      let newArray = [];

      state.allNotifications.forEach((item) => {
        if (item.payload.doc_id !== action.payload.id) {
          newArray.push(item);
        }
      });

      state.allNotifications = newArray;
    },
    addNotification(state, action) {
      const currentNotifications = state.allNotifications;

      currentNotifications.push({
        access: [...action.payload.access],
        type: action.payload.type,
        created_at: new Date().toISOString(),
        payload: { ...action.payload.payload },
      });

      state.allNotifications = currentNotifications;
    },
  },
});

export const notificationActions = notificationSlice.actions;

export default notificationSlice;
