import InfoTitle from '../../components/InfoTitle';
import InfoContent from '../../components/helpers/InfoContent';
import GamerTagCheck from './GamerTagCheck';

const LoginSection = ({order}) => {
return (
  <section
  aria-labelledby="timeline-title"
  className="shadow overflow-hidden sm:rounded-lg bg-cbackground-primary lg:col-start-3 lg:col-span-1"
>
  <div className="">
    <h2 className="px-6 py-4 text-white border-b border-cborder font-light text-lg">
      Login Info
    </h2>
  </div>
  <GamerTagCheck gamerTag={order.login_info['Gamer Tag']}/>
  <div className="grid gap-y-8 px-4 py-5 sm:px-6 ">
    {order.login_info['Gamer Tag'] ? (
      <div
        key={Math.random() * 100000}
        className="sm:col-span-1"
      >
        <InfoTitle content={'GAMERTAG'} />
        <InfoContent
          content={order.login_info['Gamer Tag']}
          style="no-caps"
        />
      </div>
    ) : (
      ''
    )}
    {order.login_info['Login Email'] ? (
      <div
        key={Math.random() * 100000}
        className="sm:col-span-1"
      >
        <InfoTitle content={'EMAIL'} />
        <InfoContent
          content={order.login_info['Login Email']}
          style="no-caps"
        />
      </div>
    ) : (
      ''
    )}
    {order.login_info['Password'] ? (
      <div
        key={Math.random() * 100000}
        className="sm:col-span-1"
      >
        <InfoTitle content={'PASSWORD'} />
        <InfoContent
          content={order.login_info['Password']}
          style="no-caps"
        />
      </div>
    ) : (
      ''
    )}
    {Object.entries(order.login_info).map(([k, v]) => {
      if (
        !['Login Email', 'Password', 'Gamer Tag'].includes(k)
      ) {
        return (
          <div
            key={Math.random() * 100000}
            className="sm:col-span-1"
          >
            <InfoTitle content={k} />
            <InfoContent content={v} style="no-caps" />
          </div>
        );
      }
    })}
  </div>
</section>
);
};

export default LoginSection;