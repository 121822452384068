import { useState } from 'react';
import { ClipboardCheckIcon, XCircleIcon } from '@heroicons/react/solid';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Dropdown from '../Dropdown';
import { useEffect } from 'react';
import Button from '../Button';
import { addOrderToContractor } from '../../store/orders-actions';
import {
  addSMSUserToTwilio,
  addChatUserToTwilio,
} from '../../store/conversations-actions';

const AssignOrderAction = () => {
  const dispatch = useDispatch();
  const [openDropdown, setOpenDropdown] = useState(false);
  const userAuth = useSelector((state) => state.auth.token);
  const [selected, setSelected] = useState();
  const { order_id } = useParams();
  const nameMap = useSelector((state) => state.ui.profileImgs.userIdNameMap);
  const basicOrder = useSelector(
    (state) =>
      state.orders.allOrders.filter(
        (order) => order.order_id === parseInt(order_id)
      )[0]
  );

  useEffect(() => {
    if (basicOrder.assigned_contractor) {
      setSelected({
        id: basicOrder.assigned_contractor,
        name: nameMap[basicOrder.assigned_contractor],
      });
    } else {
      setSelected('');
    }
  }, []);

  useEffect(() => {
    setOpenDropdown(false);
  }, [basicOrder]);

  const contractors = [];

  for (const contractor in nameMap) {
    contractors.push({
      id: contractor,
      name: nameMap[contractor],
    });
  }

  const addUsers = (basicOrder, selected) => {
    dispatch(
      addChatUserToTwilio(
        basicOrder.conversation.sid,
        selected.name,
        `${basicOrder.order_id}_${basicOrder.order_info.order_number}`,
        userAuth
      )
    );
    dispatch(
      addSMSUserToTwilio(
        basicOrder.conversation.sid,
        basicOrder.conversation.client_phone,
        basicOrder.conversation.assigned_number,
        userAuth
      )
    );
  };

  return (
    <div className="flex items-center pt-2">
      {openDropdown ? (
        <>
          <div
            className="flex-shrink-0 mr-1 h-5 w-5 text-cprimary-primary"
            aria-hidden="true"
            onClick={() => setOpenDropdown(false)}
          >
            <XCircleIcon />
          </div>
          <div className="w-1/6">
            <Dropdown
              selects={contractors}
              setInitial={selected}
              onSelect={(choice) => {
                setSelected({
                  id: choice,
                  name: nameMap[choice],
                });
              }}
            />
          </div>
          <div className="ml-2">
            <Button
              text="ASSIGN"
              style="primary"
              action={async () => {
                dispatch(
                  addOrderToContractor(
                    parseInt(order_id),
                    selected.id,
                    "claimed",
                    basicOrder.order_info.order_number,
                    userAuth
                  )
                );
                {
                  basicOrder.conversation.sid
                    ? addUsers(basicOrder, selected)
                    : '';
                }
              }}
            />
          </div>
        </>
      ) : (
        <div className="flex py-2">
          <div
            className="flex-shrink-0 mr-1 h-5 w-5 text-cprimary-primary"
            aria-hidden="true"
            onClick={() => setOpenDropdown(true)}
          >
            <ClipboardCheckIcon />
          </div>
          <p className="text-sm ml-2 text-ctext-primary font-semibold uppercase">
            {basicOrder.assigned_contractor
              ? nameMap[basicOrder.assigned_contractor]
              : 'UNASSIGNED'}
          </p>
        </div>
      )}
    </div>
  );
};

export default AssignOrderAction;
