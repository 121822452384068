import { Fragment } from 'react';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import { createUnassignRequest } from '../../store/notifications-actions';
import {
  archiveOrder,
  addOrderToContractor,
  deleteOrder,
} from '../../store/orders-actions';
import { Menu, Transition } from '@headlessui/react';
import { useSelector, useDispatch } from 'react-redux';
import { uiActions } from '../../store/ui-slice';
import { orderActions } from '../../store/orders-slice';
import { useParams, useHistory } from 'react-router-dom';

const ActionDropdown = () => {
  const { order_id } = useParams();
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.auth.token);
  const order = useSelector((state) => state.ui.activeOrderDetail);
  const Uid = useSelector((state) => state.auth.uid);
  const displayName = useSelector((state) => state.auth.displayName);
  const userRole = useSelector((state) => state.auth.role);
  const unassignedOrders = useSelector(
    (state) => state.ui.cancellationRequests
  );
  const basicOrder = useSelector(
    (state) =>
      state.orders.allOrders.filter(
        (order) => order.order_id === parseInt(order_id)
      )[0]
  );
  let history = useHistory();

  return (
    <div>
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button className="ml-auto text-ctext-primary hover:text-ctext-active items-top">
          <span className="sr-only">Open options menu</span>
          <DotsVerticalIcon className="h-6 w-6" aria-hidden="true" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {['admin', 'super'].includes(userRole) &&
              order.order_status !== 'archived' ? (
                <Menu.Item>
                  <a
                    href="#"
                    className={
                      'text-gray-700 block px-4 py-2 text-sm hover:bg-cbackground-lightgray'
                    }
                    onClick={() => {
                      try {
                        dispatch(
                          archiveOrder(
                            `${order.order_id}_${order.order_info.order_number}`,
                            order.order_id,
                            order.conversation.assigned_number,
                            order.conversation.conversation_id || undefined,
                            'archived',
                            userAuth
                          )
                        );
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                  >
                    Archive Order
                  </a>
                </Menu.Item>
              ) : (
                ''
              )}
              {['admin', 'super'].includes(userRole) &&
              order.order_status === 'archived' ? (
                <Menu.Item>
                  <a
                    href="#"
                    className={
                      'text-gray-700 block px-4 py-2 text-sm hover:bg-cbackground-lightgray'
                    }
                    onClick={() => {
                      try {
                        dispatch(
                          addOrderToContractor(
                            order.order_id,
                            null,
                            'unclaimed',
                            order.order_info.order_number,
                            userAuth
                          )
                        );
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                  >
                    Retrieve Order
                  </a>
                </Menu.Item>
              ) : (
                ''
              )}
              {unassignedOrders.includes(order.order_id) ? (
                <Menu.Item>
                  <a
                    href="#"
                    className={
                      'text-gray-700 block px-4 py-2 text-sm hover:bg-cbackground-lightgray'
                    }
                  >
                    Unassigned Requested
                  </a>
                </Menu.Item>
              ) : (
                <>
                  {order.assigned_contractor && userRole === 'user' ? (
                    <Menu.Item>
                      <a
                        href="#"
                        className={
                          'text-gray-700 block px-4 py-2 text-sm hover:bg-cbackground-lightgray'
                        }
                        onClick={() => {
                          const arrToAdd = [...unassignedOrders];
                          arrToAdd.push(order.order_id);
                          dispatch(
                            createUnassignRequest(
                              displayName,
                              order.order_id,
                              Uid,
                              userRole,
                              userAuth
                            )
                          );
                          if (userRole === 'user') {
                            dispatch(
                              uiActions.editCancellationRequests({
                                cancellationRequests: arrToAdd,
                              })
                            );
                          }

                          setTimeout(() => {
                            dispatch(uiActions.closeFlashAlert());
                          }, 2000);
                        }}
                      >
                        Unassign
                      </a>
                    </Menu.Item>
                  ) : (
                    ''
                  )}
                </>
              )}
              {['super', 'admin'].includes(userRole) && (
                <>
                  {['claimed', 'complete'].includes(basicOrder.order_status) ? (
                    <Menu.Item>
                      <a
                        href="#"
                        className={
                          'text-gray-700 block px-4 py-2 text-sm hover:bg-cbackground-lightgray'
                        }
                        onClick={() => {
                          const arrToAdd = [...unassignedOrders];
                          arrToAdd.push(order.order_id);
                          dispatch(
                            createUnassignRequest(
                              displayName,
                              order.order_id,
                              Uid,
                              userRole,
                              userAuth
                            )
                          );

                          dispatch(
                            orderActions.resetOrderStatus({
                              order: order.order_id,
                            })
                          );

                          setTimeout(() => {
                            dispatch(uiActions.closeFlashAlert());
                          }, 2000);
                        }}
                      >
                        Unassign Order
                      </a>
                    </Menu.Item>
                  ) : (
                    ''
                  )}
                </>
              )}
              {['super', 'admin'].includes(userRole) && (
                <>
                  <Menu.Item>
                    <a
                      href="#"
                      className={
                        'text-gray-700 block px-4 py-2 text-sm hover:bg-cbackground-lightgray'
                      }
                      onClick={() => {
                        dispatch(
                          deleteOrder(
                            basicOrder.order_id + '_' + basicOrder.order_info.order_number,
                            () => history.push(`/orders`),
                            userAuth
                          )
                        );
                      }}
                    >
                      Delete Order
                    </a>
                  </Menu.Item>
                </>
              )}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default ActionDropdown;
