import { createSlice } from '@reduxjs/toolkit';

const initalOrderState = {
  allOrders: [],
  setSelectedOrderForModal: '',
  setSelectedOrderForToaster: '',
  paymentObj: ''
};

const ordersSlice = createSlice({
  name: 'orders',
  initialState: initalOrderState,
  reducers: {
    setAllOrders(state, action) {
      state.allOrders = action.payload.allOrders;
    },
    addOrderToContractor(state, action) {
      const orderId = action.payload.order;
      const newContractor = action.payload.contractor;
      const newStatus = action.payload.order_status;
      const existingOrder = state.allOrders.find(
        (order) => order.order_id === orderId
      );

      if (!existingOrder) {
        console.log(
          'no existing order found in order-slice addOrderToContractor'
        );
      } else {
        existingOrder.assigned_contractor = newContractor;
        existingOrder.order_status = newStatus;
      }
    },
    updateOrderStatus(state, action) {
      const orderId = action.payload.order;
      const newStatus = action.payload.status;
      const existingOrder = state.allOrders.find(
        (order) => order.order_id === orderId
      );

      if (!existingOrder) {
        console.log(
          'no existing order found in order-slice updateOrder'
        );
      } else {
        existingOrder.order_status = newStatus;
      }
    },
    setSelectedOrderForModal(state, action) {
      state.setSelectedOrderForModal = action.payload.selectedOrder;
    },
    setSelectedOrderForToaster(state, action) {
      state.setSelectedOrderForToaster = action.payload.selectedOrder;
    },
    updateOrderImages(state, action) {
      const orderId = action.payload.orderId;
      const imageUrl = action.payload.image;
      const existingOrder = state.allOrders.find(
        (order) => order.order_id === orderId
      );
      const existingImgs = existingOrder.order_imgs;

      if (!existingOrder) {
        console.log('no existing order found in order-slice updateOrderImages');
      } else {
        existingImgs.push(imageUrl);
      }
    },
    deleteOrderImage(state, action) {
      const imageUrl = action.payload.image;
      const orderId = action.payload.orderId;
      const existingOrder = state.allOrders.find(
        (order) => order.order_id === orderId
      );
      existingOrder.order_imgs.pop(imageUrl)
    },
    resetOrderStatus(state, action) {
      const orderId = action.payload.order;
      const existingOrder = state.allOrders.find(
        (order) => order.order_id === orderId
      );
      existingOrder.assigned_contractor = null;
      existingOrder.order_imgs = [];
      existingOrder.order_status = 'unclaimed';
      existingOrder.payment_details = null;
    },
    updatePaymentStatus(state, action) {
      state.paymentObj = action.payload.paymentObj
    }
  },
});

export const orderActions = ordersSlice.actions;

export default ordersSlice;
