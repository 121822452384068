import React from "react";

const Card = ({ children, details, rightElement }) => {
  return (
    <div
      className={`${details.styling ?? ""} shadow bg-cbackground-primary my-3 ${
        details.notRounded ? "rounded-none" : "rounded-lg"
      } `}
    >
      {(details?.heading || details?.subtext) && (
        <div
          className={`flex items-center p-4 border-b border-cborder justify-between`}
        >
          <p className=" text-white font-light text-md mr-3">
            {details.heading}
          </p>
          {details.subtext && (
            <span className="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-cbackground-dark  text-white">
              {details.subtext}
            </span>
          )}
          {rightElement && <>{rightElement}</>}
        </div>
      )}
      <div className="p-4">{children}</div>
    </div>
  );
};

export default Card;
