import { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { uiActions } from '../store/ui-slice';
import { FaXbox, FaPlaystation } from 'react-icons/fa';
import { HiOutlineDesktopComputer } from 'react-icons/hi';
import { orderActions } from '../store/orders-slice';
import { addOrderToContractor } from '../store/orders-actions';
import {
  addChatUserToTwilio,
  addSMSUserToTwilio,
} from '../store/conversations-actions';
import Badges from './Badges';
import { createOverOrderRequest } from '../store/notifications-actions';
import Button from '../components/Button';

const OrderInfoSlideOut = ({ order, acceptingOrders }) => {
  const dispatch = useDispatch();
  const modalOpen = useSelector((state) => state.ui.showOrderDetailToaster);
  const userAuth = useSelector((state) => state.auth.token);
  const Uid = useSelector((state) => state.auth.uid);
  const displayName = useSelector((state) => state.auth.displayName);
  const userRole = useSelector((state) => state.auth.role);
  const requestedOrders = useSelector((state) => state.ui.requestedOrders);
  const [open, setOpen] = useState(modalOpen);
  const profileImgs = useSelector((state) => state.ui.profileImgs);

  const claimClick = () => {
    dispatch(
      addOrderToContractor(
        order.order_id,
        Uid,
        'claimed',
        order.order_info.order_number,
        userAuth
      )
    );
    if (order.conversation.client_phone) {
      dispatch(
        addChatUserToTwilio(
          order.conversation.sid,
          displayName,
          `${order.order_id}_${order.order_info.order_number}`,
          userAuth
        )
      );
      dispatch(
        addSMSUserToTwilio(
          order.conversation.sid,
          order.conversation.client_phone,
          order.conversation.assigned_number,
          userAuth
        )
      );
    }
    setOpen(false);

    setTimeout(() => {
      dispatch(
        uiActions.showOrderDetailToaster({
          setShow: false,
        })
      );
      dispatch(
        orderActions.setSelectedOrderForToaster({
          setSelectedOrderForToaster: '',
        })
      );
    }, 750);
  };

  const requestClick = () => {
    const arrToAdd = [...requestedOrders];
    arrToAdd.push(order.order_id);
    dispatch(
      createOverOrderRequest(
        order.order_id,
        order.order_info.order_number +
          `${
            order.order_info.order_index > 0
              ? '_' + order.order_info.order_index
              : ''
          }`,
        displayName,
        Uid,
        order.order_info.title,
        userAuth
      )
    );
    dispatch(uiActions.editRequestedOrders({ requestedOrders: arrToAdd }));
    setOpen(false);
    setTimeout(() => {
      dispatch(
        uiActions.showOrderDetailToaster({
          setShow: false,
        })
      );
      dispatch(
        orderActions.setSelectedOrderForToaster({
          setSelectedOrderForToaster: '',
        })
      );
    }, 750);
    setTimeout(() => {
      dispatch(uiActions.closeFlashAlert());
    }, 2000);
  };

  const actionButton = () => {
    const status = order.order_status;

    if (status === 'unclaimed' && !requestedOrders.includes(order.order_id)) {
      return (
        <div className="flex">
          <Button
            text={acceptingOrders ? 'CLAIM' : 'REQUEST'}
            action={acceptingOrders ? () => claimClick() : () => requestClick()}
            style="primary"
          />
        </div>
      );
    }

    if (status === 'unclaimed' && requestedOrders.includes(order.order_id)) {
      return (
        <div className="flex">
          <Button text={'REQUESTED'} disabled={true} />
        </div>
      );
    }
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-40 inset-0 overflow-hidden"
          onClose={() => {
            setOpen(false);
            setTimeout(() => {
              dispatch(
                uiActions.showOrderDetailToaster({
                  setShow: false,
                })
              );
              dispatch(
                orderActions.setSelectedOrderForToaster({
                  setSelectedOrderForToaster: '',
                })
              );
            }, 750);
          }}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0" />

            <div className="fixed inset-y-0 right-0  max-w-full flex md:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-md">
                  <div className="h-full flex flex-col bg-cbackground-lightgray shadow-xl overflow-y-scroll">
                    <div className="px-4 py-6 sm:px-6 bg-cbackground-light">
                      <div className="flex items-start w-full justify-end">
                        <div className="ml-3 h-8 flex w-full justify-start items-center">
                          {order.properties['PLATFORM'] === 'XBOX' && (
                            <div className="flex-shrink-0 h-8 w-8 p-2 mr-4 bg-platform-xbox rounded">
                              <FaXbox size={'100%'} color={'#ffffff'} />
                            </div>
                          )}
                          {order.properties['PLATFORM'] === 'PLAYSTATION' && (
                            <div className="flex-shrink-0 h-8 w-8 p-2 mr-4 bg-platform-playstation rounded">
                              <FaPlaystation size={'100%'} color={'#ffffff'} />
                            </div>
                          )}
                          {order.properties['PLATFORM'] === 'PC' && (
                            <div className="flex-shrink-0 h-8 w-8 p-2 mr-4 bg-platform-pc rounded">
                              <HiOutlineDesktopComputer
                                size={'100%'}
                                color={'#ffffff'}
                              />
                            </div>
                          )}
                          {userRole === 'super' || userRole === 'admin' ? (
                            <div className={'mr-2'}>
                              <Badges type={order.order_status} />
                            </div>
                          ) : (
                            ''
                          )}
                          {['VIP', 'VIP ( FAST PASS )'].includes(order.properties['QUEUE TIME']) ? (
                            <div className={'mr-2'}>
                              <Badges type={'vip'} />
                            </div>
                          ) : (
                            <div />
                          )}
                          <div className="ml-auto">
                            <button
                              type="button"
                              className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                              onClick={() => {
                                setOpen(false);
                                setTimeout(() => {
                                  dispatch(
                                    uiActions.showOrderDetailToaster({
                                      setShow: false,
                                    })
                                  );
                                  dispatch(
                                    orderActions.setSelectedOrderForToaster({
                                      setSelectedOrderForToaster: '',
                                    })
                                  );
                                }, 750);
                              }}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Main */}
                    <div>
                      <div className="pb-1 sm:pb-6">
                        <div>
                          <div className="mt-6 px-4 sm:mt-8 sm:flex sm:items-end sm:px-6">
                            <div className="sm:flex-1">
                              <div className="flex">
                                <div className="flex justify-between">
                                  <div>
                                    <div className="flex  items-center">
                                      <h3 className="font-bold text-xl text-gray-900 sm:text-2xl">
                                        {order.order_info.title}
                                      </h3>
                                    </div>
                                    <p className="text-sm text-gray-500">
                                      {order.order_info.game}
                                    </p>
                                  </div>
                                </div>
                                {order.assigned_contractor !== null ? (
                                  <div className="h-16 w-16 mb-8 ml-auto rounded-full flex justify-center items-center">
                                    <img
                                      className="h-100 w-100 rounded-full"
                                      src={`${
                                        profileImgs.userId[
                                          order.assigned_contractor
                                        ]
                                      }`}
                                      alt=""
                                    />
                                  </div>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="mx-6 mt-4">{actionButton()}</div>
                        </div>
                      </div>
                      <div className="px-4 pt-5 pb-5 sm:px-0 sm:pt-0">
                        <div
                          key={Math.random() * 1000}
                          className="pt-2 pb-5 sm:px-6"
                        >
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                            {'TITLE'}
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                            <div>
                              {order.order_info.title}
                              {order.order_info.quantity > 1 ? (
                                <span className="ml-2 inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium from-green-700 to-green-300 bg-gradient-to-b text-gray-800">
                                  {'X' + order.order_info.quantity}
                                </span>
                              ) : (
                                ''
                              )}
                            </div>
                          </dd>
                        </div>
                        {order.order_info.variant_title &&
                        order.order_info.variant_title !==
                          order.order_info.title ? (
                          <div
                            key={Math.random() * 1000}
                            className="pt-2 pb-5 sm:px-6"
                          >
                            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                              {'VARIANT'}
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                              {order.order_info.variant_title}
                            </dd>
                          </div>
                        ) : (
                          ''
                        )}
                        {Object.entries(order.properties).map(
                          ([key, value]) => {
                            if (value !== null) {
                              return (
                                <div key={key} className="pt-2 pb-5 sm:px-6">
                                  <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 uppercase">
                                    {`${key}`}
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 uppercase">
                                    {`${value}`}
                                  </dd>
                                </div>
                              );
                            }
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default OrderInfoSlideOut;
