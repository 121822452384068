import { useSelector } from "react-redux";
import Button from "../Button";

const UserCompletePrompt = ({completeClick, setShowComplete}) => {
  const order = useSelector((state) => state.ui.activeOrderDetail);

  return (
    <div className="mt-6 p-4 sm:px-6">
      <div
        className={'text-sm font-medium text-white uppercase pb-2'}
      >{`Are you sure you want to fulfill order ${order.order_info.order_number}?`}</div>
      <div className="text-sm text-ctext-primary font-semibold uppercase pb-2">
        Please make sure you have done the following:
      </div>
      <ul className="mb-6">
        <li className="text-sm text-ctext-primary font-semibold uppercase">
          1️⃣ Completed the order fully.
        </li>
        <li className="text-sm text-ctext-primary font-semibold uppercase">
          2️⃣ Uploaded the completion screenshot.
        </li>
      </ul>
      <div className="flex">
        <div className="mr-4">
          <Button
            text={'✅ COMPLETE'}
            type={'primary'}
            action={() => completeClick()}
          />
        </div>
        <div>
          <Button
            text={'❌ KEEP WORKING'}
            style={'secondary'}
            action={() => {
              setShowComplete(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default UserCompletePrompt;
