import { notificationActions } from './notifications-slice';
import { uiActions } from './ui-slice';
import axios from '../helpers/authCatch';
import axiosConfig from '../helpers/axiosConfig';
import { orderActions } from './orders-slice';

export const fetchNotificationData = (userAuth) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const config = {
        headers: { Authorization: userAuth },
      };

      const response = await axios.get(axiosConfig('notifications'), config);

      if (response.data === 'error') {
        throw new Error('Error fetching orders originating in /notifications');
      }

      const data = await response.data;
      return data;
    };

    try {
      const notificationData = await fetchData();
      const sorted = notificationData.sort((a,b) => {
        if (new Date(a.created_at).getTime() > new Date(b.created_at).getTime()) {
          return 1
        } else {
          return -1
        }
      })
      dispatch(
        notificationActions.setAllNotifications({
          allNotifications: sorted,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
};

export const createOverOrderRequest = (
  orderId,
  orderNumber,
  displayName,
  userId,
  orderName,
  userAuth
) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const body = {
        order_id: orderId,
        user_doc: displayName,
        order_number: orderNumber,
        order_name: orderName
      };

      const headers = { headers: { Authorization: userAuth } };

      const response = await axios.post(
        axiosConfig('notifications/order_request/create'),
        body,
        headers
      );
      const data = await response.data;
      return data;
    };
    try {
      await fetchData();
      dispatch(
        notificationActions.addNotification({
          access: [userId],
          type: 'order_request',
          payload: {
            message: `Request to add order ${orderId} submitted.`,
            order_id: orderId,
          },
        }),
        dispatch(
          uiActions.showFlashAlert({
            message: "Order request submitted. \n We'll get back to you soon",
          })
        )
      );
    } catch (error) {
      console.log(error);
    }
  };
};

export const approveOrderOverRequest = (
  userDoc,
  orderId,
  contractorId,
  notificationId,
  userAuth
) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const body = {
        order_id: orderId,
        user_doc: userDoc,
        contractor_id: contractorId,
        notification_id: notificationId,
        order_status: "claimed"
      };

      const headers = { headers: { Authorization: userAuth } };

      const response = await axios.post(
        axiosConfig('notifications/order_request/approve'),
        body,
        headers
      );
      const data = await response.data;
      return data;
    };
    try {
      await fetchData();
      dispatch(
        notificationActions.deleteNotification({
          id: notificationId,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
};

export const denyOrderOverRequest = (
  userDoc,
  orderId,
  contractorId,
  notificationId,
  userAuth
) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const body = {
        order_id: orderId,
        user_doc: userDoc,
        contractor_id: contractorId,
        notification_id: notificationId,
      };

      const headers = { headers: { Authorization: userAuth } };

      const response = await axios.post(
        axiosConfig('notifications/order_request/deny'),
        body,
        headers
      );
      const data = await response.data;
      return data;
    };
    try {
      await fetchData();
      dispatch(
        notificationActions.deleteNotification({
          id: notificationId,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
};

export const createUnassignRequest = (userDoc, orderId, userId, userRole, userAuth) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const body = {
        order_id: orderId,
        user_doc: userDoc,
      };

      const headers = { headers: { Authorization: userAuth } };

      const response = await axios.post(
        axiosConfig('notifications/unassign_request/create'),
        body,
        headers
      );
      const data = await response.data;
      return data;
    };
    try {
      await fetchData();
      if (['admin', 'super'].includes(userRole)) {
        dispatch(
          uiActions.showFlashAlert({
            message:
              "Order unassigned.",
          })
        );
      } else {
        dispatch(
          notificationActions.addNotification({
            access: [userId],
            type: 'unassign_request',
            payload: {
              message: `Request to unassign order: ${orderId} submitted.`,
              order_id: orderId,
            },
          })
        );
        dispatch(
          uiActions.showFlashAlert({
            message:
              "Unassign order request submitted. \n We'll get back to you soon",
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const approveUnassignRequest = (
  orderId,
  contractorId,
  notificationId,
  userDoc,
  userAuth
) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const body = {
        order_id: orderId,
        contractor_id: contractorId,
        notification_id: notificationId,
        user_doc: userDoc,
      };

      const headers = { headers: { Authorization: userAuth } };

      const response = await axios.post(
        axiosConfig('notifications/unassign_request/approve'),
        body,
        headers
      );
      const data = await response.data;
      return data;
    };
    try {
      await fetchData();
      dispatch(
        notificationActions.deleteNotification({
          id: notificationId,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
};

export const denyUnassignRequest = (
  orderId,
  contractorId,
  notificationId,
  userDoc,
  userAuth
) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const body = {
        order_id: orderId,
        contractor_id: contractorId,
        notification_id: notificationId,
        user_doc: userDoc,
      };

      const headers = { headers: { Authorization: userAuth } };

      const response = await axios.post(
        axiosConfig('notifications/unassign_request/deny'),
        body,
        headers
      );
      const data = await response.data;
      return data;
    };
    try {
      await fetchData();
      dispatch(
        notificationActions.deleteNotification({
          id: notificationId,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
};

export const createOrderCompleteRequest = (
  orderId,
  userDoc,
  userId,
  completedRequests,
  userAuth
) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const body = {
        order_id: orderId,
        user_doc: userDoc
      };

      const headers = { headers: { Authorization: userAuth } };

      const response = await axios.post(
        axiosConfig('notifications/order_complete/create'),
        body,
        headers
      );
      const data = await response.data;
      return data;
    };
    try {
      await fetchData();
      dispatch(
        notificationActions.addNotification({
          access: [userId],
          type: 'order_complete_request',
          payload: {
            message: `Request to complete order: ${orderId} submitted.`,
            order_id: orderId,
          },
        })
      );
      dispatch(
        uiActions.editCompletedRequests({
          completedRequests: completedRequests,
        })
      );
      dispatch(
        uiActions.showFlashAlert({
          message: "Order approval submitted. \n We'll get back to you soon",
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
};

export const approveOrderCompleteRequest = (
  adjustment,
  note,
  orderId,
  contractorId,
  conversationId,
  orderDocId,
  twilioDocId,
  userAuth,
  orderNumber
) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const body = {
        adjustment: adjustment,
        note: note,
        order_id: orderId,
        contractor_id: contractorId,
        conversation_id: conversationId,
        order_doc_id: orderDocId,
        twilio_doc_id: twilioDocId,
        order_number: orderNumber
      };
      const headers = { headers: { Authorization: userAuth } };

      const response = await axios.post(
        axiosConfig('notifications/order_complete/approve'),
        body,
        headers
      );
      const data = await response.data;
      return data;
    };
    try {
      await fetchData();
      dispatch(
        uiActions.showFlashAlert({
          message: "Order approved, we'll let the contractor know.",
        })
      );

      dispatch(
        orderActions.updateOrderStatus({
          order: orderId,
          status: 'approved',
        })
      );

      setTimeout(() => {
        dispatch(uiActions.closeFlashAlert());
      }, 2000);
    } catch (error) {
      dispatch(
        uiActions.showFlashAlert({
          message: "There was an error approving the order.\n" + error.response.data.message
        })
      );

      setTimeout(() => {
        dispatch(uiActions.closeFlashAlert());
      }, 3000);
    }
  };
};

export const denyOrderCompleteRequest = (userId, orderId, userAuth) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const body = {
        order_id: orderId,
        user_id: userId,
      };

      const headers = { headers: { Authorization: userAuth } };

      const response = await axios.post(
        axiosConfig('notifications/order_complete/deny'),
        body,
        headers
      );
      const data = await response.data;
      return data;
    };
    try {
      await fetchData();
      dispatch(
        uiActions.showFlashAlert({
          message: "Order rejected, we'll let the contractor know.",
        }))
      dispatch(
        orderActions.updateOrderStatus({
          order: orderId,
          status: 'claimed'
        })
      )
    } catch (error) {
      console.log(error);
    }
  };
};

export const createPaymentRequest = (userId, userDoc, userAuth) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const body = {
        user_doc: userDoc,
      };

      const headers = { headers: { Authorization: userAuth } };

      const response = await axios.post(
        axiosConfig('notifications/pmt_request/create'),
        body,
        headers
      );
      const data = await response.data;
      return data;
    };
    try {
      await fetchData();
      dispatch(
        notificationActions.addNotification({
          access: [userId],
          type: 'payment_request',
          payload: {
            message: `Payment request created - once it's approved we'll let you know.`,
          },
        })
      );
      dispatch(uiActions.editPaymentRequest({ setRequest: true }));
    } catch (error) {
      console.log(error);
    }
  };
};

export const approvePaymentRequest = (
  contractorId,
  notificationId,
  userAuth
) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const body = {
        contractor_id: contractorId,
        notification_id: notificationId,
      };

      const headers = { headers: { Authorization: userAuth } };

      const response = await axios.post(
        axiosConfig('notifications/pmt_request/approve'),
        body,
        headers
      );
      const data = await response.data;
      return data;
    };
    try {
      await fetchData();
      dispatch(
        notificationActions.deleteNotification({
          id: notificationId,
        })
      );
      dispatch(
        uiActions.showPayoutToaster({
          setShow: false,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
};

export const denyPaymentRequest = (
  userDoc,
  notificationId,
  contractorId,
  userAuth
) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const body = {
        contractor_id: contractorId,
        user_doc: userDoc,
        notification_id: notificationId,
      };

      const headers = { headers: { Authorization: userAuth } };

      const response = await axios.post(
        axiosConfig('notifications/pmt_request/deny'),
        body,
        headers
      );
      const data = await response.data;
      return data;
    };
    try {
      await fetchData();
      dispatch(
        notificationActions.deleteNotification({
          id: notificationId,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
};

export const addNotification = (access, type, payload, userAuth) => {
  return async (dispatch) => {
    const fetchData = async () => {
      const body = {
        access: [...access],
        type: type,
        payload: payload,
      };

      const headers = { headers: { Authorization: userAuth } };

      const response = await axios.post(
        axiosConfig('notifications'),
        body,
        headers
      );
      const data = await response.data;
      return data;
    };
    try {
      await fetchData();
      dispatch(
        notificationActions.addNotification({
          access: [...access],
          type: type,
          payload: payload,
        })
      );

      if (type === 'payment_request') {
        dispatch(
          uiActions.editPaymentRequest({
            setRequest: true,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const changeNotificationStatus = (
  notificationId,
  type,
  payload,
  userAuth
) => {
  return async (dispatch) => {
    const updateData = async () => {
      const config = {
        headers: { Authorization: userAuth },
      };

      const body = {
        notification_id: notificationId,
        payload: payload,
      };

      if (type === 'order_request' && payload.action === 'approve') {
        const path = `notifications/status/order_request/approve`;
        const response = await axios.post(axiosConfig(path), body, config);

        if (response.data === 'error') {
          throw new Error(
            'Error fetching orders originating in /notifications/status'
          );
        }

        const data = await response.data;
        return data;
      }

      if (type === 'order_request' && payload.action === 'deny') {
        const path = `notifications/status/order_request/deny`;
        const response = await axios.post(axiosConfig(path), body, config);

        if (response.data === 'error') {
          throw new Error(
            'Error fetching orders originating in /notifications/status'
          );
        }

        const data = await response.data;
        return data;
      }

      if (type === 'cancel_request' && payload.action === 'deny') {
        const path = `notifications/edit/unassign_request/deny`;
        const response = await axios.post(axiosConfig(path));

        if (response.data === 'error') {
          throw new Error(
            'Error fetching orders originating in /notifications/edit/unassign_request/deny'
          );
        }
        const data = await response.data;
        return data;
      }
    };

    try {
      await updateData();
      dispatch(
        notificationActions.deleteNotification({
          id: notificationId,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
};

export const processPaymentNotification = (docId, userId, userAuth) => {
  return async (dispatch) => {
    const updateData = async () => {
      const config = {
        headers: { Authorization: userAuth },
      };
      const path = `orders/get_payment_details/${userId}/process`;
      await axios.get(axiosConfig(path), config); //process the pmt
      await axios.delete(axiosConfig(`notifications/${docId}`), config); //delete notification in firebas

      const body = {
        access: [userId],
        type: 'notification',
        payload: {
          message: 'Your payment has been approved!',
        },
        userAuth,
      };

      await axios.post(axiosConfig('notifications'), body, config);
      return;
    };

    try {
      await updateData();
      dispatch(
        notificationActions.deleteNotification({
          id: docId,
        }) //delete the notification in UI
      );
    } catch (error) {
      return error;
    }
  };
};

export const deleteNotification = (docId, userAuth) => {
  return async (dispatch) => {
    const updateData = async () => {
      const config = {
        headers: { Authorization: userAuth },
      };

      const path = `notifications/${docId}`;
      await axios.delete(axiosConfig(path), config);
      return;
    };
    try {
      await updateData();
      dispatch(notificationActions.deleteNotification({ id: docId }));
    } catch (error) {
      return error;
    }
  };
};
