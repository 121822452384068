import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useHistory,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import Login from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
import OrdersPage from './pages/OrdersPage';
import MembersPage from './pages/MembersPage';
import PayoutPage from './pages/PayoutPage';
import TermsPage from './pages/TermsPage';
import ProfilePage from './pages/ProfilePage';
import SignUpPage from './pages/SignUp';
import CreateOrderPage from './pages/CreateOrderPage'
import LoadingPage from './pages/LoadingPage';
import WithNav from './components/WithNav';
import NotificationPage from './pages/NotificationPage';
import OrderDetailPage from './pages/OrderDetailPage';
import UserDetailPage from './pages/UserDetailPage';
import FlashAlert from './components/FlashAlert';
import { ErrorBoundary } from 'react-error-boundary';

function App() {
  const history = useHistory();
  const isLoggedIn = useSelector((state) => state.auth.token);
  const role = useSelector((state) => state.auth.role);

  const PrivateRoute = ({
    children,
    access = ['user', 'admin', 'super'],
    ...rest
  }) => {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          isLoggedIn && access.includes(role) ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  };

  function ErrorFallback({ error, resetErrorBoundary }) {
    return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre>{error.message}</pre>
        <button onClick={resetErrorBoundary}>Try again</button>
      </div>
    );
  }

  return (
    <Router>
      <div>
        <main>
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
              history.push('/login');
            }}
          >
            <Switch>
              <Route path="/" exact>
                <Redirect to="/login" />
              </Route>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/signup">
                <SignUpPage />
              </Route>
              <PrivateRoute path="/user_detail/:user" access={['super']}>
                <WithNav>
                  <UserDetailPage />
                </WithNav>
              </PrivateRoute>
              <PrivateRoute path="/create_order" access={['admin', 'super']}>
                <WithNav>
                  <CreateOrderPage />
                </WithNav>
              </PrivateRoute>
              <PrivateRoute path="/members" access={['super']}>
                <WithNav>
                  <MembersPage />
                </WithNav>
              </PrivateRoute>
              <PrivateRoute path="/notifications" access={['super', 'admin']}>
                <WithNav>
                  <NotificationPage />
                </WithNav>
              </PrivateRoute>
              <PrivateRoute path="/order_detail/:order_id">
                <WithNav>{<OrderDetailPage />}</WithNav>
              </PrivateRoute>
              <PrivateRoute path="/dashboard">
                <WithNav>{<DashboardPage />}</WithNav>
              </PrivateRoute>
              <PrivateRoute path="/orders">
                <WithNav>{<OrdersPage />}</WithNav>
              </PrivateRoute>
              <PrivateRoute path="/payout">
                <WithNav>
                  <PayoutPage />
                </WithNav>
              </PrivateRoute>
              <PrivateRoute path="/termsofservice">
                <WithNav>
                  <TermsPage />
                </WithNav>
              </PrivateRoute>
              <PrivateRoute path="/profile/:memberId">
                <WithNav>
                  <ProfilePage />
                </WithNav>
              </PrivateRoute>
              <Route path="/whoops">
                <LoadingPage message={"Whoops, there's nothing here"} />
              </Route>
              <Route path="/verify">
                <LoadingPage
                  message={"We're verifying your account, hang tight."}
                />
              </Route>
              <Route path="*">
                <Redirect to="/whoops" />
              </Route>
            </Switch>
          </ErrorBoundary>
        </main>
        <FlashAlert />
      </div>
    </Router>
  );
}

export default App;
