import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getOrderConvoParticipants } from '../../store/ui-actions';
import Card from '../helpers/Card';
import OrderConversationAction from './OrderConversationAction';
import { XCircleIcon, DotsVerticalIcon } from '@heroicons/react/solid';
import { removeUserFromTwilio } from '../../store/conversations-actions';
import ProgressIndicator from '../helpers/ProgressIndicator';
import ConversationEdit from './ConversationEdit';

const OrderConversation = () => {
  const [selected, setSelected] = useState();
  const [openDropdown, setOpenDropdown] = useState(false);
  const dispatch = useDispatch();
  const { order_id } = useParams();
  const nameMap = useSelector((state) => state.ui.profileImgs.userIdNameMap);
  const userAuth = useSelector((state) => state.auth.token);
  const orderConvoSid = useSelector(
    (state) =>
      state.orders.allOrders.filter(
        (order) => order.order_id === parseInt(order_id)
      )[0].conversation.sid
  );
  const convoParticipants = useSelector(
    (state) => state.ui.activeOrderDetail.conversation.participants
  );
  const basicOrder = useSelector(
    (state) =>
      state.orders.allOrders.filter(
        (order) => order.order_id === parseInt(order_id)
      )[0]
  );
  const displayName = useSelector((state) => state.auth.displayName);
  const userRole = useSelector((state) => state.auth.role);

  useEffect(() => {
    setOpenDropdown(true);
    setSelected('');
    dispatch(getOrderConvoParticipants(orderConvoSid, userAuth));
  }, []);

  const contractors = [];

  for (const contractor in nameMap) {
    contractors.push({
      id: nameMap[contractor],
      name: nameMap[contractor].toUpperCase(),
    });
  }

  return (
    <Card
      details={{
        heading: 'Order Conversation',
        styling: 'mr-0 w-full lg:mr-3',
      }}
      rightElement={<DotsVerticalIcon className="h-6 w-6 text-ctext-primary" />}
    >
      <ConversationEdit
        convName={
          basicOrder.order_info.order_number +
          ' - ' +
          basicOrder.order_info.title
        }
        orderName={
          basicOrder.order_id + '_' + basicOrder.order_info.order_number
        }
        conversationId={basicOrder.conversation.sid}
        orderPhone={basicOrder.conversation.client_phone}
        conversationSid={basicOrder.conversation.sid}
        proxyNumber={basicOrder.conversation.assigned_number}
        currentClientNumber={convoParticipants.filter((participant) => {
          return participant.name === basicOrder.conversation.client_phone
        })}
        userAuth={userAuth}
      />
      {basicOrder.conversation.assigned_number === 'NO NUMBER PROVIDED' && (
        <div className="text-sm text-ctext-primary uppercase">
          {'NO CLIENT PHONE NUMBER PROVIDED'}
        </div>
      )}
      {basicOrder.conversation.assigned_number === '' &&
        basicOrder.conversation.client_phone && (
          <div className="text-sm mb-2 text-ctext-primary uppercase">
            {'NO CONVERSATION ASSIGNED'}
          </div>
        )}
        {basicOrder.conversation.sid &&
      basicOrder.conversation.assigned_number !== 'NO NUMBER PROVIDED' &&
        <>
          {['super', 'admin'].includes(userRole) &&
          convoParticipants !== 'NO_CONVO_RESOURCE' ? (
            <div className={'mb-4'}>
              <OrderConversationAction
                setOpen={(type) => setOpenDropdown(type)}
                openDropdown={openDropdown}
                contractors={contractors}
                selected={selected}
                convoId={orderConvoSid}
                orderName={`${basicOrder.order_id}_${basicOrder.order_info.order_number}`}
              />
            </div>
          ) : (
            ''
          )}
          {!convoParticipants && <ProgressIndicator type={'dots'} />}
          {convoParticipants === 'NO_CONVO_RESOURCE' && (
            <div className="text-sm text-ctext-primary uppercase">
              {'NO ASSIGNED CONVERSATION'}
            </div>
          )}
          {convoParticipants !== 'NO_CONVO_RESOURCE' &&
            convoParticipants?.map((participant, i) => {
              return (
                <div
                  key={i}
                  className="inline-flex items-center mr-2 mb-2 px-3 py-0.5 rounded-full text-sm font-medium from-blue-500 to-blue-300 bg-gradient-to-b text-gray-800"
                >
                  {['admin', 'super'].includes(userRole) ? (
                    <>
                      {participant.name?.charAt(0) === '+' ||
                      participant.name ===
                        nameMap[basicOrder.assigned_contractor] ? (
                        <div className="h-4" />
                      ) : (
                        <div
                          onDoubleClick={async (e) => {
                            e.preventDefault();
                            dispatch(
                              removeUserFromTwilio(
                                basicOrder.conversation.sid,
                                participant.sid,
                                participant.name,
                                `${basicOrder.order_id}_${basicOrder.order_info.order_number}`,
                                displayName,
                                userAuth
                              )
                            );
                          }}
                        >
                          <XCircleIcon className="h-4 w-4 mr-2 text-cprimary-primary" />
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="h-4" />
                  )}
                  <div>
                    {participant.name?.charAt(0) === '+'
                      ? `CLIENT ${participant.name.slice(-2)}`
                      : participant.name}
                  </div>
                </div>
              );
            })}
          <div className="text-sm text-ctext-primary uppercase">
            {convoParticipants?.length === 0
              ? 'NO CONVERSATION PARTICIPANTS'
              : ''}
          </div>
        </>
      }
    </Card>
  );
};

export default OrderConversation;
