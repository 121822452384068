import React from 'react';

const Badges = ({ type }) => {
  switch (type) {
    case 'vip':
      return (
        <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium from-cprimary-primary to-red-400 bg-gradient-to-b text-gray-800">
          VIP
        </span>
      );
    case 'stream':
      return (
        <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium from-indigo-800 to-blue-500 bg-gradient-to-b text-gray-800">
          STREAM
        </span>
      );
    case 'claimed':
      return (
        <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium from-blue-500 to-blue-300 bg-gradient-to-b text-gray-800">
          CLAIMED
        </span>
      );
    case 'complete':
      return (
        <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium from-indigo-800 to-blue-500 bg-gradient-to-b text-gray-800">
          COMPLETE
        </span>
      );
    case 'unclaimed':
      return (
        <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium from-green-700 to-green-300 bg-gradient-to-b text-gray-800">
          OPEN
        </span>
      );
    case 'approved':
      return (
        <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium from-green-700 to-green-300 bg-gradient-to-b text-gray-800">
          APPROVED
        </span>
      );
    case 'paid':
      return (
        <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium from-green-700 to-green-300 bg-gradient-to-b text-gray-800">
          💲 PAID
        </span>
      );
    case 'archived':
      return (
        <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium from-yellow-700 to-yellow-300 bg-gradient-to-b text-gray-800">
          ARCHIVED
        </span>
      );
    case 'account':
      return (
        <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium from-cprimary-primary to-red-400 bg-gradient-to-b text-gray-800">
          SIGNUP
        </span>
      );
    case 'user':
      return (
        <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium from-blue-700 to-blue-300 bg-gradient-to-b text-gray-800">
          USER
        </span>
      );
    case 'admin':
      return (
        <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium from-green-700 to-green-300 bg-gradient-to-b text-gray-800">
          ADMIN
        </span>
      );
    case 'super':
      return (
        <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium from-yellow-700 to-yellow-300 bg-gradient-to-b text-gray-800">
          SUPER
        </span>
      );

    default:
      return (
        <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium from-indigo-800 to-blue-500 bg-gradient-to-b text-gray-800">
          {'NO MATCH'}
        </span>
      );
  }
};

export default Badges;
