import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useDispatch, useSelector } from 'react-redux';
import { XIcon } from '@heroicons/react/outline';
import { uiActions } from '../store/ui-slice';
import axios from '../helpers/authCatch';
import axiosConfig from '../helpers/axiosConfig';
import {
  approvePaymentRequest,
  denyPaymentRequest,
} from '../store/notifications-actions';
import { Link } from 'react-router-dom';
import Button from '../components/Button';

const PayoutInfoSlideout = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const activeNotificationUser = useSelector(
    (state) => state.ui.activeNotification
  );
  const userAuth = useSelector((state) => state.auth.token);
  const [paymentDetails, setPaymentDetails] = useState();
  const notification = useSelector((state) => state.ui.activeNotification);

  const config = {
    headers: { Authorization: userAuth },
  };

  useEffect(async () => {
    const path = `orders/get_payment_details/${activeNotificationUser.payload.contractor_id}/summary`;
    const response = await axios.get(axiosConfig(path), config);
    setPaymentDetails(response);
  }, []);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-40 inset-0 overflow-hidden"
          onClose={() => {
            setOpen(false);
            setTimeout(() => {
              dispatch(
                uiActions.showPayoutToaster({
                  setShow: false,
                })
              );
              dispatch(
                uiActions.setActiveNotification({
                  notificationId: '',
                })
              );
            }, 750);
          }}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0" />

            <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-md">
                  <div className="h-full flex flex-col bg-cbackground-lightgray shadow-xl overflow-y-scroll">
                    <div className="px-4 py-6 sm:px-6 bg-cbackground-light">
                      <div className="flex items-start justify-between">
                        <div />
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            type="button"
                            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                            onClick={() => {
                              setOpen(false);
                              setTimeout(() => {
                                dispatch(
                                  uiActions.showPayoutToaster({
                                    setShow: false,
                                  })
                                );
                                dispatch(
                                  uiActions.setActiveNotification({
                                    notificationId: '',
                                  })
                                );
                              }, 750);
                            }}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* Main */}
                    {paymentDetails ? (
                      <div>
                        <div className="pb-1 sm:pb-6">
                          <div>
                            <div className="mt-6 px-4 sm:mt-8 sm:flex sm:items-end sm:px-6">
                              <div className="sm:flex-1">
                                <div>
                                  <div className="flex items-center">
                                    <h3 className="font-bold text-xl text-gray-900 sm:text-2xl">
                                      {paymentDetails
                                        ? paymentDetails.data.data.user_name
                                        : ''}
                                    </h3>
                                  </div>
                                  <p className="text-ctext-accent font-bold">
                                    {paymentDetails
                                      ? `$${(
                                          paymentDetails.data.data.total_value +
                                          paymentDetails.data.data.total_tips +
                                          paymentDetails.data.data
                                            .total_adjustment
                                        ).toFixed(2)}`
                                      : ''}
                                  </p>
                                </div>
                                <div className="mt-5 flex flex-wrap space-y-3 sm:space-y-0 sm:space-x-3">
                                  <div className="">
                                    <Button
                                      text="✅ PAY"
                                      style="primary"
                                      action={() => {
                                        dispatch(
                                          approvePaymentRequest(
                                            notification.payload.contractor_id,
                                            notification.payload.doc_id,
                                            userAuth
                                          )
                                        );
                                      }}
                                    />
                                  </div>
                                  <div className="">
                                    <Button
                                      text="❌ DENY"
                                      style="secondary"
                                      action={() => {
                                        dispatch(
                                          denyPaymentRequest(
                                            notification.payload.user_doc,
                                            notification.payload.doc_id,
                                            notification.payload.contractor_id,
                                            userAuth
                                          )
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="px-4 pt-5 pb-5 sm:px-5 sm:pt-0">
                          {paymentDetails
                            ? paymentDetails.data.data.order_lineitems.map(
                                (order) => {
                                  console.log(order);
                                  return (
                                    <Link
                                      key={Math.random() * 1000000}
                                      to={`/order_detail/${order.id}`}
                                    >
                                      {/* USE THIS ONCE LOCAL STORAGE IS USED TO STORE AUTH KEY
                                  <Link target="_blank" rel="noopener noreferrer" key={i} to={`/order_detail/${order.id}`}></Link> */}
                                      <div
                                        className={
                                          'py-2 px-4 my-4 bg-cbackground-light rounded-lg border border-cborder'
                                        }
                                        key={Math.random() * 1000}
                                      >
                                        <div
                                          className={
                                            'flex justify-between font-bold'
                                          }
                                        >
                                        <div>
                                          <div className={'text-indigo-100'}>
                                            {`#${order.order_num}`}
                                            <span>
                                            {order.is_tip && (
                                              <span className="inline-flex ml-2 items-center mr-2 px-3 py-0.5 rounded-full text-sm font-medium from-green-700 to-green-300 bg-gradient-to-b text-gray-800">
                                                TIP
                                              </span>
                                            )}
                                          </span>
                                          </div>
                                          <div className={'text-ctext-primary'}>
                                            {`${order.order_name}`}
                                          </div>
                                        </div>
                                          <span className={'text-ctext-accent'}>
                                            {'$ ' +
                                              parseFloat(order.total).toFixed(
                                                2
                                              )}
                                          </span>
                                        </div>
                                        <div
                                          className={
                                            'text-sm text-ctext-primary'
                                          }
                                        >{`Tips: $${parseFloat(
                                          order.total_tips
                                        ).toFixed(2)}`}</div>
                                        <div
                                          className={
                                            'text-sm text-ctext-primary'
                                          }
                                        >{`Adjustment: $${parseFloat(
                                          order.total_adjustment
                                        ).toFixed(2)}`}</div>
                                        <div
                                          className={
                                            'text-sm text-ctext-primary'
                                          }
                                        >{`Notes: ${order.note}`}</div>
                                      </div>
                                    </Link>
                                  );
                                }
                              )
                            : ''}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default PayoutInfoSlideout;
