import Card from '../helpers/Card';
import { useHistory } from 'react-router-dom';
import InfoTitle from '../InfoTitle';
import InfoContent from '../helpers/InfoContent';

const PayoutList = ({ data }) => {
  const history = useHistory();

  return (
    <Card
      details={{
        heading: 'Payment History',
        styling: 'w-full overflow-y-auto lg:w-8/12',
      }}
    >
      {data ? (
        <table className="w-full table-auto">
          <thead>
            <tr className="whitespace-nowrap border-b border-cbackground-light">
              <th
                scope="col"
                className="px-4 py-3 text-left text-xs font-medium text-white uppercase tracking-wider "
              >
                <InfoTitle content={'Payout ID'} />
              </th>
              <th
                scope="col"
                className="px-4 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"
              >
                <InfoTitle content={'Payment Date'} />
              </th>
              <th
                scope="col"
                className="px-4 py-3 text-left text-xs font-medium text-white uppercase tracking-wider hidden xl:inline-block"
              >
                <InfoTitle content={'Tips'} />
              </th>
              <th
                scope="col"
                className="px-4 py-3 text-left text-xs font-medium text-white uppercase tracking-wider hidden xl:inline-block"
              >
                <InfoTitle content={'Adjustments'} />
              </th>
              <th
                scope="col"
                className="px-4 py-3 text-left text-xs font-medium text-white uppercase tracking-wider"
              >
                <InfoTitle content={'Total'} />
              </th>
            </tr>
          </thead>
          <tbody className="bg-cbackground-primary">
            {data.length === 0
              ? <div className={"text-ctext-primary p-4"}>{'No Payouts Recorded'}</div>
              : data.map((payout) => (
                  <tr key={payout.payout_id}>
                    <td
                      className="px-4 py-4 whitespace-nowrap text-sm text-ctext-accent hover:text-blue-300 text-left cursor-pointer font-bold"
                      id={payout.payout_id}
                      onClick={(e) => {
                        history.push({
                          hash: e.target.id,
                        });
                      }}
                    >
                      #{payout.payout_id}
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap text-sm text-ctext-primary text-left">
                      <InfoContent
                        content={new Date(
                          payout.payout_date
                        ).toLocaleDateString()}
                      />
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap text-sm text-ctext-primary text-left hidden xl:inline-block">
                      <InfoContent content={`$${payout.total_tips}`} />
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap text-sm text-ctext-primary text-left hidden xl:inline-block">
                      <InfoContent content={`$${payout.total_adjustments}`} />
                    </td>
                    <td className="px-4 py-4 whitespace-nowrap text-sm text-ctext-primary text-left">
                      <InfoContent
                        content={`$${payout.total_payout.toFixed(2)}`}
                      />
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      ) : (
        <div></div>
      )}
    </Card>
  );
};
export default PayoutList;
