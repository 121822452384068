import { useState } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const AccessLevel = ({ access, getAdminValues }) => {
  const [active, setActive] = useState(access);

  function selectedHandler(current) {
    setActive(current);
    getAdminValues("access", current);
  }

  return (
    <>
      <span className="relative z-0 inline-flex shadow-sm rounded-md w-full">
        <button
          onClick={() => selectedHandler("account")}
          type="button"
          className={classNames(
            active === "account"
              ? "bg-cprimary-primary"
              : "bg-cbackground-dark",
            "relative inline-flex items-center px-4 py-2 rounded-l-md text-sm font-medium text-white hover:text-gray-700 w-3/12 justify-center xl:w-auto"
          )}
        >
          Account
        </button>
        <button
          onClick={() => selectedHandler("user")}
          type="button"
          className={classNames(
            active === "user" ? "bg-cprimary-primary" : "bg-cbackground-dark",
            "relative inline-flex items-center px-4 py-2 text-sm font-medium  w-3/12 justify-center xl:w-auto text-white hover:text-gray-700"
          )}
        >
          User
        </button>
        <button
          onClick={() => selectedHandler("admin")}
          type="button"
          className={classNames(
            active === "admin" ? "bg-cprimary-primary" : "bg-cbackground-dark",
            "relative inline-flex items-center px-4 py-2 text-sm font-medium  w-3/12 justify-center xl:w-auto text-white hover:text-gray-700"
          )}
        >
          Admin
        </button>
        <button
          onClick={() => selectedHandler("super")}
          type="button"
          className={classNames(
            active === "super" ? "bg-cprimary-primary" : "bg-cbackground-dark",
            "relative inline-flex items-center px-4 py-2 rounded-r-md  w-3/12 justify-center xl:w-auto text-sm font-medium text-white hover:text-gray-700"
          )}
        >
          Super
        </button>
      </span>
    </>
  );
};

export default AccessLevel;
