import { createSlice } from "@reduxjs/toolkit";

const initalUiState = {
  showOrderAddModal: false,
  showOrderDetailToaster: false,
  showPayoutToaster: false,
  activeContractors: [],
  activeNotification: "",
  userRating: "",
  userCompletedOrders: "",
  userActiveOrders: "",
  userUnpaid: "",
  userRevenue: "",
  firstName: "",
  lastName: "",
  phone: "",
  discord: "",
  email: "",
  country: "",
  paypal: "",
  access: "",
  platforms: "",
  userPaymentRequested: false,
  showFlash: false,
  flashMessage: "",
  requestedOrders: [],
  cancellationRequests: [],
  completedRequests: [],
  activeOrderDetail: null,
  profileImgs: {
    userId: {},
    userName: {},
  },
  twilioUid: "",
  progressIndicators: {
    imageUploading: false,
    membersLoading: false,
    memberLoading: false,
    profileUploading: false,
    noteUpload: false,
  },
  profileInfo: {},
};

const uiSlice = createSlice({
  name: "ui",
  initialState: initalUiState,
  reducers: {
    showOrderAddModal(state, action) {
      state.showOrderAddModal = action.payload.setShow;
    },
    showOrderDetailToaster(state, action) {
      state.showOrderDetailToaster = action.payload.setShow;
    },
    setActiveContractors(state, action) {
      state.activeContractors = action.payload.activeContractors;
    },
    showPayoutToaster(state, action) {
      state.showPayoutToaster = action.payload.setShow;
    },
    setActiveNotification(state, action) {
      state.activeNotification = action.payload.notificationId;
    },
    editUserStats(state, action) {
      for (const [k, v] of Object.entries(action.payload)) {
        state[k] = v;
      }
    },
    editPaymentRequest(state, action) {
      state.userPaymentRequested = action.payload.setRequest;
    },
    closeFlashAlert(state) {
      state.showFlash = false;
      state.flashMessage = "";
    },
    showFlashAlert(state, action) {
      (state.showFlash = true), (state.flashMessage = action.payload.message);
    },
    editRequestedOrders(state, action) {
      state.requestedOrders = action.payload.requestedOrders;
    },
    editCancellationRequests(state, action) {
      state.cancellationRequests = action.payload.cancellationRequests;
    },
    setActiveOrderDetail(state, action) {
      state.activeOrderDetail = action.payload.orderDetail;
    },
    addOrderDetailImage(state, action) {
      const existingArray = [...state.activeOrderDetail.order_imgs];
      existingArray.push(action.payload.image);
      state.activeOrderDetail.order_imgs = existingArray;
    },
    removeOrderDetailImage(state, action) {
      const existingArray = [...state.activeOrderDetail.order_imgs];
      existingArray.pop(action.payload.image);
      state.activeOrderDetail.order_imgs = existingArray;
    },
    editCompletedRequests(state, action) {
      state.completedRequests = action.payload.completedRequests;
    },
    changeProgress(state, action) {
      state.progressIndicators[action.payload.type] = action.payload.state;
    },
    updateActiveConvoParticipants(state, action) {
      state.activeOrderDetail.conversation.participants = action.payload.state;
    },
    addNoteToOrder(state, action) {
      const notes = [...state.activeOrderDetail.order_info.note];
      notes.push({
        by: action.payload.by,
        content: action.payload.content,
        date: action.payload.date,
      });
      state.activeOrderDetail.order_info.note = notes;
    },
    updateChatUserUi(state, action) {
      const existingArray = [
        ...state.activeOrderDetail.conversation.participants,
      ];
      if (action.payload.type === "add") {
        existingArray.push({
          sid: action.payload.sid,
          name: action.payload.name,
        });
        state.activeOrderDetail.conversation.participants = existingArray;
      } else if (action.payload.type === "remove") {
        const newArray = existingArray.filter(
          (item) => item.name !== action.payload.name
        );
        state.activeOrderDetail.conversation.participants = newArray;
      }
    },
    updateProfileImg(state, action) {
      const existingObject = state.profileImgs;
      existingObject.userName[action.payload.userName] = action.payload.image;
      existingObject.userId[action.payload.userId] = action.payload.image;
      state.profileImgs = existingObject;
    },
  },
});

export const uiActions = uiSlice.actions;

export default uiSlice;
