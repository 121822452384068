import React, { useState, useEffect } from 'react';
import { fetchOrderData } from '../store/orders-actions';
import OrderList from '../components/OrderList';
import NotificationsPanel from '../components/Notifications/NotificationsPanel';
import StatRow from '../components/StatRow';
import { useSelector, useDispatch } from 'react-redux';

const DashboardPage = () => {
  const dispatch = useDispatch();
  const savedOrders = useSelector((state) => state.orders.allOrders);
  const notifications = useSelector(
    (state) => state.notifications.allNotifications
  );
  const Uid = useSelector((state) => state.auth.uid);
  const [displayOrders, setDisplayOrders] = useState([]);
  const userAuth = useSelector((state) => state.auth.token);
  const displayName = useSelector((state) => state.auth.displayName);

  useEffect(() => {
    dispatch(fetchOrderData('all', displayName, userAuth));
  }, [])

  useEffect(() => {
    filterOrders();
  }, [savedOrders]);

  const filterOrders = () => {
    let updatedOrders = savedOrders.filter(
      (order) =>
        order.order_info.title !== 'TIP YOUR BOOSTER' &&
        order.order_info.title !== 'Tip'
    );

    setDisplayOrders(
      updatedOrders.filter((order) => {
        return (
          order.assigned_contractor === Uid
        );
      })
    );
  };

  return (
    <main>
      <div className="flex flex-col">
        <div className="min-h-screen">
          <div className="py-2 px-3 align-middle block min-w-full min-h-full sm:px-4 lg:px-8 sm:inline-block ">
            <div className="shadow sm:rounded-lg">
              <div className="flex m-2 p-4">
                <div className="mr-24 flex">
                  <div className="text-white self-center text-xl mr-8">
                    Dashboard
                  </div>
                </div>
              </div>
            </div>
            <div>
              <StatRow />
            </div>
            <div className="flex flex-col py-6 xl:flex-row">
              <OrderList orders={displayOrders} />
              <NotificationsPanel notifications={notifications} />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default DashboardPage;
