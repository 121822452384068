import { useState } from 'react';
import InfoContent from '../../helpers/InfoContent';
import InfoTitle from '../../InfoTitle';

const GAME_FILTERS = [
  { display: 'Destiny 2', filter: 'DESTINY 2' },
  { display: 'Call Of Duty', filter: 'CALL OF DUTY' },
  { display: 'Overwatch', filter: 'OVERWATCH' },
  { display: 'Wow - Shadow Lands', filter: 'WOW - SHADOW LANDS' },
  { display: 'Wow - Classic', filter: 'WOW - CLASSIC' },
  { display: 'Lost Ark', filter: 'LOST ARK' },
  { display: 'Diablo 2 Resurrected', filter: 'DIABLO 2 RESURRECTED' },
  { display: 'Battlefield 2042', filter: 'BATTLEFIELD 2042' },
  { display: 'Final Fantasy XIV', filter: 'FINAL FANTASY XIV' },
  { display: 'Apex Legends', filter: 'APEX LEGENDS' },
  { display: 'Warzone', filter: 'WARZONE' },
  { display: 'Wow - Burning Crusade', filter: 'WOW - BURNING CRUSADE' },
  { display: 'Dota 2', filter: 'DOTA 2' },
  { display: 'Path of Exile', filter: 'PATH OF EXILE' },
  { display: 'Valorant', filter: 'VALORANT' },
  { display: 'Rainbow Six Siege', filter: 'RAINBOW SIX SIEGE' },
  { display: 'New World', filter: 'NEW WORLD' },
  { display: 'Halo Infinite', filter: 'HALO INFINITE' },
  { display: 'Rocket League', filter: 'ROCKET LEAGUE' },
  { display: 'Wow - Season of Mastery', filter: 'WOW - SEASON OF MASTERY' },
  { display: 'League of Legends', filter: 'LEAGUE OF LEGENDS' },
  { display: 'Escape from Tarkov', filter: 'ESCAPE FROM TARKOV' },
  { display: 'Genshin Impact', filter: 'GENSHIN IMPACT' },
  { display: 'Elder Scrolls Online', filter: 'ELDER SCROLLS ONLINE' },
];

const GameFilter = ({ games, getAdminValues }) => {
  const [active, setActive] = useState(games);

  function gameHandler(games) {
    let x = [...active, games];
    const included = active.includes(games);
    if (included) {
      x = x.filter((word) => word !== games);
    }
    setActive(x);
    getAdminValues("games", x);
  }

  return (
    <fieldset className="space-y-5 mt-4 px-2 grid grid-cols-2">
      <legend>
        <InfoTitle content={'Eligible Games'} />
      </legend>
      {GAME_FILTERS.map((game) => {
        return (
          <div key={game.filter} className="relative flex items-start">
            <div className="flex items-center h-5">
              <input
                //onChange={(e) => {console.log(e.target.id)}}
                onChange={(e) => {
                  gameHandler(e.target.id);
                }}
                checked={games.includes(game.filter) ? true : false}
                id={game.filter}
                aria-describedby="comments-description"
                name="comments"
                type="checkbox"
                className="h-4 w-4 text-cprimary-primary border-gray-300 rounded"
              />
            </div>
            <div className="ml-3 text-sm">
              <InfoContent content={game.display} />
            </div>
          </div>
        );
      })}
    </fieldset>
  );
};

export default GameFilter;
