import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { addNoteToOrder } from "../../store/ui-actions";
import Button from "../Button";

const NotesTextArea = () => {
  const dispatch = useDispatch();
  const [note, setNote] = useState("");
  const { order_id } = useParams();
  const Uid = useSelector((state) => state.auth.uid);
  const displayName = useSelector((state) => state.auth.displayName);
  const userAuth = useSelector((state) => state.auth.token);
  const profileImgs = useSelector((state) => state.ui.profileImgs);
  const noteUploadStatus = useSelector(
    (state) => state.ui.progressIndicators.noteUpload
  );
  const basicOrder = useSelector(
    (state) =>
      state.orders.allOrders.filter(
        (order) => order.order_id === parseInt(order_id)
      )[0]
  );

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      submitNote();
    }
  };

  const submitNote = () => {
    setNote("");
    dispatch(
      addNoteToOrder(
        `${basicOrder.order_id}_${basicOrder.order_info.order_number}`,
        note,
        displayName,
        userAuth
      )
    );
  };

  return (
    <div className="flex items-start space-x-4">
      <div className="flex-shrink-0">
        <img
          className="h-8 w-8 rounded-full"
          src={`${
            profileImgs.userId[Uid]
              ? profileImgs.userId[Uid]
              : "https://i.pinimg.com/originals/c6/92/85/c69285419db3f7f436aca78bd4bccbcf.png"
          }`}
          alt="user avatar"
        />
      </div>
      <div className="min-w-0 flex-1">
        <div className="">
          <label htmlFor="comment" className="sr-only">
            Add your comment
          </label>
          <textarea
            rows={3}
            name="note-field"
            id="noteField"
            className="bg-cbackground-lightgray block w-full rounded-sm border-0 border-b border-transparent p-2 resize-none focus:ring-0 focus:border-cprimary-primary sm:text-sm"
            placeholder="Add your note..."
            value={note}
            onChange={(e) => setNote(e.target.value)}
            onSubmit={() => submitNote()}
            onKeyPress={handleKeyPress}
          />
        </div>
        <div className="pt-2 flex justify-between">
          <div className="flex-shrink-0">
            <Button
              text={"POST"}
              action={() => submitNote()}
              loading={noteUploadStatus}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotesTextArea;
