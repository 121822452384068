import { conversationsActions } from './conversations-slice';
import { uiActions } from './ui-slice';
import axios from '../helpers/authCatch';
import axiosConfig from '../helpers/axiosConfig';

// export const createConversation = (userAuth) => {
//   return async (dispatch) => {
//     const createConversation = async () => {
//       const config = {
//         headers: { Authorization: userAuth },
//       };

//       const response = await axios.post(
//         axiosConfig('chat/createConversation'),
//         config
//       );

//       if (response.data === 'error') {
//         throw new Error('Error creating conversation');
//       }

//       const conversationId = response;
//       return conversationId;
//     };
//     try {
//       const conversationId = await createConversation();
//       dispatch(
//         conversationsActions.setConversations({
//           conversations: [conversationId.data],
//         })
//       );
//     } catch (error) {
//       return error;
//     }
//   };
// };

// export const fetchConversation = (conversationSid, userAuth) => {
//   return async () => {
//     const fetchConversation = async () => {
//       const config = {
//         headers: { Authorization: userAuth },
//       };

//       const response = await axios.post(
//         axiosConfig('chat/fetchConversation'),
//         {
//           conversation_id: conversationSid,
//         },
//         config
//       );

//       if (response.data === 'error') {
//         throw new Error('Error fetching conversation');
//       }

//       const conversationServiceId = response.data.chatServiceSid;
//       return conversationServiceId;
//     };
//     try {
//       const resp = await fetchConversation();
//       console.log(resp);
//     } catch (error) {
//       return error;
//     }
//   };
// };

// export const fetchUserConversations = (identity, userAuth) => {
//   const fetchConversations = async () => {
//     const config = {
//       headers: { Authorization: userAuth },
//     };

//     const response = await axios.post(
//       axiosConfig('chat/fetchUserConversations'),
//       {
//         user_id: identity,
//       },
//       config
//     );

//     if (response.data === 'error') {
//       throw new Error('Error fetching conversation');
//     }

//     const JWTToken = response.data;
//     console.log(JWTToken);
//   };

//   try {
//     fetchConversations();
//   } catch (error) {
//     console.log(error);
//   }
// };

export const getJWTToken = (identity, userAuth) => {
  return async (dispatch) => {
    const getJWTToken = async () => {
      const config = {
        headers: { Authorization: userAuth },
      };

      const response = await axios.post(
        axiosConfig('chat/getToken'),
        {
          identity: identity,
        },
        config
      );

      if (response.data === 'error') {
        throw new Error('Error fetching conversation');
      }
      return response.data;
    };

    try {
      const response = await getJWTToken();
      dispatch(conversationsActions.setJWT({ JWT: response }));
    } catch (error) {
      console.log(error);
    }
  };
};

export const addChatUserToTwilio = (CId, identity, orderName, userAuth) => {
  return async (dispatch) => {
    const addUser = async () => {
      const config = {
        headers: { Authorization: userAuth },
      };

      const response = await axios.post(
        axiosConfig('chat/addChat'),
        {
          conversation_sid: CId,
          identity: identity,
          order_name: orderName
        },
        config
      );

      if (response.data === 'error') {
        throw new Error('Error fetching conversation');
      }
      return response;
    };
    try {
      const resp = await addUser();
      dispatch(
        uiActions.updateChatUserUi({
          type: 'add',
          sid: resp.data.sid,
          name: resp.data.identity,
        })
      );
    } catch (error) {
      return error;
    }
  };
};

export const addSMSUserToTwilio = (
  CId,
  clientMobileNumber,
  proxyNumber,
  userAuth
) => {
  return async (dispatch) => {
    const addUser = async () => {
      const config = {
        headers: { Authorization: userAuth },
      };

      const response = await axios.post(
        axiosConfig('chat/addSMS'),
        {
          conversation_id: CId,
          mobile_number: clientMobileNumber,
          proxy_number: proxyNumber,
        },
        config
      );

      if (response.data === 'error') {
        throw new Error('Error fetching conversation');
      }

      return response;
    };
    try {
      const resp = await addUser();
      dispatch(
        uiActions.updateChatUserUi({
          type: 'add',
          sid: resp.data.sid,
          name: resp.data.identity,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
};

export const removeUserFromTwilio = (CId, participant, name, docId, userName, userAuth) => {
  return async (dispatch) => {
    const removeUser = async () => {
      var data = JSON.stringify({
        conversation_id: CId,
        participant: participant,
        doc_id: docId,
        user_name: userName
      });

      var config = {
        method: 'delete',
        url: axiosConfig('chat/removeUser'),
        headers: {
          Authorization: userAuth,
          'Content-Type': 'application/json',
        },
        data: data,
      };

      const response = await axios(config);

      if (response.data === 'error') {
        throw new Error('Error fetching conversation');
      }
      return response;
    };

    try {
      await removeUser();
      dispatch(
        uiActions.updateChatUserUi({
          type: 'remove',
          sid: participant,
          name: name,
        })
      );
    } catch (error) {
      return error;
    }
  };
};

export const deleteTwilioConversation = (
  conversationId,
  orderDocId,
  userAuth
) => {
  return async (dispatch) => {
    const deleteConvo = async () => {
      var data = JSON.stringify({
        conversation_id: conversationId,
        order_doc_id: orderDocId,
      });

      var config = {
        method: 'delete',
        url: axiosConfig('chat/deleteConversation'),
        headers: {
          Authorization: userAuth,
          'Content-Type': 'application/json',
        },
        data: data,
      };

      const response = await axios(config);
      if (response.data === 'error') {
        throw new Error('Error fetching conversation');
      }
      return response;
    };
    try {
      await deleteConvo();
      dispatch(
        conversationsActions.removeConversation({
          conversation: conversationId,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
};
