import React, { useState } from 'react';
import axios from '../helpers/authCatch';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { authActions } from '../store/auth-slice';
import playerboost_logo from '../assets/images/playerboost_logo.png';
import axiosConfig from '../helpers/axiosConfig';
import { fetchNotificationData } from '../store/notifications-actions';
import { getUserDetail } from '../store/ui-actions';
import ProgressIndicator from '../components/helpers/ProgressIndicator';
import playerboost_bg from "../assets/images/PB_BG3.jpg";

const bgImgStyle = {
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundImage: `url(${playerboost_bg})`
}

export const LoginPage = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [loaderSpinner, setLoaderSpinner] = useState(false);

  let history = useHistory();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const userData = {
      email: email,
      password: password,
    };
    setAlertMessage('Getting your account info...');
    setLoaderSpinner(true);

    try {
      const response = await axios.post(axiosConfig('login'), userData);

      const config = {
        headers: { Authorization: `Bearer ${response.data.token}` },
      };

      const claim = await axios.get(axiosConfig('claim'), config);

      dispatch(
        authActions.setInitialAuthData({
          role: claim.data,
          token: `Bearer ${response.data.token}`,
          uid: response.data.uid,
          displayName: response.data.displayName,
        })
      );

      if (claim.data === 'account') {
        history.push('/verify');
      } else {
              await fetchData(`Bearer ${response.data.token}`);
              setAlertMessage('Success!');
        
              setTimeout(() => {
                  history.push('/dashboard');
              }, 1000);
      }
    } catch (error) {
      const errCode = error.response.data.type;

      if (error.response.status >= 400) {
        setEmail('');
        setPassword('');
        if (errCode === 'INVALID_PASSWORD')
          setAlertMessage('Incorrect password, try again');
        else if (errCode === 'INVALID_EMAIL')
          setAlertMessage('Incorrect email, try again');
        else if (errCode === 'USER_DISABLED')
          setAlertMessage(
            'Your account has been disabled. \nPlease reach out to support if you think this is incorrect.'
          );
        else if (errCode === 'USER_NOT_FOUND')
          setAlertMessage('No user was found matching that email address.');
        else if (errCode === 'PASSWORD_EMPTY')
          setAlertMessage('Please enter a password.');
        else if (errCode === 'EMAIL_EMPTY')
          setAlertMessage('Please enter an email.');
        else {
          setAlertMessage('Something went wrong - please try again...');
        }
        setTimeout(() => {
          setLoaderSpinner(false);
        }, 2000);
      }
      resetInputs();
    }
  };

  const fetchData = async (userAuth) => {
      dispatch(fetchNotificationData(userAuth)),
      dispatch(getUserDetail(userAuth));
  };

  const handleChange = (event, inputType) => {
    if (inputType === 'password') {
      setPassword(event.target.value);
    } else {
      setEmail(event.target.value);
    }
  };

  const resetInputs = () => {
    setEmail('');
    setPassword('');
  };

  return (
    <>
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-cbackground-primary" style={ bgImgStyle }>
        <div className="p-4 max-w-md w-full space-y-8 shadow overflow-hidden sm:rounded-md bg-cbackground-cover">
          <div>
            <img
              className="mx-auto h-12 w-auto"
              src={playerboost_logo}
              alt="Workflow"
            />
          </div>
          <form className="mt-8 space-y-6">
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Email address"
                  value={email}
                  onChange={(event) => handleChange(event, 'email')}
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Password"
                  value={password}
                  onChange={(event) => handleChange(event, 'password')}
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="text-md">
                <a href="/" className="font-medium text-cprimary-primary">
                  Forgot your password?
                </a>
              </div>
              <div className="text-md">
                <a href="/signup" className="font-medium text-cprimary-primary">
                  Sign Up
                </a>
              </div>
            </div>

            <div>
              {loaderSpinner ? (
                <div className="flex-auto items-center flex text-cprimary-primary transition ease-in-out delay-15">
                  <div className="px-6">
                    <ProgressIndicator type={'dots'}/>
                  </div>
                  {alertMessage}
                </div>
              ) : (
                <button
                  type="submit"
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium text-white bg-cprimary-primary hover:bg-cprimary-dark"
                  onClick={(event) => {
                    handleSubmit(event);
                  }}
                >
                  <span className="absolute left-0 inset-y-0 flex items-center pl-3"></span>
                  SIGN IN
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
