import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  ZoomInIcon,
  PlusCircleIcon,
  DocumentRemoveIcon,
} from '@heroicons/react/solid';
import ImageUpload from '../../components/ImageUpload';
import { removeOrderImage } from '../../store/orders-actions';
import ProgressIndicator from '../../components/helpers/ProgressIndicator';
import InfoTitle from '../../components/InfoTitle';
import InfoContent from '../../components/helpers/InfoContent';

const OrderDetails = () => {
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.auth.token);
  const [showImageUpload, setShowImageUpload] = useState(false);
  const order = useSelector((state) => state.ui.activeOrderDetail);
  const imageProgress = useSelector((state) => state.progress.imageUploading);
  return (
    <section aria-labelledby="applicant-information-title">
      <div className="shadow overflow-hidden sm:rounded-lg bg-cbackground-primary">
        <div className="flex items-center px-6 py-4 text-white font-light text-lg">
          <h2 className="">Order Details</h2>
        </div>
        <div className="border-t border-cborder px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <InfoTitle content="TITLE" />
              <div className="flex items-center">
                <InfoContent content={order.order_info.title} />
                {order.order_info.quantity > 1 ? (
                  <span className="ml-2 inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium from-green-700 to-green-300 bg-gradient-to-b text-gray-800">
                    {'X' + order.order_info.quantity}
                  </span>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="sm:col-span-1">
              <InfoTitle content="GAME" />
              <InfoContent content={order.order_info.game} />
            </div>
            <div className="sm:col-span-1">
              <InfoTitle content="VARIANT TITLE" />
              <InfoContent content={order.order_info.variant_title} />
            </div>
            {order.conversation.phone ? (
              ''
            ) : (
              <div className="sm:col-span-1">
                <InfoTitle content="EMAIL" />
                <InfoContent
                  content={
                    order.conversation.email ||
                    'contact support for client contact info'
                  }
                />
              </div>
            )}
            {Object.entries(order.properties).map(([k, v]) => {
              return (
                <div key={Math.random() * 100000} className="sm:col-span-1">
                  <InfoTitle content={k} />
                  <InfoContent content={v} />
                </div>
              );
            })}
            <div className="sm:col-span-2">
              <div className="flex items-center">
                <InfoTitle content="ORDER PHOTOS" />
                {imageProgress ? (
                  <div className="flex-auto items-center flex text-cprimary-primary transition ease-in-out delay-15">
                    <div className="px-6">
                      <ProgressIndicator />
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {showImageUpload || imageProgress ? (
                  ''
                ) : (
                  <button type="file" value>
                    <PlusCircleIcon
                      className="flex-shrink-0 h-6 ml-2 w-6 text-cprimary-primary hover:text-cprimary-light"
                      aria-hidden="true"
                      onClick={() => {
                        setShowImageUpload(true);
                      }}
                    />
                  </button>
                )}
                {showImageUpload ? (
                  <ImageUpload
                    orderId={`${order.order_id}_${order.order_info.order_number}`}
                    setClose={() => setShowImageUpload(false)}
                    order={order.order_id}
                  />
                ) : (
                  ''
                )}
              </div>
              <div className="mt-1 text-sm text-gray-900">
                {order.order_imgs.length > 0 ? (
                  <ul
                    role="list"
                    className="border border-cborder rounded-md divide-y divide-gray-200"
                  >
                    {order.order_imgs.map((img, index) => (
                      <>
                        <li
                          key={Math.random() * 1000}
                          className="pl-3 pr-4 py-3 flex items-center justify-between text-sm hover:bg-cbackground-light"
                        >
                          <a
                            onClick={() => window.open(img, '_blank')}
                            className="flex flex-auto items-center w-100"
                          >
                            <ZoomInIcon
                              className="flex-shrink-0 h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                            <div className="ml-4 mr-auto">
                              <InfoContent
                                content={`Image ${parseInt(index) + 1}`}
                              />
                            </div>
                            <div className="font-medium pr-4 text-cprimary-primary hover:text-cprimary-dark">
                              View
                            </div>
                          </a>
                          <button
                            onClick={() => {
                              dispatch(
                                removeOrderImage(img, order.order_id, userAuth)
                              );
                            }}
                          >
                            <DocumentRemoveIcon
                              className="flex-shrink-0 h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </button>
                        </li>
                      </>
                    ))}
                  </ul>
                ) : (
                  <InfoContent content="NO ORDER PHOTOS YET" />
                )}
              </div>
            </div>
          </dl>
        </div>
      </div>
    </section>
  );
};

export default OrderDetails;
