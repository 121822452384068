import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../Button';
import {
  approveOrderCompleteRequest,
  denyOrderCompleteRequest,
} from '../../store/notifications-actions';
import { uiActions } from '../../store/ui-slice';
import { ArrowLeftIcon } from '@heroicons/react/solid';

const ApprovalPrompt = ({ closePrompt }) => {
  const dispatch = useDispatch();
  const [paymentAdjustment, setPaymentAdjustment] = useState(0);
  const [approvalMessage, setApprovalMessage] = useState('');
  const order = useSelector((state) => state.ui.activeOrderDetail);
  const userAuth = useSelector((state) => state.auth.token);
  const userRole = useSelector((state) => state.auth.role);

  const approveClick = () => {
    dispatch(
      approveOrderCompleteRequest(
        paymentAdjustment,
        approvalMessage,
        order.order_id,
        order.assigned_contractor,
        order.conversation.conversation_id,
        `${order.order_id}_${order.order_info.order_number}`,
        order.conversation.assigned_number,
        userAuth,
        order.order_info.shop_order
      )
    );

    setTimeout(() => {
      closePrompt();
      setApprovalMessage('');
      setPaymentAdjustment(0);
    }, 1000);
  };

  const rejectClick = () => {
    dispatch(
      denyOrderCompleteRequest(
        order.assigned_contractor,
        order.order_id,
        userAuth
      )
    );
    setTimeout(() => {
      closePrompt();
    }, 500);
    setTimeout(() => {
      dispatch(uiActions.closeFlashAlert());
    }, 2000);
  };

  return (
    <div className="px-6 pb-6">
      <div className="mb-4">
        <div className="text-sm text-ctext-primary font-semibold uppercase mb-2">
          If you&apos;re ready to approve this order click ✅ APPROVE below.
          Clicking ❌ REJECT moves the order back to &apos;in-progress&apos;
        </div>
        <div className="flex items-center">
          <ArrowLeftIcon
            className="h-6 w-6 text-ctext-primary mr-4 flex-none"
            aria-hidden="true"
            onClick={closePrompt}
          />
          <div className="mr-4">
            <Button text={'✅ APPROVE'} action={approveClick} style="primary" />
          </div>
          <Button text={'❌ REJECT'} action={rejectClick} style="primary" />
        </div>
      </div>
      {userRole === 'super' ? (
        <div className="mb-4">
          <label
            htmlFor="price"
            className="text-sm text-ctext-primary font-semibold uppercase"
          >
            Payment Adjustment
          </label>
          <div className="mt-1 relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <span className="text-gray-500 sm:text-sm">$</span>
            </div>
            <input
              type="text"
              name="price"
              id="price"
              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
              placeholder="0.00"
              aria-describedby="price-currency"
              onChange={(e) => {
                setPaymentAdjustment(e.target.value);
              }}
            />
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <span className="text-gray-500 sm:text-sm" id="price-currency">
                USD
              </span>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      <div>
        <label
          htmlFor="email"
          className="text-sm text-ctext-primary font-semibold uppercase"
        >
          Approval Note
        </label>
        <div className="mt-1 ">
          <input
            type="email"
            name="email"
            id="email"
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="Leave an optional message here"
            onChange={(e) => {
              setApprovalMessage(e.target.value);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ApprovalPrompt;
