// Page transition loading element

import playerboost_only_logo from "../../assets/images/playerboost_only_logo.png";

const ShopifyLoader = () => {
  return (
    <div className="body-preloader">
      <div className="foxic-body-loader">
      <img src={playerboost_only_logo} className="body-loader-image" alt="Loader"></img>
        <div className="preloader-circle-1">
          <div className="preloader-circle-2">
            <div className="preloader-circle-3"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopifyLoader;
