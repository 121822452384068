import React from "react";

export const PayoutDetailsItem = ({ order }) => {
  return (
    <ul role="list cursor-none">
      <li className="relative bg-cbackground-dark rounded-sm py-3 px-2 mb-3">
        <div className="flex justify-between space-x-3">
          <div className="min-w-0 flex-1">
            <a className="block focus:outline-none">
              <span className="absolute inset-0" aria-hidden="true" />
              <p className="text-sm font-medium text-ctext-accent hover:text-blue-300 truncate mb-2">
                {order.order_name}
              </p>
              <p className="text-sm font-medium text-ctext-accent hover:text-blue-300 truncate mb-2">
                {order.order_number}
              </p>
              <p className="text-sm text-white truncate">
                Total ${order.total.toFixed(2)}
              </p>
            </a>
          </div>
          <time
            dateTime={new Date(order.approval_date).toLocaleDateString()}
            className="flex-shrink-0 whitespace-nowrap text-xs text-ctext-primary"
          >
            {new Date(order.approval_date).toLocaleDateString()}
          </time>
        </div>
        <div className="mt-1">
          <p className="line-clamp-2 text-sm text-ctext-primary">
            {order.admin_notes}
          </p>
        </div>
      </li>
    </ul>
  );
};
