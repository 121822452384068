import { createSlice } from '@reduxjs/toolkit';

const initalConversationState = {
  conversations: [],
  conversationServiceId: [],
  JWT: 'nonefined',
};

const conversationsSlice = createSlice({
  name: 'conversations',
  initialState: initalConversationState,
  reducers: {
    addConversation(state, action) {
      const currentConversations = state.conversations;
      let matched = false;

      currentConversations.forEach((convo) => {
        if (convo.sid === action.payload.conversation.sid) {
          matched = true;
        }
      });

      if (!matched) {
        currentConversations.push(action.payload.conversation);
        state.conversations = currentConversations;
      }
    },
    removeConversation(state, action) {
      const currentConversations = state.conversations;

      const newList = currentConversations.filter(
        (convo) => convo.sid !== action.payload.conversation.sid
      );
      state.conversations = newList;
    },
    setConversationServiceId(state, action) {
      state.conversationServiceId = action.payload.conversationServiceId;
    },
    setJWT(state, action) {
      state.JWT = action.payload.JWT;
    },
  },
});

export const conversationsActions = conversationsSlice.actions;
export default conversationsSlice;
