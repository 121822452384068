import NotificationsPanelContent from "./NotificationsPanelContent";
import Card from "../helpers/Card";

const NotificationsPanel = ({ notifications }) => {
  const sortedNotifications = [...notifications].sort((a, b) => {
    return new Date(a.created_at) - new Date(b.created_at);
  });

  return (
    <div className="flex-auto flex-col mx-2 ">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <Card
            details={{
              heading: "Notifications",
            }}
          >
            <table className="my-2 min-w-full">
              <tbody>
                {<NotificationsPanelContent content={sortedNotifications} />}
              </tbody>
            </table>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default NotificationsPanel;