import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import OrderCard from '../components/OrderCard';
import OrderInfoSlideOut from '../components/OrderInfoSlideOut';
import Dropdown from '../components/Dropdown';
import { useDispatch } from 'react-redux';
import { uiActions } from '../store/ui-slice';
import { getContractors } from '../store/ui-actions';
import { fetchOrderData } from '../store/orders-actions';
import NavPill from '../components/NavPill';
import { RefreshIcon } from '@heroicons/react/outline';

const OrdersPage = () => {
  const dispatch = useDispatch();
  const allOrders = useSelector((state) => state.orders.allOrders);
  const userId = useSelector((state) => state.auth.uid);
  const displayName = useSelector((state) => state.auth.displayName);
  const userAuth = useSelector((state) => state.auth.token);
  const userRole = useSelector((state) => state.auth.role);
  const showOrderDetailToaster = useSelector(
    (state) => state.ui.showOrderDetailToaster
  );
  const nameMap = useSelector((state) => state.ui.profileImgs.userIdNameMap);
  const [allDisplayOrders, setAllDisplayOrders] = useState([]);
  const [userFilter, setUserFilter] = useState('All');
  const [systemFilter, setSystemFilter] = useState('AllSystems');
  const [selectedContractor, setSelectedContractor] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [gameFilter, setGameFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [activeOrder, setActiveOrder] = useState();
  const [orderUpdating, setOrderUpdating] = useState(false);
  let orderKey = 0;

  const tabs = [
    { name: 'Available', id: 'All', current: true },
    { name: 'My Orders', id: 'MyOrders', current: true },
  ];

  const contractorSelect = [{ name: 'All', id: 'all' }];

  const filteredOrders = allOrders.filter(
    (order) => order.assigned_contractor !== null
  );
  filteredOrders.forEach((order) => {
    if (
      contractorSelect.filter(
        (contractor) => contractor.id === order.assigned_contractor
      ).length === 0
    ) {
      contractorSelect.push({
        name: nameMap[order.assigned_contractor],
        id: order.assigned_contractor,
      });
    }
  });

  const changePillCurrent = (selection) => {
    console.log(selection);
    if (selection === 'AssignedOrders') {
      setStatusFilter('claimed');
      setSelectedContractor('all');
    } else if (selection === 'MyOrders') {
      setSelectedContractor('');
      setStatusFilter('claimed');
    } else {
      setStatusFilter('');
      setSelectedContractor('');
    }
    setUserFilter(selection);
  };

  if (userRole === 'admin' || userRole === 'super') {
    tabs.push(
      {
        name: 'Assigned',
        id: 'AssignedOrders',
        current: false,
      },
      {
        name: 'Archived',
        id: 'ArchivedOrders',
        current: false,
      }
    );
  }

  const systemSelects = [
    { id: 'AllSystems', name: 'All' },
    { id: 'Xbox', name: 'Xbox' },
    { id: 'PC', name: 'PC' },
    { id: 'PlayStation', name: 'PlayStation' },
  ];

  const gameArray = [{ id: '', name: 'All' }];
  const statusArray = [{ id: '', name: 'All' }];

  let statusStrings = [''];
  let gameStrings = [''];

  allOrders.forEach((order) => {
    const game = order.order_info['game']
      ? order.order_info['game'].toUpperCase() || ''
      : '';
    if (gameStrings.indexOf(game) === -1) {
      gameArray.push({ id: game, name: game });
      gameStrings.push(game);
    }

    const status = order.order_status;
    if (status && statusStrings.indexOf(status) === -1 && status !== 'paid') {
      statusArray.push({ id: status, name: status.toUpperCase() || '' });
      statusStrings.push(status);
    }
  });

  const showSlideOut = (order) => {
    setActiveOrder(order);
    dispatch(
      uiActions.showOrderDetailToaster({
        setShow: true,
      })
    );
  };

  useEffect(() => {
    const FILTER_MAP = {
      All: (order) =>
        order.assigned_contractor !== userId &&
        order.order_status === 'unclaimed',
      //&& order.conversation.sid,
      AllSystems: (order) => order,
      MyOrders: (order) =>
        order.assigned_contractor === userId &&
        order.order_status !== 'archived',
      ArchivedOrders: (order) => order.order_status === 'archived',
      SpecificContractor: (order) =>
        order.assigned_contractor === selectedContractor,
      AssignedOrders: (order) => {
        if (userRole === 'super') {
          return order;
        } else if (order.order_status !== 'approved') {
          return order;
        }
      },
      Xbox: (order) => order.properties['PLATFORM'] === 'XBOX',
      PlayStation: (order) => order.properties['PLATFORM'] === 'PLAYSTATION',
      PC: (order) => order.properties['PLATFORM'] === 'PC',
      GameFilter: (order) => order.order_info.game === gameFilter,
      StatusFilter: (order) => order.order_status === statusFilter,
      AllAssigned: (order) =>
        order.assigned_contractor !== null && order.order_status !== 'archived',
    };

    const applyFilters = () => {
      let updatedOrders = allOrders.filter(
        (order) =>
          order.order_info.title !== 'TIP YOUR BOOSTER' &&
          order.order_info.title !== 'Tip' &&
          order.order_status !== 'paid'
      );

      if (userFilter === 'All') {
        updatedOrders = updatedOrders.filter(FILTER_MAP['All']);
      } else if (userFilter === 'MyOrders') {
        updatedOrders = updatedOrders.filter(FILTER_MAP['MyOrders']);
      } else if (userFilter === 'AssignedOrders') {
        updatedOrders = updatedOrders.filter(FILTER_MAP['AssignedOrders']);
      } else if (userFilter === 'ArchivedOrders') {
        updatedOrders = updatedOrders.filter(
          (order) => order.order_status === 'archived'
        );
      }

      if (systemFilter !== '') {
        updatedOrders = updatedOrders.filter(FILTER_MAP[systemFilter]);
      }

      if (gameFilter !== '') {
        updatedOrders = updatedOrders.filter(FILTER_MAP['GameFilter']);
      }

      if (statusFilter !== '') {
        updatedOrders = updatedOrders.filter(FILTER_MAP['StatusFilter']);
      }

      if (selectedContractor === 'all') {
        updatedOrders = updatedOrders.filter(FILTER_MAP['AllAssigned']);
      } else if (selectedContractor !== '') {
        updatedOrders = updatedOrders.filter(FILTER_MAP['SpecificContractor']);
      }

      updatedOrders = updatedOrders.sort((a, b) => {
        let aComp = a.order_id;
        let bComp = b.order_id;
        return aComp - bComp;
      });

      let vipUpdatedSort = updatedOrders.sort((a) => {
        if (['VIP', 'VIP ( FAST PASS )'].includes(a.properties['QUEUE TIME'])) {
          return -1;
        } else {
          return 1;
        }
      });

      setAllDisplayOrders(vipUpdatedSort);
    };

    applyFilters();
  }, [
    userFilter,
    systemFilter,
    selectedContractor,
    allOrders,
    userId,
    pageNumber,
    gameFilter,
    statusFilter,
  ]);

  useEffect(() => {
    setPageNumber(1);
  }, [userFilter, systemFilter]);

  useEffect(() => {
    if (userRole === 'super') {
      dispatch(getContractors(userAuth));
    }
    dispatch(fetchOrderData('all', displayName, userAuth));
  }, [dispatch]);

  const refreshOrderData = () => {
    setOrderUpdating(true);
    dispatch(fetchOrderData('all', displayName, userAuth)).then(() =>
      setOrderUpdating(false)
    );
  };

  const acceptingOrders = () => {
    if (userRole === 'user') {
      const orderLength = allOrders.filter((order) => {
        if (
          order.assigned_contractor === userId &&
          order.order_status === 'claimed'
        ) {
          return true;
        }
      }).length;
      if (orderLength >= 2) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  return (
    <>
      <div className="flex flex-col">
        <div className="min-h-screen">
          {showOrderDetailToaster && activeOrder ? (
            <OrderInfoSlideOut
              order={activeOrder}
              acceptingOrders={acceptingOrders()}
            />
          ) : (
            ''
          )}
          <div className="py-2 align-middle inline-block w-full">
            <div className="flex m-2 p-4 items-center">
              <div className="mr-24">
                <div className="flex">
                  <div className="text-white self-center text-xl">Orders</div>
                </div>
                <div className="text-ctext-primary font-light">
                  Be professional at a place of work.
                </div>
              </div>
              <div>
                <NavPill
                  tabs={tabs}
                  onClick={changePillCurrent}
                  current={userFilter}
                />
              </div>
              <div
                onClick={() => refreshOrderData()}
                className={
                  'ml-8 rounded-full p-2 bg-cbackground-primary shadow-lg'
                }
              >
                <RefreshIcon
                  className={`flex-shrink-0 h-6 w-6 text-ctext-primary ${
                    orderUpdating && 'text-cprimary-primary animate-spin'
                  }`}
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row md:m-2 p-4">
              <div className="w-full md:w-1/3 lg:w-36">
                <Dropdown
                  selects={systemSelects}
                  label="System"
                  onSelect={(choice) => {
                    setSystemFilter(choice);
                  }}
                />
              </div>
              <div className="w-full my-4 md:w-1/3 md:ml-4 md:my-0 lg:w-36">
                <Dropdown
                  selects={gameArray}
                  label="Game"
                  onSelect={(choice) => {
                    setGameFilter(choice);
                  }}
                />
              </div>
              {userFilter !== 'All' && userFilter !== 'ArchivedOrders' && (
                <div className="w-full md:w-1/3 md:ml-4 md:my-0  lg:w-36">
                  <Dropdown
                    selects={statusArray}
                    setInitial={{ name: 'CLAIMED', id: 'claimed' }}
                    label="Status"
                    onSelect={(choice) => {
                      setStatusFilter(choice);
                    }}
                  />
                </div>
              )}
              {['admin', 'super'].includes(userRole) &&
                userFilter === 'AssignedOrders' && (
                  <div className="w-full md:w-1/3 md:ml-4 md:my-0  lg:w-36">
                    <Dropdown
                      selects={contractorSelect}
                      label="Contractor"
                      onSelect={(choice) => {
                        setSelectedContractor(choice);
                      }}
                    />
                  </div>
                )}
            </div>
            <div className="mb-16 sm:mb-0 md:flex md:justify-around md:flex-wrap lg:flex-unset xl:flex-col">
              {allDisplayOrders.length > 0 ? (
                <>
                  {allDisplayOrders.map(
                    (order) => (
                      orderKey++,
                      (
                        <OrderCard
                          ordersLength={allDisplayOrders.length}
                          order={order}
                          key={orderKey}
                          index={orderKey}
                          onDetails={showSlideOut}
                          filterType={userFilter}
                        />
                      )
                    )
                  )}
                </>
              ) : (
                <div className="text-ctext-primary p-4 w-full text-center">
                  No orders available - hang tight
                </div>
              )}
              <div className="h-24" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrdersPage;
