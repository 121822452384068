import React from 'react';
import Badge from '../components/Badges';
import { Link } from 'react-router-dom';
import Card from '../components/helpers/Card';

const OrderList = ({ orders }) => {
  return (
    <div className="flex-auto flex-col mx-2 mb-4 sm:mb-0 md:mb-4 xl:mb-0">
      <Card
        details={{
          heading: 'Recent Orders',
        }}
      >
        {orders.length > 0 ? (
          <table className="min-w-full">
            <thead className="bg-cbackground-primary border-b border-1 border-cbackground-light">
              <tr className="whitespace-nowrap">
                <th
                  scope="col"
                  className="px-6 py-3 text-left font-light text-ctext-primary tracking-wider"
                >
                  Invoice
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left font-light text-ctext-primary tracking-wider hidden lg:table-cell	"
                >
                  Order Type
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left font-light text-ctext-primary tracking-wider hidden lg:table-cell	"
                >
                  Accepted
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left font-light text-ctext-primary tracking-wider"
                >
                  Payout
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left font-light text-ctext-primary tracking-wider"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left font-light text-ctext-primary tracking-wider hidden lg:table-cell	"
                >
                  Completed
                </th>
              </tr>
            </thead>
            <tbody className="bg-cbackground-primary">
              {orders.map((order) => (
                <tr key={Math.random() * 1000} className="lg:align-baseline">
                  <td>
                    <Link to={`/order_detail/${order.order_id}`}>
                      <div className="ml-4">
                        <div className="text-sm font-medium text-ctext-accent ml-2">
                          {`#${order.order_info.order_number}`}
                        </div>
                      </div>
                    </Link>
                  </td>
                  <td className="hidden lg:table-cell		">
                    <div className="ml-4">
                      <div className="text-sm text-ctext-primary ml-2">
                        {`#${order.order_info.order_number}${
                          order.order_info.index > 0
                            ? '-' + order.order_info.index++
                            : ''
                        }`}
                      </div>
                    </div>
                  </td>
                  <td className="hidden lg:table-cell		">
                    <div className="ml-4">
                      <div className="text-sm text-ctext-primary ml-2">
                        {order.order_info.claimed_date
                          ? new Date(
                              order.order_info.claimed_date
                            ).toLocaleDateString()
                          : ''}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="ml-4">
                      <div className="text-sm text-ctext-primary ml-2">
                        {order.order_info.price.toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        })}
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 mr-2 whitespace-nowrap">
                    <Badge type={order.order_status} />
                  </td>
                  <td className="hidden lg:table-cell">
                    <div className="ml-4">
                      <div className="text-sm text-gray-500 ml-2">
                        {order.order_info.completed_date
                          ? new Date(
                              order.order_info.completed_date
                            ).toLocaleDateString()
                          : ''}
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="px-4 py-2 text-center text-sm text-white border-top border-color-white ">
            No current orders...
          </div>
        )}
      </Card>
    </div>
  );
};

export default OrderList;
