const NavPill = ({ tabs, current, onClick }) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  function onChangeFilter(e) {
    const currentValue = document.getElementById("tabs").value;
    const children = e.target.children;
    for (let i = 0; i < children.length; i++) {
      if (children[i].value === currentValue) {
        window.scrollTo({ top: 0, behavior: "smooth" });
        return onClick(children[i].id);
      }
    }
  }

  return (
    <div>
      <div className="sm:hidden w-full fixed bottom-0 right-0 left-0">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          onChange={onChangeFilter}
          id="tabs"
          name="tabs"
          className="block w-full border-none focus:border-none p-4 bg-cbackground-light text-white text-sm parent"
          defaultValue={tabs.find((tab) => tab.current).name}
        >
          {tabs.map((tab) => (
            <option key={tab.name} id={tab.id}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block background-cbackground-dark lg:w-navPill">
        <nav
          className="relative z-0 rounded-lg shadow flex overflow-hidden"
          aria-label="Tabs"
        >
          {tabs.map((tab) => (
            <a
              onClick={() => {
                onClick(tab.id);
              }}
              key={tab.name}
              className={classNames(
                tab.id === current
                  ? "text-white"
                  : "text-ctext-active hover:text-gray-700",
                "group relative min-w-0 flex-1 overflow-hidden bg-cbackground-lightgray py-2 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
              )}
              aria-current={tab.current ? "page" : undefined}
            >
              <span className="relative z-40">{tab.name}</span>
              <span
                aria-hidden="true"
                className={classNames(
                  tab.id === current ? "bg-cprimary-primary" : "bg-cbackground-primary",
                  "absolute inset-x-0 bottom-0 h-full"
                )}
              />
            </a>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default NavPill;
