import ProgressIndicator from "./helpers/ProgressIndicator";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Button = ({ text, action, style, disabled = false, loading }) => {
  let classes;

  if (disabled) {
    switch (style) {
      case "outline":
        classes =
          "rounded-md inline-flex items-center justify-center px-4 py-2 mr-4 border border-gray-300 shadow-sm text-sm font-medium text-gray-900 bg-gray-100 opacity-50";
        break;
      case "primary":
        classes =
          "rounded-md inline-flex items-center justify-center px-4 py-2 mr-4 border border-transparent text-sm font-medium shadow-sm text-white bg-csecondary-light";
        break;
      case "secondary":
        classes =
          "rounded-md inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium shadow-sm text-white bg-cprimary-light";
        break;
      default:
        classes =
          "rounded-md inline-flex items-center justify-center px-4 py-2 mr-4 border border-transparent text-sm font-medium shadow-sm text-white bg-csecondary-light";
    }

    return (
      <button type="button" className={classNames(classes)} disabled>
        {loading ? <ProgressIndicator type={"tailSpin"} /> : text}
      </button>
    );
  } else {
    switch (style) {
      case "outline":
        classes =
          "rounded-md inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium text-white bg-transparent hover:bg-gray-50 hover:text-cprimary-primary";
        break;
      case "primary":
        classes =
          "rounded-md inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium shadow-sm text-white bg-csecondary-primary";
        break;
      case "secondary":
        classes =
          "rounded-md inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium shadow-sm text-white bg-cprimary-primary";
        break;
      default:
        classes =
          "rounded-md inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium shadow-sm text-white bg-csecondary-primary";
    }
    return (
      <button onClick={action} type="button" className={classNames(classes)}>
        {loading ? <ProgressIndicator type={"tailSpin"} /> : text}
      </button>
    );
  }
};

export default Button;
