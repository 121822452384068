import { createSlice } from '@reduxjs/toolkit';

const initialAuthState = { isAuthenticated: false, role: '', token: false, uid: '', displayName: '' };

const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    login(state) {
      state.isAuthenticated = true;
    },
    logout(state) {
      state.isAuthenticated = false;
    },
    setRole(state, action) {
      state.role = action.payload.role;
    },
    setToken(state, action) {
      state.token = action.payload.token;
    },
    setUid(state, action) {
      state.uid = action.payload.uid
    },
    setDisplayName(state, action) {
      state.displayName = action.payload.displayName
    },
    setInitialAuthData(state, action) {
      const {role, token, uid, displayName} = action.payload;

      state.role = role,
      state.token = token,
      state.uid = uid, 
      state.displayName = displayName
      state.isAuthenticated = true
    }
  },
});

export const authActions = authSlice.actions;

export default authSlice;