import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchNotificationData } from "../store/notifications-actions";
import NotificationCard from "../components/Notifications/NotificationCard";
import PayoutInfoSlideout from "../components/PayoutInfoSlideout";

const NotificationsPage = () => {
  const dispatch = useDispatch();
  const allNotifications = useSelector(
    (state) => state.notifications.allNotifications
  );
  const userAuth = useSelector((state) => state.auth.token);
  const showPaymentToaster = useSelector((state) => state.ui.showPayoutToaster);
  const notificationId = useSelector((state) => state.ui.activeNotification);

  useEffect(() => {
    //GB: Only reload data on inital page load here.
    dispatch(fetchNotificationData(userAuth));
    console.log(allNotifications);
  }, [dispatch]);

  useEffect(() => {
    console.log("running rerender");
  }, [allNotifications]);

  return (
    <>
      <div className="flex flex-col">
        <div className="min-h-screen">
          {showPaymentToaster ? (
            <PayoutInfoSlideout notificationId={notificationId} />
          ) : (
            ""
          )}
          <div className="py-2 align-middle inline-block min-w-full min-h-full">
            <div className="shadow sm:rounded-lg">
              <div className="text-white self-center text-xl flex m-2 p-4">
                Notifications
              </div>
              {allNotifications.length > 0 &&
                allNotifications.map((notification) => {
                  return (
                    <NotificationCard
                      key={Math.random() * 100000}
                      notification={notification}
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationsPage;
