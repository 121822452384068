import React, { useState, useRef } from "react";
import { updateProfileImg } from "../../store/ui-actions";
import { useDispatch, useSelector } from "react-redux";
import Button from "../Button";

const ProfileImageUpload = ({ setClose }) => {
  const [name, setName] = useState("");
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.auth.token);
  const username = useSelector((state) => state.auth.displayName);
  const userId = useSelector((state) => state.auth.uid);
  const loading = useSelector(
    (state) => state.ui.progressIndicators.imageUploading
  );

  const handleChange = (e) => {
    setName(e.target.value);
  };
  const formSubmit = (e) => {
    e.preventDefault();

    const file = inputRef.current.files[0];

    if (!file) return;

    dispatch(updateProfileImg(file, username, userId, userAuth));
    setName("");
    setClose();
  };

  return (
    <form
      className="flex items-center mb-0 w-full"
      id="load-image-form"
      onSubmit={(e) => {
        formSubmit(e);
      }}
    >
      <input
        type="file"
        value={name}
        onChange={handleChange}
        ref={inputRef}
        className="m-2"
      />
      <Button
        text="Upload"
        action={formSubmit}
        style="primary"
        loading={loading}
      />
    </form>
  );
};

export default ProfileImageUpload;

//Note that the “files” argument depends on the name of the input specified in the formData.
