import { useState } from "react";
import { PencilIcon } from "@heroicons/react/solid";
import ProfileImageUpload from "./ProfileImageUpload";

export default function AvatarUpload({ imgSrc }) {
  const [show, setShow] = useState(false);

  return (
    <>
      <div className="relative">
        <div className="h-20 w-20 rounded-full border my-0 mx-auto relative overflow-hidden">
          <img className="h-full w-full" src={imgSrc} alt="user avatar image" />
        </div>
        <PencilIcon
          className="h-6 w-6 p-1 text-white absolute right-0 bottom-1 bg-cprimary-primary rounded-full"
          onClick={() => setShow(!show)}
        />
      </div>
      <div className={`${show ? "w-full" : "hidden"}`}>
        <ProfileImageUpload setClose={() => setShow(!show)} />
      </div>
    </>
  );
}
