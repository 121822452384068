import Card from "../helpers/Card";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { PayoutDetailsItem } from "./PayoutDetailsItem";

const PayoutDetails = ({ data }) => {
  const location = useLocation();
  const [orderDetails, setOrderDetails] = useState(null);

  useEffect(() => {
    data?.map((d) => {
      if (+d.payout_id === +location.hash.slice(1)) {
        setOrderDetails(d.order_pmt_details);
      }
    });
  }, [location.hash]);

  return (
    <Card
      details={{
        heading: "Recent Activities",
        styling: "mr-0 w-full lg:mr-3 lg:w-4/12",
      }}
      disabled
    >
      <div>
        {orderDetails !== null ? (
          orderDetails.map((order) => (
            <PayoutDetailsItem key={order.order_id} order={order} />
          ))
        ) : (
          <div className="text-ctext-primary p-4">
            No Payout ID selected...
          </div>
        )}
      </div>
    </Card>
  );
};

export default PayoutDetails;
