import InfoTitle from '../InfoTitle';
import InfoContent from '../helpers/InfoContent';

const DetailCard = ({firstName, lastName, gamerTag, email, country, paypal, discord}) => {
  return (
    <div className="grid grid-cols-1 gap-6 sm:grid-cols-3">
      <div>
        <InfoTitle content={'First Name'} />
        <InfoContent content={firstName} />
      </div>
      <div>
        <InfoTitle content={'Last Name'} />
        <InfoContent content={lastName} />
      </div>
      <div>
        <InfoTitle content={'Gamer Tag'} />
        <InfoContent content={gamerTag} style="no-caps" />
      </div>
      <div>
        <InfoTitle content={'Email'} />
        <InfoContent content={email} style="no-caps" />
      </div>
      <div>
        <InfoTitle content={'Country'} />
        <InfoContent content={country} />
      </div>
      <div>
        <InfoTitle content={'PayPal'} />
        <InfoContent content={paypal} style="no-caps" />
      </div>
      <div>
        <InfoTitle content={'Discord'} />
        <InfoContent content={discord} style="no-caps" />
      </div>
    </div>
  );
}

export default DetailCard;