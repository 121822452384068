import playerboost_only_logo from "../assets/images/playerboost_only_logo.png";

const LoadingPage = ({ message }) => {
  return (
    <div className="fixed w-full h-full top-0 left-0 flex flex-col items-center justify-center bg-cbackground-light z-50">
      <img className="h-30 animate-pulse" src={playerboost_only_logo} alt="" />
      <div className="text-cprimary-primary text-2xl">{message}</div>
    </div>
  );
};

export default LoadingPage;
