import React from "react";
import AvatarUpload from "./AvatarUpload";
import { useSelector } from "react-redux";
import { FaXbox, FaPlaystation } from "react-icons/fa";
import { HiOutlineDesktopComputer } from "react-icons/hi";

const UserCard = ({ name, access, email, platforms }) => {
  const profileImgs = useSelector((state) => state.ui.profileImgs);

  return (
    <div className="flex flex-col w-full items-center py-4">
      <AvatarUpload
        imgSrc={`${
          profileImgs.userName[name]
            ? profileImgs.userName[name]
            : "https://i.pinimg.com/originals/c6/92/85/c69285419db3f7f436aca78bd4bccbcf.png"
        }`}
      />
      <div className="text-center my-6">
        <h4 className="text-white font-semibold">{name}</h4>
        <p className="text-sm my-2 text-cprimary-primary">{access}</p>
        <p className="text-white text-sm">{email}</p>
      </div>
      <div className="flex">
        {platforms.includes("xbox") && (
          <div className="flex-shrink-0 h-8 w-8 mx-2 p-2 bg-platform-xbox rounded-md ">
            <FaXbox size={"100%"} color={"#ffffff"} />
          </div>
        )}
        {platforms.includes("playstation") && (
          <div className="flex-shrink-0 h-8 w-8 mx-2 p-2 bg-platform-playstation rounded-md">
            <FaPlaystation size={"100%"} color={"#ffffff"} />
          </div>
        )}
        {platforms.includes("pc") && (
          <div className="flex-shrink-0 h-8 w-8 mx-2 p-2 bg-platform-pc rounded-md">
            <HiOutlineDesktopComputer size={"100%"} color={"#ffffff"} />
          </div>
        )}
      </div>
    </div>
  );
};

export default UserCard;
