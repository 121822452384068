import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from '../Dropdown';
import { UserAddIcon, XCircleIcon } from '@heroicons/react/solid';
import { addChatUserToTwilio } from '../../store/conversations-actions';
import Button from '../Button';

const OrderConversationAction = ({
  contractors,
  selected,
  convoId,
  orderName,
}) => {
  const dispatch = useDispatch();
  const [openDropdown, setOpenDropdown] = useState(false);
  const [choice, setChoice] = useState(contractors[0].id || null);
  const userAuth = useSelector((state) => state.auth.token);

  return (
    <>
      {openDropdown ? (
        <div className="flex items-center justify-between">
          <div className="flex mr-4 w-full items-center">
            <XCircleIcon
              className="h-6 w-6 mr-2 text-ctext-primary"
              onClick={() => setOpenDropdown(false)}
            />
            <Dropdown
              selects={contractors}
              setInitial={selected}
              onSelect={(choice) => {
                setChoice(choice);
              }}
            />
          </div>
          <div className="flex">
            <UserAddIcon
              onClick={async () => {
                dispatch(
                  addChatUserToTwilio(convoId, choice, orderName, userAuth)
                );
                setOpenDropdown(false);
              }}
              className="h-6 w-6 mr-4 text-cprimary-primary"
            />
          </div>
        </div>
      ) : (
        <div className="flex">
          <Button
            text={'ADD BOOSTER'}
            style={'outline'}
            action={() => {
              setOpenDropdown(true);
            }}
          />
        </div>
      )}
    </>
  );
};

export default OrderConversationAction;
