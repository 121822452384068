// Individual conversation bubbles in the conversation widget. 
// Compares the user identity to the message author to determine if the message is incoming or outgoing.

import { useSelector } from 'react-redux';

const ConversationMessages = ({ messages }) => {
  const identity = useSelector((state) => state.auth.displayName);

  return (
    <ul className="mx-4">
      {messages.map((m) => {
        if (m.author === identity) {
          return (
            <div
              key={m.index}
              direction="outgoing"
              className={
                'text-left my-2 ml-auto p-2 w-fit bg-cprimary-primary rounded-xl rounded-tl-lg message-bubble'
              }
            >
              <p className="font text-xs italic text-white">{m.author?.charAt(0) === '+' ? 'Client' : m.author}</p>
              <p className="font-light text-white">{m.state.body}</p>
              <p className="text-xs text-white italic">
                {m.state.timestamp.toLocaleTimeString()}
              </p>
              <p className="text-xs text-white italic">
                {m.state.timestamp.toLocaleDateString()}
              </p>
            </div>
          );
        } else if (m.author?.charAt(0) === "+") {
          return (
            <div
              key={m.index}
              direction="incoming"
              className={
                'my-2 p-2 bg-cbackground-lightgray rounded-xl message-bubble'
              }
            >
              <p className="font text-xs italic">{"Client"}</p>
              <p className="font-light">{m.state.body}</p>
              <p className="text-xs italic">
                {m.state.timestamp.toLocaleTimeString()}
              </p>
              <p className="text-xs italic">
                {m.state.timestamp.toLocaleDateString()}
              </p>
            </div>
          );
        } else {
          return (
            <div
              key={m.index}
              direction="incoming"
              className={
                'my-2 p-2 ml-auto bg-cprimary-primary rounded-xl message-bubble'
              }
            >
              <p className="font text-xs text-white italic">{m.author}</p>
              <p className="font-light text-white">{m.state.body}</p>
              <p className="text-xs italic text-white">
                {m.state.timestamp.toLocaleTimeString()}
              </p>
              <p className="text-xs text-white italic">
                {m.state.timestamp.toLocaleDateString()}
              </p>
            </div>
          ) 
        }
      })}
    </ul>
  );
};

export default ConversationMessages;
