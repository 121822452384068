import NotesFeed from './NotesFeed';
import NotesTextArea from './NotesTextArea';

const NotesSection = () => {
  return (
    <section
      aria-labelledby="timeline-title"
      className="shadow overflow-hidden sm:rounded-lg bg-cbackground-primary lg:col-start-3 lg:col-span-1"
    >
      <div className="">
        <h2 className="px-6 py-4 text-white border-b border-cborder font-light text-lg">
          Notes
        </h2>
      </div>
      <div className="text-sm text-ctext-primary font-semibold px-4 py-5 sm:px-6">
        <NotesFeed />
      </div>
      <div className="text-sm text-ctext-primary font-semibold px-4 py-5 sm:px-6">
        <NotesTextArea />
      </div>
    </section>
  );
};

export default NotesSection;
