// Loading indicators

import Loader from "react-loader-spinner";

const ProgressIndicator = ({ type }) => {
  switch (type) {
    case "dots":
      return <Loader type="ThreeDots" color="#4289f4" height={30} width={30} />;
    case "spinner":
      return (
        <Loader type="MutatingDots" color="#e17289" secondaryColor="#c32a4a" />
      );
    case "tailSpin":
      return <Loader type="TailSpin" color="#eee" height={15} width={15} />;
    default:
      return <Loader type="ThreeDots" color="#4289f4" height={30} width={30} />;
  }
};

export default ProgressIndicator;
